import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import Pagination from '../../../components/Admin/Pagination';

const Attraction = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const [attractions, setAttractions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    // Hooks for API requests
    const { request: fetchAttractionsRequest, response: attractionsResponse } = useRequest();
    const { request: deleteAttractionRequest, response: deleteAttractionResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    // Fetch Attractions
    const handleFetchAttractions = () => {
        fetchAttractionsRequest('GET', `/attractions?page_num=${currentPage + 1}&page_size=${pageSize}&search=${searchQuery}`);
    };

    // Fetch attractions on page load and on changes to pagination or search query
    useEffect(() => {
        handleFetchAttractions();
    }, [currentPage, pageSize, searchQuery]);

    // Handle response from fetchAttractionsRequest
    useEffect(() => {
        console.log('Attractions response:', attractionsResponse);
        if (attractionsResponse && attractionsResponse.status === "SUCCESS") {
            setAttractions(attractionsResponse.data || []);
            setTotalRecords(attractionsResponse?.total || 0);
        } else if (attractionsResponse && attractionsResponse.status !== "SUCCESS") {
            Toast(false, 'Failed to fetch attractions.');
        }
    }, [attractionsResponse]);

    // Handle delete attraction response
    useEffect(() => {
        if (deleteAttractionResponse && deleteAttractionResponse.status === "SUCCESS") {
            Toast(true, deleteAttractionResponse?.message || 'Deleted successfully.');
            handleFetchAttractions();
        } else if (deleteAttractionResponse) {
            Toast(false, deleteAttractionResponse?.message || 'Failed to delete.');
        }
    }, [deleteAttractionResponse]);

    // Handle status update response
    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message || 'Status updated.');
            handleFetchAttractions(); // Refetch the data after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message || 'Failed to update status.');
        }
    }, [updateStatusResponse]);

    // Handle pagination change
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle page size change
    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when page size changes
    };

    // Handle search input change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0); // Reset to first page when search query changes
    };

    // Handle delete attraction
    const handleDelete = (id) => {
        deleteAttractionRequest('DELETE', `/attractions/${id}`);
    };

    // Handle status toggle
    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        updateStatusRequest('PUT', `/attractions/${id}`, { is_active: updatedStatus });
    };

    return (
        <div
            className={`attraction-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`}
            id="container-dashboard"
        >
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Dashboard</Link></li>
                        <li>Attraction</li>
                    </ul>
                    <div className="btn-bc">
                        <Link to={`/${country}/${lang}/admin/attraction/landmark`} className="btn btn-info">
                            Landmark
                        </Link>
                        <Link to={`/${country}/${lang}/admin/attraction/listing`} className="btn btn-secondary">
                            Listing Page
                        </Link>
                        <Link to={`/${country}/${lang}/admin/attraction/add`} className="btn btn-primary">
                            Add Attraction <i className="fa-solid fa-plus"></i>
                        </Link>
                    </div>
                </div>

                {/* Search and Table */}
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </form>
                        </div>
                    </div>

                    {/* Table */}
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Active</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attractions && attractions.length > 0 ? (
                                    attractions.map(attraction => (
                                        <tr key={attraction.id}>
                                            <td>{attraction.title}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={attraction.is_active}
                                                        onChange={() => handleToggleStatus(attraction.id, attraction.is_active)}
                                                    />
                                                    <div className="txt-toggle"> <span>YES</span> </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/attraction/edit/${attraction.id}`}>
                                                        Edit <i className="fa-solid fa-pencil"></i>
                                                    </Link>
                                                    <a href="#" onClick={() => handleDelete(attraction.id)}>
                                                        Delete <i className="fa-solid fa-trash"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="3">No attractions found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    {/* Pagination */}
                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Attraction;