import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';

const AboutUs = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [aboutUsList, setAboutUsList] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchAboutUsRequest, response: aboutUsResponse } = useRequest();
    const { request: deleteAboutUsRequest, response: deleteAboutUsResponse } = useRequest();

    useEffect(() => {
        fetchAboutUsRequest('GET', `/cms-about-us?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`);
    }, [currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (aboutUsResponse && aboutUsResponse.status === "SUCCESS") {
            setAboutUsList(aboutUsResponse.data || []);
            setTotalRecords(aboutUsResponse.total || 0);
        }
    }, [aboutUsResponse]);

    useEffect(() => {
        if (deleteAboutUsResponse && deleteAboutUsResponse.status === "SUCCESS") {
            Toast(true, deleteAboutUsResponse.message);
            fetchAboutUsRequest('GET', `/cms-about-us?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`); // Refresh the list after deletion
        } else if (deleteAboutUsResponse) {
            Toast(false, deleteAboutUsResponse.message);
        }
    }, [deleteAboutUsResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0);
    };

    const handleDelete = (id) => {
        deleteAboutUsRequest('DELETE', `/about-us/${id}`);
    };

    return (
        <div className={`about-us container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li>About Us</li>
                    </ul>
                    <div className="d-inline-flex">
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/cms/about-us/add`} className="btn btn-primary">Add About Us
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </form>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Headline<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Body Type<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                                {aboutUsList.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.headline}</td>
                                        <td>{item.content}</td>
                                        <td>
                                            <div className="action-table">
                                                <Link to={`/${country}/${lang}/admin/cms/about-us/edit/${item.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                                                <a href='javascript:void(0);' onClick={() => handleDelete(item.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
