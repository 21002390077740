const GuestDashbaord = () => {
  return (
    <div
      className="community-stats-page container-dashboard"
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="box-dashboard">
          <div className="heading-graph-db">
            <h3>MyCommunity Stats</h3>
          </div>
          <img
            src="/assets/images/graph/1.jpg"
            alt="alt"
            style={{ width: "100%" }}
          />
        </div>
        <div className="total-count col-5-tc">
          <ul>
            <li>
              Total Reviews
              <h3>
                36 <small>+70.56%</small>
              </h3>
            </li>
            <li>
              Recommendation
              <h3>
                36 <small>+70.56%</small>
              </h3>
            </li>
            <li>
              Total Articles
              <h3>
                29 <small>+70.56%</small>
              </h3>
            </li>
            <li>
              Total Blogs
              <h3>
                15 <small className="minus-total-count">+70.56%</small>
              </h3>
            </li>
            <li>
              Total Applied Jobs
              <h3>
                150 <small className="minus-total-count">-70.56%</small>
              </h3>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default GuestDashbaord;
