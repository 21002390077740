import { Link, useOutletContext, useParams } from "react-router-dom";

const AddLive = () => {
  const { isSidebarHidden } = useOutletContext();
  const { country, lang } = useParams();

  
  return (
    <div
      className={`mylive-page container-dashboard ${
        isSidebarHidden ? "sidebar-dashboard-hidden" : ""
      }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="bc-dashboard">
          <ul>
            <li>
              <a href="#">Dashboard</a>
            </li>
            <li>
              <Link to={`/${country}/${lang}/customer/mylive`}>MyLive</Link>
            </li>
            <li>Add MyLive</li>
          </ul>

          <div className="btn-bc">
            <Link
              to={`/${country}/${lang}/customer/mylive/add`}
              className="btn btn-primary"
            >
              Add New Live <i className="fa-solid fa-plus"></i>
            </Link>
          </div>
        </div>
        <div className="box-dashboard">
          
          <h2>Add My Live</h2>
        </div>
      </div>
    </div>
  );
};
export default AddLive;
