import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddFaqS = () => {
    const { country, lang, category, faqId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        category_id: '',
        question: '',
        answer: '',
    });
    const [errors, setErrors] = useState({});
    const [categoryData, setCategoryData] = useState(null);
    const isEditMode = Boolean(faqId);

    const { request: categoryRequest, response: categoriesResponse } = useRequest();
    const { request: fetchFaqRequest, response: faqResponse } = useRequest();
    const { request: addOrUpdateFaqRequest, response: faqAddOrUpdateResponse } = useRequest();

    useEffect(() => {
        if (category) {
            categoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategoryData(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    useEffect(() => {
        if (isEditMode) {
            fetchFaqRequest('GET', `/faqs/${faqId}`); // Fetch existing FAQ data
        }
    }, [isEditMode, faqId]);

    useEffect(() => {
        if (faqResponse && faqResponse.status === "SUCCESS" && isEditMode) {
            const { category_id, question, answer } = faqResponse.data;
            setFormData({
                category_id,
                question,
                answer,
            });
        }
    }, [faqResponse, isEditMode]);

    useEffect(() => {
        if (faqAddOrUpdateResponse && faqAddOrUpdateResponse.status === "SUCCESS") {
            Toast(true, faqAddOrUpdateResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/faqs`);
        } else if (faqAddOrUpdateResponse) {
            Toast(false, faqAddOrUpdateResponse.message);
        }
    }, [faqAddOrUpdateResponse]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.question) formErrors.question = 'Question is required';
        if (!formData.answer) formErrors.answer = 'Answer is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            question: formData.question,
            answer: formData.answer,
            category_id: categoryData.id,
        };

        if (faqId) {
            addOrUpdateFaqRequest('PUT', `/faqs/${faqId}`, data);
        } else {
            addOrUpdateFaqRequest('POST', '/faqs', data);
        }
    };

    return (
        <div className={`add-faq container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/faqs`}>FAQs</Link></li>
                        <li>{isEditMode ? 'Edit FAQ' : 'Add FAQ'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Question</label>
                                    {errors.question && <p className="error">{errors.question}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Type question"
                                            name="question"
                                            value={formData.question}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <label>Answer</label>
                                    {errors.answer && <p className="error">{errors.answer}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Type answer"
                                            name="answer"
                                            value={formData.answer}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={isEditMode ? "Update" : "Save"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddFaqS;
