import React, { useEffect, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import Pagination from "../../../../components/Admin/Pagination";
import { Toast } from "../../../../utils/ToastNotification";

const Reels = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [reels, setReels] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchReelsRequest, response: reelsResponse } = useRequest();
    const { request: deleteReelRequest, response: deleteReelResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchReels = () => {
        if (categoryData) {
            fetchReelsRequest(
                'GET',
                `/my-reel?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchReels();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (reelsResponse && reelsResponse.status === "SUCCESS") {
            setReels(reelsResponse?.data || []);
            setTotalRecords(reelsResponse?.total || 0);
        }
    }, [reelsResponse]);

    useEffect(() => {
        if (deleteReelResponse && deleteReelResponse.status === "SUCCESS") {
            Toast(true, deleteReelResponse?.message);
            handleFetchReels();
        } else if (deleteReelResponse) {
            Toast(false, deleteReelResponse?.message);
        }
    }, [deleteReelResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchReels(); // Refresh the list after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handleDeleteReel = (id) => {
        deleteReelRequest('DELETE', `/my-reel/${id}`);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        const formData = new FormData();
        formData.append('is_active', updatedStatus);
        updateStatusRequest('PUT', `/my-reel/${id}`, formData);
    };

    return (
        <div className={`reels-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Reels</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Total <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>View <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {reels.map((reel) => (
                                    <tr key={reel.id}>
                                        <td>{reel.listing?.user?.name || 'N/A'}</td>
                                        <td>5</td>
                                        <td>{reel.listing ? reel.listing.view_count : 'N/A'}</td>
                                        <td>
                                            <div className="btn-toggle">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-toggle"
                                                    checked={!reel.is_active}
                                                    onChange={() => handleToggleStatus(reel.id, reel.is_active)}
                                                />
                                                <div class="txt-toggle"> <span>YES</span> </div>
                                                <div className="layer-toggle"></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-table">
                                                <a href="#">Edit <i className="fa-solid fa-pencil"></i></a>
                                                <a href="#" onClick={() => handleDeleteReel(reel.id)}>Delete <i className="fa-solid fa-trash"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reels;
