import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';

const Profile = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        logo: null,
        name: null,
        email: null,
        mobile: null,
    });
    const [passwordData, setPasswordData] = useState({
        old_password: null,
        new_password: null,
        retype_password: null,
    });
    const [previewLogo, setPreviewLogo] = useState('/assets/images/user.png');
    const [errors, setErrors] = useState({});

    const { request: fetchUserDataRequest, response: userDataResponse } = useRequest();
    const { request: updateUserProfileRequest, response: updateUserProfileResponse } = useRequest();
    const { request: updatePasswordRequest, response: updatePasswordResponse } = useRequest();

    // Fetch user data on component load
    useEffect(() => {
        fetchUserDataRequest('GET', '/auth/user');
    }, []);

    // Set user data to form after fetching
    useEffect(() => {
        if (userDataResponse && userDataResponse.status === "SUCCESS") {
            const logoUrl = userDataResponse?.data?.user_profile?.logo ? cloudFrontUrl + userDataResponse?.data?.user_profile?.logo : '/assets/images/user.png'
            setFormData({
                name: userDataResponse.data.name || null,
                email: userDataResponse.data.email || null,
                mobile: userDataResponse.data.mobile || null,
            });
            setPreviewLogo(logoUrl); // Assuming the API returns a logo URL
        }
    }, [userDataResponse]);

    // Handle form data changes
    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'logo') {
            setFormData({ ...formData, logo: files[0] });
            setPreviewLogo(URL.createObjectURL(files[0])); // Set preview of the logo
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    // Handle password data changes
    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData({ ...passwordData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    // Handle profile update submission
    const handleProfileSubmit = (e) => {
        e.preventDefault();

        const data = new FormData();
        if (formData.logo) data.append('logo', formData.logo);
        if (formData.name) data.append('name', formData.name);
        if (formData.email) data.append('email', formData.email);
        if (formData.mobile) data.append('mobile', formData.mobile);

        updateUserProfileRequest('PUT', '/auth/user', data);
    };

    // Handle password update submission
    const handlePasswordSubmit = (e) => {
        e.preventDefault();
        if (passwordData.new_password !== passwordData.retype_password) {
            setErrors({ retype_password: 'Passwords do not match' });
            return;
        }

        const data = {
            old_password: passwordData.old_password,
            new_password: passwordData.new_password,
        };

        updatePasswordRequest('POST', '/auth/update-password', data);
    };

    // Handle profile update response
    useEffect(() => {
        if (updateUserProfileResponse && updateUserProfileResponse.status === "SUCCESS") {
            Toast(true, updateUserProfileResponse.message);
        } else if (updateUserProfileResponse) {
            Toast(false, updateUserProfileResponse.message);
        }
    }, [updateUserProfileResponse]);

    // Handle password update response
    useEffect(() => {
        if (updatePasswordResponse && updatePasswordResponse.status === "SUCCESS") {
            Toast(true, updatePasswordResponse.message);
            setPasswordData({
                old_password: null,
                new_password: null,
                retype_password: null,
            });
        } else if (updatePasswordResponse) {
            Toast(false, updatePasswordResponse.message);
        }
    }, [updatePasswordResponse]);

    return (
        <div className={`admin-profile-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="box-dashboard">
                    <h3>Profile Detail</h3>
                    <div className="detail-admin-profile box-admin-profile">
                        <div className="avatar-admin-profile">
                            <input type="file" name="logo" id="logo" onChange={handleInputChange} style={{ display: 'none' }} />
                            <label htmlFor="logo" className="edit-text"><i className="fa-solid fa-pencil"></i></label>
                            <img src={previewLogo} alt="user" />
                        </div>
                        <form onSubmit={handleProfileSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="field-admin-profile">
                                        <label><img src="/assets/images/icons/user.svg" alt="user" />Full Name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="field-admin-profile">
                                        <label><img src="/assets/images/icons/email.svg" alt="email" />Email</label>
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="field-admin-profile">
                                        <label><img src="/assets/images/icons/phone-2.svg" alt="phone" />Phone</label>
                                        <input
                                            type="text"
                                            name="mobile"
                                            value={formData.mobile}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-admin-profile">
                                <button type="submit" className="btn btn-primary">Save Profile</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="box-dashboard">
                    <h3>Password & Security</h3>
                    <div className="box-admin-profile">
                        <form onSubmit={handlePasswordSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="field-admin-profile">
                                        <label><img src="/assets/images/icons/lock.svg" alt="lock" />Current Password</label>
                                        <input
                                            type="password"
                                            name="old_password"
                                            value={passwordData.old_password}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="field-admin-profile">
                                        <label><img src="/assets/images/icons/lock.svg" alt="lock" />New Password</label>
                                        <input
                                            type="password"
                                            name="new_password"
                                            value={passwordData.new_password}
                                            onChange={handlePasswordChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="field-admin-profile">
                                        <label><img src="/assets/images/icons/lock.svg" alt="lock" />Retype Password</label>
                                        <input
                                            type="password"
                                            name="retype_password"
                                            value={passwordData.retype_password}
                                            onChange={handlePasswordChange}
                                        />
                                        {errors.retype_password && <p className="error">{errors.retype_password}</p>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="submit-admin-profile">
                                        <button type="submit" className="btn btn-primary">Update Password</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
