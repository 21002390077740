import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';

const NewsAndUpdate = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [news, setNews] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [categoryData, setCategoryData] = useState(null);

    const { request: fetchNewsRequest, response: newsResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: deleteNewsRequest, response: deleteNewsResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchNews = () => {
        if (categoryData) {
            fetchNewsRequest(
                'GET',
                `/news?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        // Fetch category data using the category slug from the URL parameters
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);


    useEffect(() => {
        // Set category data after fetching and trigger fetching amenities
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchNews();
        }

    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (newsResponse && newsResponse.status === "SUCCESS") {
            setNews(newsResponse.data || []);
            setTotalRecords(newsResponse.total || 0);
        }
    }, [newsResponse]);

    useEffect(() => {
        if (deleteNewsResponse && deleteNewsResponse.status === "SUCCESS") {
            Toast(true, deleteNewsResponse.message);
            handleFetchNews(); // Refresh the list after deletion
        } else if (deleteNewsResponse) {
            Toast(false, deleteNewsResponse.message);
        }
    }, [deleteNewsResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchNews(); // Refresh the list after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when changing page size
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0); // Reset to first page when searching
    };

    const handleDelete = (id) => {
        deleteNewsRequest('DELETE', `/news/${id}`);
    };

    const handleToggleStatus = (id, isActive) => {
        updateStatusRequest('PUT', `/news/${id}/status`, { is_active: !isActive });
    };

    return (
        <>
            <div className={`news-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><Link to="#">Directory</Link></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li>News & Updates</li>
                        </ul>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/news/add`} className="btn btn-primary">
                                {t('Add News & Updates')} <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db">
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <form>
                                    <input
                                        type="text"
                                        placeholder={t('Search')}
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </form>
                            </div>
                            <div className="btn-filter">
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> {t('Download')}</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>{t('Name')}<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>{t('Active')}<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>{t('Action')}<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>
                                    {news.map((item) => (
                                        <tr key={item.id}>
                                            <td>{item.headline}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={item.is_active}
                                                        onChange={() => handleToggleStatus(item.id, item.is_active)}
                                                    />
                                                    <div className="txt-toggle"><span>{item.is_active ? 'YES' : 'NO'}</span></div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/directory/${category}/news/edit/${item.id}`}>
                                                        {t('Edit')} <i className="fa-solid fa-pencil"></i>
                                                    </Link>
                                                    <a href="#" onClick={() => handleDelete(item.id)}>
                                                        {t('Delete')}<i className="fa-solid fa-trash"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NewsAndUpdate;
