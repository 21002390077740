import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import Pagination from "../../../components/Admin/Pagination";

const GuestReviews = () => {
  const { isSidebarHidden } = useOutletContext();
  const [reviews, setReviews] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const { request: fetchReviewsRequest, response: reviewsResponse } =
    useRequest();
  const { request: fetchCategoriesRequest, response: categoriesResponse } =
    useRequest();
  const {
    request: fetchSubCategoriesRequest,
    response: subCategoriesResponse,
  } = useRequest();

  const handleFetchReviews = () => {
    fetchReviewsRequest(
      "GET",
      `/reviews?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}&category=${selectedCategoryId}`
    );
  };

  useEffect(() => {
    handleFetchReviews();
  }, [currentPage, pageSize, searchQuery, selectedCategoryId]);

  useEffect(() => {
    if (reviewsResponse && reviewsResponse.status === "SUCCESS") {
      setReviews(reviewsResponse?.data || []);
      setTotalRecords(reviewsResponse?.total || 0);
    }
  }, [reviewsResponse]);

  // Fetch categories on component mount
  useEffect(() => {
    fetchCategoriesRequest("GET", "/category");
  }, []);

  // Set categories data after fetching
  useEffect(() => {
    if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
      setCategories(categoriesResponse.data || []);
    }
  }, [categoriesResponse]);

  console.log(reviews);

  // Fetch subcategories when selectedCategoryId changes
  useEffect(() => {
    if (selectedCategoryId) {
      fetchSubCategoriesRequest("GET", `/sub-categories/${selectedCategoryId}`);
    }
  }, [selectedCategoryId]);

  // Set subcategories data after fetching
  useEffect(() => {
    if (subCategoriesResponse && subCategoriesResponse.status === "SUCCESS") {
      setSubCategories(subCategoriesResponse.data || []);
    }
  }, [subCategoriesResponse]);

  // const getContributor = (review) => {
  //     return review.user_id ? `User ${review.user_id}` : "Anonymous";
  // };

  const getRank = () => {
    return "N/A"; // Customize this based on your logic
  };

  const getActiveStatus = (review) => {
    return review.is_active ? "YES" : "NO";
  };

  const handleCategoryChange = (e) => {
    setSelectedCategoryId(e.target.value);
  };

  return (
    <div
      className={`reviews-page container-dashboard ${
        isSidebarHidden ? "sidebar-dashboard-hidden" : ""
      }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <h1>Reviews</h1>
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </div>
            <div className="btn-filter">
              <div className="select-btn-filter">
                <select onChange={handleCategoryChange}>
                  <option value="">Category</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="select-btn-filter">
                <select>
                  <option value="">Subcategory</option>
                  {subCategories.map((subCategory) => (
                    <option key={subCategory.id} value={subCategory.id}>
                      {subCategory.name}
                    </option>
                  ))}
                </select>
              </div>
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <tbody>
                <tr>
                  <th>
                    Company Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Reviews
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Ranking
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Action
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>

                {reviews.map((item, index) => (
                  <tr>
                    <td>{item.name}</td>
                    <td>{item.message}</td>
                    <td>
                      <div className="flex-rank-table">
                        <div className="rank-table">{item.rating}/5</div>
                        {item.rating === 5 && "Excellent"}
                        {item.rating === 4 && "Very Good"}
                        {item.rating === 3 && "Good"}
                        {item.rating === 2 && "Poor"}
                        {item.rating === 1 && "Terrible"}
                      </div>
                    </td>
                    <td>
                      <div className="action-table">
                        <a href="#">
                          Edit <i className="fa-solid fa-pencil"></i>
                        </a>
                        <a href="#">
                          Delete <i className="fa-solid fa-trash"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              currentPage={currentPage}
              pageSize={pageSize}
              totalRecords={totalRecords}
              onPageChange={setCurrentPage}
              onPageSizeChange={setPageSize}
            />
          </div>
          {/* <div className="pagination-container">
            <div className="show-per-page">
              <label>Show per page:</label>
              <select>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="info-pagination">
              <span>1 - 10 of 11 items</span>
              <div className="control-pagination">
                <button className="active">1</button>
                <button>2</button>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};
export default GuestReviews;
