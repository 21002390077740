import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Toast } from "../../../utils/ToastNotification";
import { useEffect, useState } from "react";
import useRequest from "../../../hooks/useRequest";
import { useTranslation } from "react-i18next";
import Pagination from "../../../components/Admin/Pagination";

const MyInqueries = () => {
  const { country, lang } = useParams();
  const { isSidebarHidden } = useOutletContext();
  const { t } = useTranslation();
  //   const navigate = useNavigate();

  const [inqueries, setInqueries] = useState([]);
  //   const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const { request: request, response: response } = useRequest();
  const { request: updateRequest, response: updateResponse } = useRequest();

  // const handleEdit = (id) => {
  //     navigate(`/${country}/${lang}/customer/listings/update/${id}`);
  // }

  const handleDelete = (id) => {
    updateRequest("DELETE", `/inquires/${id}`);
  };



  // Search input change handler
  const handleSearchChange = (e) => {
    const query = e.target.value;
    // setSearchQuery(query);
    fetchInqueries(query); // Fetch Inqueries whenever search input changes
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(0); // Reset to first page when page size changes
  };

  const fetchInqueries = (search = "") => {
    request(
      "GET",
      `/inquires?page_num=${currentPage}&page_size=${pageSize}&search=${search}`
    );
  };

  useEffect(() => {
    fetchInqueries();
  }, []);

  useEffect(() => {
    if (response && response.status === "SUCCESS") {
      setInqueries(response.data);
      setTotalRecords(response.total);
    }
  }, [response]);

  useEffect(() => {
    if (updateResponse && updateResponse.status === "SUCCESS") {
      fetchInqueries();
      Toast(true, updateResponse?.message);
    } else {
      Toast(false, updateResponse?.message);
    }
  }, [updateResponse]);
  return (
    <div
      className={`inquiries-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
        }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input type="text" placeholder="Search" onChange={handleSearchChange} />
              </form>
            </div>
            <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">Company</a>
              <a href="#" className="btn btn-primary">Store / Listing</a>
            </div>
            {/* <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">
                Company
              </a>
              <a href="#" className="btn btn-primary">
                Store / Listing
              </a>
            </div> */}
            <div className="btn-filter">
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <thead>
                <tr>
                  <th>
                    Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Message
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Email
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Mobile
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Action
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {inqueries &&
                  inqueries.map((item, index) => (
                    <tr key={index}>
                      <td>{item.name}</td>
                      <td>Turpis sit amet dfed lode orci porttitor...</td>
                      <td>ali@ali.com</td>
                      <td>+971 56 1234567</td>
                      <td>
                        <div className="action-table">
                          <button onClick={() => handleDelete(item.id)}>
                            Delete <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            totalRecords={totalRecords}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
          />
        </div>
      </div>
    </div>
  );
};
export default MyInqueries;
