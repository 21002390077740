import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from "../../../../hooks/useRequest";
import { Toast } from '../../../../utils/ToastNotification';

const AddCmsVideo = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        category_id: '',
        cover_video_image: null,
        video_url: ''
    });

    const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
    const { request: addCmsVideoRequest, response: addCmsVideoResponse } = useRequest();

    useEffect(() => {
        fetchCategoriesRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategories(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    useEffect(() => {
        if (addCmsVideoResponse && addCmsVideoResponse.status === "SUCCESS") {
            Toast(true, addCmsVideoResponse.message);
            navigate(`/${country}/${lang}/admin/cms/how-it-work`);
        } else if (addCmsVideoResponse) {
            Toast(false, addCmsVideoResponse.message);
        }
    }, [addCmsVideoResponse]);

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setFormData({ ...formData, [name]: type === 'file' ? e.target.files[0] : value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.category_id) formErrors.category_id = 'Category is required';
        if (!formData.cover_video_image) formErrors.cover_video_image = 'Cover video image is required';
        if (!formData.video_url) formErrors.video_url = 'Video url is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return
        }

        const data = new FormData();
        data.append('category_id', formData.category_id);
        data.append('cover_video_image', formData.cover_video_image);
        data.append('video_url', formData.video_url);

        await addCmsVideoRequest('POST', '/cms-videos', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });


    }

    return (
        <div className={`add-video container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li><Link to={`/${country}/${lang}/admin/cms/how-it-work`}>How It Work?</Link></li>
                        <li>Add Video</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Select Category</label>
                                    {errors.category_id && <p className="error">{errors.category_id}</p>}

                                    <div className="field-add-form">
                                        <select name="category_id" value={formData.category_id} onChange={handleInputChange}>
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Upload Video Cover</label>
                                    {errors.cover_video_image && <p className="error">errors.cover_video_image</p>}
                                    <div className="attached-input">
                                        <input type="file" name="cover_video_image" onChange={handleInputChange} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small></div>
                                </div>
                            </div>
                            <label>Add Video URL</label>
                            {errors.video_url && <p className="error">errors.video_url</p>}
                            <div className="field-add-form">
                                <input type="text" value={formData.video_url} placeholder="Add Video URL" name="video_url" onChange={handleInputChange} />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="more-add-form"> <a href="#" class="btn btn-primary">Add More <i class="fa-solid fa-plus"></i></a> </div>
                <h3>Master Video</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form action="">
                            <label>Upload Video Cover</label>
                            <div className="attached-input">
                                <input type="file" />
                                <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small></div>
                            <label>Add Video URL</label>
                            <div className="field-add-form">
                                <input type="text" placeholder="Add Video URL" />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddCmsVideo;
