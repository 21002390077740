import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ adminStatsData }) => {

    // Extract total listings data
    const totalListingsData = adminStatsData?.total_listings || [];

    // Prepare the data for the bar chart
    const categories = totalListingsData.map(listing => listing.category || 'Unknown'); // Get category names
    const dataValues = totalListingsData.map(listing => listing.total_listings || 0); // Get total listings

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '55%',
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: categories.length > 0 ? categories : ['Property', 'Venus', 'Motors', 'Jobs', 'Influencer', 'Talents'], // Fallback if no data
        },
    };

    const series = [
        {
            name: 'Total Listings',
            data: dataValues.length > 0 ? dataValues : [47, 20, 6, 30, 35, 25], // Fallback values
        }
    ];

    return (
        <>
            <Chart
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </>
    );
}

export default BarChart;