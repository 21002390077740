import React, { useEffect, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import { Toast } from "../../../../utils/ToastNotification";

const RightSidePanel = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [videos, setVideos] = useState([]);
    const [categoryData, setCategoryData] = useState(null);

    const { request: fetchVideos, response: videosResponse } = useRequest();
    const { request: deleteVideoRequest, response: deleteVideoResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();

    // Fetch videos if categoryData is available
    const handleFetchVideos = () => {
        fetchVideos('GET', '/right_side_video');
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            // Assuming the API returns a single category object
            setCategoryData(categoryResponse.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchVideos();
        }
    }, [categoryData]);

    useEffect(() => {
        if (videosResponse && videosResponse.status === "SUCCESS") {
            setVideos(videosResponse.data || []);
        }
    }, [videosResponse]);

    const formatDateTime = (dateTime) => {
        const date = new Date(dateTime);
        const day = String(date.getDate()).padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

        return `${day} ${month} ${year} | ${time}`;
    };

    const getCategoryName = (categoryId) => {
        console.log("MyFinder - getCategoryName - categoryData:", categoryData);
        return categoryData && categoryData.id === categoryId ? categoryData.name : "Unknown";
    };

    useEffect(() => {
        if (deleteVideoResponse && deleteVideoResponse.status === "SUCCESS") {
            Toast(true, deleteVideoResponse.message);
            handleFetchVideos();
        } else if (deleteVideoResponse) {
            Toast(false, deleteVideoResponse.message);
        }
    }, [deleteVideoResponse]);

    const handleDelete = (id) => {
        deleteVideoRequest('DELETE', `/right_side_video/${id}`);
    };

    return (
        <div className={`right-side-panel container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Right Side Panel</li>
                    </ul>
                    <div className="d-inline-flex">
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel/registration`} className="btn btn-primary">Registration
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel/advertisement`} className="btn btn-primary">Advertisement
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel/add`} className="btn btn-primary">Add Video
                                <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                />
                            </form>
                        </div>
                        <div className="btn-filter"><a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a> </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <tbody>
                                <tr>
                                    <th>Category<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Date & Time<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                                {videos.map((video) => (
                                    <tr key={video.id}>
                                        <td>{getCategoryName(video.category_id)}</td>
                                        <td>{formatDateTime(video.created_at)}</td>
                                        <td>
                                            <div className="action-table">
                                                <Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel/edit/${video.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                                                <a href='javascript:void(0);' onClick={() => handleDelete(video.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RightSidePanel;