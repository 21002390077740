import { useEffect, useState } from "react";
import { Toast } from "../../../utils/ToastNotification";
import { useOutletContext, useParams } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import Pagination from "../../../components/Admin/Pagination";

const Inquiries = () => {
  const { country, lang, category } = useParams();
  const { isSidebarHidden } = useOutletContext();

  const [inquiries, setInquiries] = useState([]);
  const [categoryData, setCategoryData] = useState(null); // Add category data
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const { request: fetchInquiriesRequest, response: inquiriesResponse } =
    useRequest();
  const { request: deleteInquiryRequest, response: deleteInquiryResponse } =
    useRequest();

  const handleFetchInquiries = () => {
    fetchInquiriesRequest(
      "GET",
      `/inquires?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
    );
  };


  useEffect(() => {
    handleFetchInquiries();
  }, []);

  useEffect(() => {
    if (inquiriesResponse && inquiriesResponse.status === "SUCCESS") {
      setInquiries(inquiriesResponse?.data || []);
      setTotalRecords(inquiriesResponse?.total || 0);
    }
  }, [inquiriesResponse]);

  useEffect(() => {
    if (deleteInquiryResponse && deleteInquiryResponse.status === "SUCCESS") {
      Toast(true, deleteInquiryResponse?.message);
      handleFetchInquiries();
    } else if (deleteInquiryResponse) {
      Toast(false, deleteInquiryResponse?.message);
    }
  }, [deleteInquiryResponse]);

  const handleDeleteInquiry = (id) => {
    deleteInquiryRequest("DELETE", `/inquires/${id}`);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(0); // Reset to first page when changing page size
  };
  return (
    <div
      className={`inquiries-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
        }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <h1>Inquiries</h1>
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            </div>
            <div className="btn-filter">
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <thead>
                <tr>
                  <th>
                    Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Message
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Email
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Mobile
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Action
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {inquiries.map((inquiry) => (
                  <tr key={inquiry.id}>
                    <td>{inquiry.name}</td>
                    <td>{inquiry.email}</td>
                    <td>{inquiry.mobile}</td>
                    <td>{inquiry.message}</td>
                    <td>{new Date(inquiry.created_at).toLocaleString()}</td>
                    <td>
                      <div className="action-table">
                        <a href="#">
                          Edit <i className="fa-solid fa-pencil"></i>
                        </a>
                        <a
                          href="#"
                          onClick={() => handleDeleteInquiry(inquiry.id)}
                        >
                          Delete <i className="fa-solid fa-trash"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalRecords={totalRecords}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Inquiries;
