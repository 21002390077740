import { useOutletContext } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { useEffect, useState } from "react";

const MyCv = () => {
  const { isSidebarHidden } = useOutletContext();
  const { request, response } = useRequest();
  const [myCv, setMyCv] = useState([]);
  const fetchMyCv = async () => {
    await request("GET", "/my-cv");
  };
  useEffect(() => {
    fetchMyCv();
  }, []);

  useEffect(() => {
    if (response) {
      setMyCv(response.data);
    }
  }, [response]);

  return (
    <div
      className={`cv-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
        }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input type="text" placeholder="Search" />
              </form>
            </div>
            <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">Company</a>
              <a href="#" className="btn btn-primary">Store / Listing</a>
            </div>
            {/* <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">
                Company
              </a>
              <a href="#" className="btn btn-primary">
                Store / Listing
              </a>
            </div> */}
            <div className="btn-filter">
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <thead>
                <tr>
                  <th>
                    Candidate Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Experience
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Email
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Mobile
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    CVs
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Call for Interview
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {myCv.map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.job.experience}</td>
                    <td>{item.email}</td>
                    <td>{item.mobile}</td>
                    <td>
                      <button className="published-status">
                        Published
                      </button>
                    </td>
                    <td>
                      <div className="btn-toggle">
                        <input
                          type="checkbox"
                          className="checkbox-toggle"
                        />
                        <div className="txt-toggle">
                          <span>YES</span>
                        </div>
                        <div className="layer-toggle"></div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <div className="show-per-page">
              <label>Show per page:</label>
              <select>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="info-pagination">
              <span>1 - 10 of 11 items</span>
              <div className="control-pagination">
                <button className="active">1</button>
                <button>2</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyCv;
