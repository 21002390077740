import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Modal/Modal';
import useRequest from '../../../../hooks/useRequest';
import { cloudFrontUrl } from '../../../../config';

const Amenity = ({ categoryId, listingId }) => {
    const [amenities, setAmenities] = useState([])
    const [listingAmenities, setListingAmenities] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { request: request, response: response } = useRequest()
    const { request: getRequest, response: getResponse } = useRequest()
    const { request: addRequest, response: addResponse } = useRequest()
    const { request: deleteRequest, response: deleteResponse } = useRequest()

    const handleAmenityChange = (amenity) => {
        let data = {
            listing_id: listingId,
            amenities_id: amenity.id
        }
        addRequest('POST', `/listing-amenity`, data)
    };

    const handleRemoveAmenity = (id) => {
        deleteRequest('DELETE', `/listing-amenity/${id}`)
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const handleFetchListingAmenity = () => {
        getRequest('GET', `/listing-amenities/${listingId}`)
    }

    useEffect(() => {
        if (listingId) {
            handleFetchListingAmenity()
        }
    }, [listingId]);

    useEffect(() => {
        if (categoryId) {
            request('GET', `/amenities/${categoryId}`)
        }
    }, [categoryId]);

    useEffect(() => {
        if (response && response.status === "SUCCESS") {
            setAmenities(response.data)
        }
    }, [response]);

    useEffect(() => {
        if (getResponse && getResponse.status === "SUCCESS") {
            setListingAmenities(getResponse.data)
        }
    }, [getResponse]);

    useEffect(() => {
        if (addResponse && addResponse.status === "SUCCESS") {
            handleFetchListingAmenity()
        }
    }, [addResponse]);

    useEffect(() => {
        if (deleteResponse && deleteResponse.status === "SUCCESS") {
            handleFetchListingAmenity()
        }
    }, [deleteResponse]);

    return (
        <>
            <div className="heading">
                <h2>Amenities</h2>
            </div>
            <div className="add-amenity-dbe">
                <button className="btn-add-adbe" onClick={() => setIsModalOpen(true)}>
                    Add Amenities <i className="fa-solid fa-plus"></i>
                </button>

                <ul>
                    {listingAmenities && listingAmenities.map((data, index) => (
                        <li key={index}>
                            <img src={cloudFrontUrl + data?.amenity?.icon} alt={data?.amenity?.name} /> {data?.amenity?.name}
                            <button className="delete-text" onClick={() => handleRemoveAmenity(data?.id)}>
                                <i className="fa-solid fa-minus"></i>
                            </button>
                        </li>
                    ))}
                </ul>
                <Modal isOpen={isModalOpen} closeModal={handleModalClose} title="Select Amenities">
                    <ul>
                        {amenities && amenities.map((data, index) => (
                            <li key={index}>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={listingAmenities.some(selected => selected.id === data.id)}
                                        onChange={() => handleAmenityChange(data)}
                                    />
                                    <img src={cloudFrontUrl + data?.icon} alt={data?.name} /> {data?.name}
                                </label>
                            </li>
                        ))}
                    </ul>
                </Modal>
            </div>
        </>
    );
};

export default Amenity;
