import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const AdminControl = () => {
    const { isSidebarHidden } = useOutletContext();
    return (
        <div>
            <div className={`admin-control container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Dashboard</a></li>
                            <li>Admin Controler</li>
                        </ul>
                    </div>
                    <div className="box-dashboard">
                        <div className="table-dashboard">
                            <table>
                                <tr>
                                    <th></th>
                                    <th>Property</th>
                                    <th>Motors</th>
                                    <th>Directory</th>
                                    <th>Jobs</th>
                                    <th>Venues</th>
                                    <th>Hotels</th>
                                    <th>Talents</th>
                                    <th>Influencers</th>
                                    <th>Education</th>
                                    <th>Healthcare</th>
                                    <th>Crypto</th>
                                </tr>
                                <tr>
                                    <td>My Story</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Reels</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Live</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Community</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Highlights</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Reviews</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Recommendation</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>

                                <tr>
                                    <td>My Status</td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-1" /><label for="switch-1">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-2" /><label for="switch-2">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-3" /><label for="switch-3">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-4" /><label for="switch-4">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-5" /><label for="switch-5">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-6" /><label for="switch-6">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-7" /><label for="switch-7">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-8" /><label for="switch-8">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-9" /><label for="switch-9">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-10" /><label for="switch-10">Toggle</label></div></td>
                                    <td><div className="toggle-switch"><input type="checkbox" id="switch-11" /><label for="switch-11">Toggle</label></div></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default AdminControl;
