import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../config';
import Pagination from '../../../../components/Admin/Pagination';

const AdminLandmark = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [landmarks, setLandmarks] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchLandmarks, response: landmarksResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: deleteLandmarkRequest, response: deleteLandmarkResponse } = useRequest();

    const handleFetchLandmark = () => {
        if (categoryData) {
            fetchLandmarks(
                'GET',
                `/landmarks?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchLandmark();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);


    useEffect(() => {
        if (landmarksResponse && landmarksResponse.status === 'SUCCESS') {
            setLandmarks(landmarksResponse.data || []);
            setTotalRecords(landmarksResponse.total || 0);
        } else if (landmarksResponse) {
            Toast(false, landmarksResponse.message);
        }
    }, [landmarksResponse]);

    useEffect(() => {
        if (deleteLandmarkResponse && deleteLandmarkResponse.status === "SUCCESS") {
            Toast(true, deleteLandmarkResponse?.message);
            handleFetchLandmark();
        } else if (deleteLandmarkResponse) {
            Toast(false, deleteLandmarkResponse?.message);
        }
    }, [deleteLandmarkResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0);
    };

    const handleDeleteLandmark = (id) => {
        deleteLandmarkRequest('DELETE', `/landmarks/${id}`);
    };

    return (
        <div className={`landmark-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Landmark</li>
                    </ul>
                    <div className="btn-bc">
                        <Link to={`/${country}/${lang}/admin/directory/${category}/landmark/add`} className="btn btn-primary">
                            Add Landmark <i className="fa-solid fa-plus"></i>
                        </Link>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form className="">
                                <input type="text" name="search" value={searchQuery} onChange={handleSearchChange} placeholder="Search" />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Icon<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {landmarks.length > 0 ? (
                                    landmarks.map((landmark) => (
                                        <tr key={landmark.id}>
                                            <td>
                                                <img src={cloudFrontUrl + landmark.icon} alt={landmark.name} />
                                            </td>
                                            <td>{landmark.name}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={landmark.category.is_active}
                                                        readOnly
                                                    />
                                                    <div className="txt-toggle">
                                                        <span>{landmark.category.is_active ? 'YES' : 'NO'}</span>
                                                    </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/directory/${category}/landmark/edit/${landmark.id}`}>
                                                        Edit <i className="fa-solid fa-pencil"></i>
                                                    </Link>
                                                    <a href="#" onClick={() => handleDeleteLandmark(landmark.id)}>
                                                        Delete <i className="fa-solid fa-trash"></i>
                                                    </a>                                                        </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No landmarks found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminLandmark;