import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';

const AddSocial = () => {
    const { country, lang, socialId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        social_media_name: '',
        social_media_type: '',
        social_media_url: '',
        is_active: true,
    });
    const [errors, setErrors] = useState({});
    const isEditMode = Boolean(socialId);

    const { request: addSocialRequest, response: addSocialResponse } = useRequest();
    const { request: fetchSocialRequest, response: socialResponse } = useRequest();
    const { request: updateSocialRequest, response: updateSocialResponse } = useRequest();

    useEffect(() => {
        if (isEditMode) {
            fetchSocialRequest('GET', `/social-media/${socialId}`);
        }
    }, [isEditMode, socialId]);

    useEffect(() => {
        if (socialResponse && socialResponse.status === "SUCCESS" && isEditMode) {
            setFormData({
                social_media_name: socialResponse.data.social_media_name || '',
                social_media_type: socialResponse.data.social_media_type || '',
                social_media_url: socialResponse.data.social_media_url || '',
                is_active: socialResponse.data.is_active !== undefined ? socialResponse.data.is_active : true,
            });
        }
    }, [socialResponse, isEditMode]);

    useEffect(() => {
        if (addSocialResponse && addSocialResponse.status === "SUCCESS") {
            Toast(true, addSocialResponse.message);
            navigate(`/${country}/${lang}/admin/social`);
        } else if (addSocialResponse) {
            Toast(false, addSocialResponse.message);
        }
    }, [addSocialResponse]);

    useEffect(() => {
        if (updateSocialResponse && updateSocialResponse.status === "SUCCESS") {
            Toast(true, updateSocialResponse.message);
            navigate(`/${country}/${lang}/admin/social`);
        } else if (updateSocialResponse) {
            Toast(false, updateSocialResponse.message);
        }
    }, [updateSocialResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        const formattedValue = name === 'social_media_name' && value
            ? value.charAt(0).toUpperCase() + value.slice(1)
            : value;

        setFormData({ ...formData, [name]: formattedValue });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.social_media_name) formErrors.social_media_name = 'Social media name is required';
        if (!formData.social_media_type) formErrors.social_media_type = 'Social media type is required';
        if (!formData.social_media_url) formErrors.social_media_url = 'Social media URL is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            social_media_name: formData.social_media_name,
            social_media_type: formData.social_media_type,
            social_media_url: formData.social_media_url,
        };

        if (isEditMode) {
            await updateSocialRequest('PUT', `/social-media/${socialId}`, data);
        } else {
            data.is_active = formData.is_active;
            await addSocialRequest('POST', '/social-media', data);
        }
    };

    return (
        <div className={`add-social container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to={`/${country}/${lang}/admin/dashboard`}>Dashboard</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/social`}>Social Media</Link></li>
                        <li>{isEditMode ? 'Edit Social Media' : 'Add Social Media'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Social Media Name</label>
                                    {errors.social_media_name && <p className="error">{errors.social_media_name}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Enter social media name"
                                            name="social_media_name"
                                            value={formData.social_media_name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Social Media Type</label>
                                    {errors.social_media_type && <p className="error">{errors.social_media_type}</p>}
                                    <div className="field-add-form">
                                        <select
                                            name="social_media_type"
                                            value={formData.social_media_type}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Social Media Type</option>
                                            <option value="instagram">Instagram</option>
                                            <option value="facebook">Facebook</option>
                                            <option value="x">X</option>
                                            <option value="youtube">Youtube</option>
                                            <option value="linkedin">Linkedin</option>pinterest
                                            <option value="telegram">Telegram</option>
                                            <option value="pinterest">Pinterest</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>URL</label>
                                    {errors.social_media_url && <p className="error">{errors.social_media_url}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Enter social media URL"
                                            name="social_media_url"
                                            value={formData.social_media_url}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={isEditMode ? "Update" : "Save"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSocial;