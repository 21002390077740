import { ToastContainer } from "react-toastify";
import GuestSideBar from "../components/Guest/GuestSideBar";
import GuestHeader from "../components/Guest/GuestHeader";
import { Outlet } from "react-router-dom";
import GuestFooter from "../components/Guest/GuestFooter";
import { useState } from "react";

const GuestLayout = () => {
  console.log("Guest layout");
  const [isSidebarHidden, setIsSidebarHidden] = useState(false);

  const hideSidebar = () => {
    setIsSidebarHidden(true);
  };

  const showSidebar = () => {
    setIsSidebarHidden(false);
  };

  return (
    <>
      <GuestSideBar
        hideSidebar={hideSidebar}
        showSidebar={showSidebar}
        isSidebarHidden={isSidebarHidden}
      />
      <ToastContainer />
      <GuestHeader />
      <Outlet context={{ isSidebarHidden }} />
      <GuestFooter />
    </>
  );
};
export default GuestLayout;
