import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useOutletContext } from "react-router-dom";
import * as Yup from "yup";
import useRequest from "../../../hooks/useRequest";
import { useEffect, useState } from "react";
import { Toast } from "../../../utils/ToastNotification";
const UploadCv = () => {
  const { isSidebarHidden } = useOutletContext();
  const [jobCategory, setJobCategory] = useState([]);
  const FILE_SIZE = 1 * 1024 * 1024;
  const FILE_SIZE_RESUME = 2 * 1024 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
  const SUPPORTED_FORMATS_RESUME = ['application/msword', 'application/pdf'];
  const validationSchema = Yup.object().shape({
    full_name: Yup.string().required("Full name is required"),
    gender: Yup.string().required("Gender is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    mobile: Yup.string().required("Mobile is required"),
    current_location: Yup.string().required("Current location is required"),
    category: Yup.string().required("Category is required"),
    key_skills: Yup.string().required("Key skills are required"),
    total_work_experience: Yup.string().required(
      "Total work experience is required"
    ),
    notice_period: Yup.string().required("Notice period is required"),
    last_salary: Yup.number().required("Last salary is required"),
    expected_salary: Yup.number().required("Expected salary is required"),
    photo: Yup
      .mixed()
      // .required("Image is required")
      .test("fileSize", "Image size is too large (max 1MB)", (value) => {
        if (value.length > 0) {
          return value && value[0].size <= FILE_SIZE;
        } else {
          return true;
        }
      })
      .test(
        "fileFormat",
        "Unsupported format. Supported formats are jpg, jpeg, and png",
        (value) => {
          if (value.length > 0) {
            return value && SUPPORTED_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      ),
    cover_letter_file: Yup
      .mixed()
      // .required("Image is required")
      .test("fileSize", "Image size is too large (max 1MB)", (value) => {
        if (value.length > 0) {
          return value && value[0].size <= FILE_SIZE;
        } else {
          return true;
        }
      })
      .test(
        "fileFormat",
        "Unsupported format. Supported formats are jpg, jpeg, and png",
        (value) => {
          if (value.length > 0) {
            return value && SUPPORTED_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      ),
    resume_file: Yup
      .mixed()
      .test("required", "Resume file is required.", (value) => value.length)
      .test("fileSize", "Resume size is too large (max 2MB)", (value) => {
        if (value.length > 0) {
          return value && value[0].size <= FILE_SIZE_RESUME;
        } else {
          return true;
        }
      })
      .test(
        "fileFormat",
        "Unsupported format. Supported formats are MS word file or PDF",
        (value) => {
          if (value.length > 0) {
            return value && SUPPORTED_FORMATS_RESUME.includes(value[0].type);
          } else {
            return true;
          }
        }
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { request: uploadResume, response: uploadResumeResponse } = useRequest();
  const onSubmit = async (data) => {
    const formData = new FormData();
    for (const key in data) {
      const value = data[key];
      if (key === "photo") {
        formData.append(key, value[0]);
      }
      else if (key === 'cover_letter_file') {
        formData.append(key, value[0]);

      }
      else if (key === 'resume_file') {
        formData.append(key, value[0]);
      }
      else {
        formData.append(key, value);
      }
    }
    await uploadResume("POST", "/upload-cv", formData);
  };

  useEffect(() => {
    if (uploadResumeResponse) {
      if (uploadResumeResponse.status === 'SUCCESS') {
        Toast(true, uploadResumeResponse?.message);
        reset()
      }
      else {
        Toast(false, uploadResumeResponse?.message);
      }
    }
  }, [uploadResumeResponse])

  const { request: fetchJobCategory, response: jobCategoryRes } = useRequest();

  useEffect(() => {
    (async () => {
      await fetchJobCategory("GET", `/sub-categories/4`);
    })();
  }, []);

  useEffect(() => {
    if (jobCategoryRes) {
      setJobCategory(jobCategoryRes.data);
    }
  }, [jobCategoryRes]);

  return (
    <div
      className={`upload-cv-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
        }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <h1>Upload a CV</h1>
        <div className="box-dashboard">
          <div className="add-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-4 mb-3">
                  <label>Upload Your Photo</label>
                  <div className="attached-input mb-0">
                    <input type="file" {...register("photo")} />
                    <small>
                      Upload Image / Photo{" "}
                      <img
                        src="/assets/images/icons/upload.svg"
                        alt="upload"
                      />
                    </small>
                  </div>
                  <p className="text-danger">{errors?.photo?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Full Name</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      {...register("full_name")}
                      placeholder="Type Full Name"
                    />
                  </div>
                  <p className="text-danger">{errors?.full_name?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Gender</label>
                  <div className="field-add-form mb-0">
                    <select {...register("gender")}>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </div>
                  <p className="text-danger">{errors?.gender?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Email ID</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      {...register("email")}
                      placeholder="email@email.com"
                    />
                  </div>
                  <p className="text-danger">{errors?.email?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Mobile</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      {...register("mobile")}
                      placeholder="+971 55 1234567"
                    />
                  </div>
                  <p className="text-danger">{errors?.mobile?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Current Location</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      placeholder="Example: Dubai Marina, Dubai, UAE"
                      {...register("current_location")}
                    />
                  </div>
                  <p className="text-danger">{errors?.current_location?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Select Category</label>
                  <div className="field-add-form mb-0">
                    <select {...register("category")}>
                      <option value="">Seject Job Cateogry</option>
                      {jobCategory &&
                        jobCategory.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <p className="text-danger">{errors?.category?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Key Skills</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      placeholder="Example: Photoshop, Illustrator, Indesign..."
                      {...register("key_skills")}
                    />
                  </div>
                  <p className="text-danger">{errors?.key_skills?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Total Work Experience</label>
                  <div className="field-add-form mb-0">
                    <select {...register("total_work_experience")}>
                      <option value="">Select Total Work Experience</option>
                      <option value="1-2 Years">1-2 Years</option>
                      <option value="3-5 Years">3-5 Years</option>
                    </select>
                  </div>
                  <p className="text-danger">{errors?.total_work_experience?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Join Company / Notice Period</label>
                  <div className="field-add-form mb-0">
                    <select {...register("notice_period")}>
                      <option value="">Select Notice Period</option>
                      <option value="Immediately">Immediately</option>
                      <option value="15 Days">15 Days</option>
                      <option value="2 Months">2 Months</option>
                      <option value="3 Months">3 Months</option>
                    </select>
                  </div>
                  <p className="text-danger">{errors?.notice_period?.message}</p>
                </div>
                <div className="col-sm-4">
                  <label>Last Salary / Current Salary</label>
                  <div className="field-add-form">
                    <input
                      type="text"
                      {...register("last_salary")}
                      placeholder="AED - 5,000"
                    />
                  </div>
                  <p className="text-danger">{errors?.last_salary?.message}</p>
                </div>
                <div className="col-sm-4 mb-3">
                  <label>Salary Expectation</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      {...register("expected_salary")}
                      placeholder="AED - 7,000"
                    />
                  </div>
                  <p className="text-danger">{errors?.expected_salary?.message}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 mb-3">
                  <label>Upload Cover Letter</label>
                  <div className="attached-input mb-0">
                    <input type="file" {...register("cover_letter_file")} />
                    <small>
                      Upload
                      <img
                        src="/assets/images/icons/upload.svg"
                        alt="upload"
                      />
                    </small>
                  </div>
                  <p className="text-danger">{errors?.cover_letter_file?.message}</p>
                </div>
                <div className="col-sm-6 mb-3">
                  <label>Upload CV / Resume</label>
                  <div className="attached-input mb-0">
                    <input type="file" {...register('resume_file')} />
                    <small>
                      Upload
                      <img
                        src="/assets/images/icons/upload.svg"
                        alt="upload"
                      />
                    </small>
                  </div>
                  <p className="text-danger">{errors?.resume_file?.message}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="checkbx-cv">
                    <input type="checkbox" id="agree" />
                    <label htmlFor="agree">
                      I agree to Term & Condition & Privacy Policy use of
                      Myfidner.ai
                    </label>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="checkbx-cv">
                    <input type="checkbox" id="send" />
                    <label htmlFor="send">
                      Send me job as per my choose category
                    </label>
                  </div>
                </div>
              </div>
              <div className="submit-add-form">
                <input type="submit" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UploadCv;