import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AdminSubCategoryUpdate = () => {
    const { country, lang, category, sub_category_id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState(null);
    const [subCategoryName, setSubCategoryName] = useState('');
    const [isActive, setIsActive] = useState(true);

    const { request: categroyRequest, response: categoryResponse } = useRequest();
    const { request: subCategroyRequest, response: subCategoryResponse } = useRequest();
    const { request: subCategroyUpdateRequest, response: subCategoryUpdateResponse } = useRequest();

    // Function to handle input changes
    const handleInputChange = (event) => {
        setSubCategoryName(event.target.value);
    };

    const handleToggleActive = () => {
        setIsActive((prevIsActive) => !prevIsActive);
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        const requestBody = {
            category_id: categoryData.id, // category ID from the fetched category data
            name: subCategoryName,
            is_active: isActive
        };

        subCategroyUpdateRequest('PUT', `/sub-category/${sub_category_id}`, requestBody);
    };

    useEffect(() => {
        if (sub_category_id) {
            subCategroyRequest('GET', `/sub-category/${sub_category_id}`);
        }
    }, [sub_category_id]);

    useEffect(() => {
        if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
            setSubCategoryName(subCategoryResponse?.data?.name)
        }
    }, [subCategoryResponse]);

    useEffect(() => {
        if (category) {
            categroyRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse?.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (subCategoryUpdateResponse && subCategoryUpdateResponse.status === "SUCCESS") {
            Toast(true, subCategoryUpdateResponse?.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/sub-category`);
        } else if (subCategoryUpdateResponse) {
            Toast(false, subCategoryUpdateResponse?.message);
        }
    }, [subCategoryUpdateResponse]);

    return (
        <div className={`add-subcategory container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Dashboard</a></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/sub-category`}>Subcategory</Link></li>
                        <li>Update Subcategory</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <label>Subcategory Name</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Type here"
                                            value={subCategoryName}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Update" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSubCategoryUpdate;
