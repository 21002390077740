import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const Registration = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        category_id: '',
        registration_headline: '',
        registration_description: '',
        registration_image: null
    });
    const [registration, setRegistration] = useState(null);
    const [categoryData, setCategoryData] = useState(null);

    const { request: fetchRegistrationRequest, response: registrationResponse } = useRequest();
    const { request: addOrUpdateRegistrationRequest, response: registrationAddOrUpdateResponse } = useRequest();
    const { request: categoryRequest, response: categoryResponse } = useRequest();

    useEffect(() => {
        if (category) {
            categoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data?.id);
        }
    }, [categoryResponse]);

    useEffect(() => {
        fetchRegistrationRequest('GET', '/right_side_registration');
    }, []);

    useEffect(() => {
        if (registrationResponse && registrationResponse.status === "SUCCESS") {
            const { category_id, registration_headline, registration_description, registration_image } = registrationResponse.data;
            setRegistration(registrationResponse.data);
            setFormData({
                category_id,
                registration_headline,
                registration_description,
                registration_image
            });
        }
    }, [registrationResponse]);

    // Handle submission response
    useEffect(() => {
        if (registrationAddOrUpdateResponse) {
            if (registrationAddOrUpdateResponse.status === "SUCCESS") {
                Toast(true, registrationAddOrUpdateResponse.message);
                navigate(`/${country}/${lang}/admin/directory/${category}/right-side-panel`);
            } else {
                Toast(false, registrationAddOrUpdateResponse.message);
            }
        }
    }, [registrationAddOrUpdateResponse]);

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value
        });
    };

    const validateInputs = () => {
        const errors = {};
        if (!formData.registration_headline) errors.registration_headline = 'Headline is required';
        if (!formData.registration_description) errors.registration_description = 'Description is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateInputs();
        if (Object.keys(errors).length > 0) {
            return;
        }

        const data = new FormData();
        data.append('registration_headline', formData.registration_headline);
        data.append('registration_description', formData.registration_description);
        if (formData.registration_image) {
            data.append('registration_image', formData.registration_image);
        }
        if (categoryData) {
            data.append('category_id', categoryData);
        }

        if (registration) {
            await addOrUpdateRegistrationRequest('PUT', `/right_side_registration/${registration.id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } else {
            await addOrUpdateRegistrationRequest('POST', '/right_side_registration', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
    };

    return (
        <div className={`add-side-panel container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel`}>Right Side Panel</Link></li>
                        <li>Registration</li>
                    </ul>
                </div>
                <h3>Registration</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="registration_image"
                                            onChange={handleInputChange}
                                        />
                                        <small>Upload <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="registration_headline"
                                            value={formData.registration_headline}
                                            onChange={handleInputChange}
                                            placeholder="Type Headline"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Call to Action Button</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="call_to_action"
                                            value={formData.call_to_action || ''}
                                            onChange={handleInputChange}
                                            placeholder="Register Now"
                                        />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea
                                    name="registration_description"
                                    value={formData.registration_description}
                                    onChange={handleInputChange}
                                    placeholder="Type Description"
                                />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Registration;