import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper/modules';
import useRequest from '../../../../../hooks/useRequest';
import { Toast } from '../../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../../config';

const MyReelTab = ({ isVTavShow }) => {
    const { country, lang, category, id } = useParams();
    const { t } = useTranslation();
    const imageRef = useRef()

    const [images, setImages] = useState([])

    const { request: Request, response: Response } = useRequest()
    const { request: saveRequest, response: saveResponse } = useRequest()
    const { request: deleteRequest, response: deleteResponse } = useRequest()

    const handleImage = (e) => {
        e.preventDefault()

        if (e.target.files.length === 0) {
            return false
        }

        let formData = new FormData()
        formData.append('reel_type', 'CUSTOMER')
        formData.append('customer_id', id)
        formData.append('image', e.target.files[0])

        if (id && isVTavShow) {
            saveRequest('POST', `/my-reel`, formData)
        } else {
            Toast(false, 'Create a company first')
        }
    }

    const handleDelete = (reelId) => {
        if (id && reelId && isVTavShow) {
            deleteRequest('DELETE', `/my-reel/${reelId}`)
        }
    }

    const fetchStories = () => {
        let query = `?reel_type=CUSTOMER&id=${id}`
        Request('GET', `/my-reel${query}`)
    }
    useEffect(() => {
        if (id && isVTavShow) {
            fetchStories()
        }
    }, []);

    useEffect(() => {
        if (Response && Response.status === "SUCCESS") {
            setImages(Response.data)
        }
    }, [Response]);

    useEffect(() => {
        if (saveResponse && saveResponse.status === "SUCCESS") {
            imageRef.current.value = '';
            Toast(true, saveResponse?.message)
            fetchStories()
        } else {
            Toast(false, saveResponse?.message)
        }
    }, [saveResponse]);

    useEffect(() => {
        if (deleteResponse && deleteResponse.status === "SUCCESS") {
            Toast(true, deleteResponse?.message)
            fetchStories()
        } else {
            Toast(false, deleteResponse?.message)
        }
    }, [deleteResponse]);

    return (
        <>
            <Swiper
                modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next.reels-vtab-next',
                    prevEl: '.swiper-button-prev.reels-vtab-prev',
                }}
                spaceBetween={10}
                slidesPerView={3}
                className="reels-slick-vtab"
            >
                <SwiperSlide>
                    <div className="edit-item-story">
                        <i><img src="/assets/images/icons/story.svg" alt="story" /></i>
                        <p>Upload Image / Photo</p>
                        <div className="attached-input">
                            <input type="file" ref={imageRef} onChange={(e) => handleImage(e)} />
                            <small>Upload</small>
                        </div>
                    </div>
                </SwiperSlide>

                {images && images.map((data, index) => (
                    <SwiperSlide>
                        <div className="item-story" key={index}>
                        <button className="delete-text" onClick={(e) => handleDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                            <div className="cap-item-story">
                                <img src={`${cloudFrontUrl}${data?.customer?.user_profile?.logo}`} alt="alt" />
                                <small className="date-item-story">1 days ago</small>
                            </div>
                            <div className="imgeffect">
                                <img src={`${cloudFrontUrl}${data?.image}`} alt="alt" />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="swiper-control">
                <div className="swiper-button-prev reels-vtab-prev"></div>
                <div className="swiper-button-next reels-vtab-next"></div>
            </div>
        </>
    );
}

export default MyReelTab;
