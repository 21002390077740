import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddNews = () => {
    const { country, lang, category, newsId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        headline: '',
        upload: null,
        description: '',
        category_id: null,
        is_active: true,
    });
    const [categoryData, setCategoryData] = useState(null);
    const [errors, setErrors] = useState({});

    const { request: fetchNewsRequest, response: newsResponse } = useRequest();
    const { request: addOrUpdateNewsRequest, response: newsAddOrUpdateResponse } = useRequest();
    const { request: categoryRequest, response: categoryResponse } = useRequest();

    useEffect(() => {
        if (category) {
            categoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse?.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (newsId) {
            fetchNewsRequest('GET', `/news/${newsId}`);
        }
    }, [newsId]);

    useEffect(() => {
        if (newsResponse && newsResponse.status === "SUCCESS") {
            const { headline, description, upload, category_id, is_active } = newsResponse.data;
            setFormData({
                headline,
                description,
                upload: upload ? `${process.env.REACT_APP_API_URL}/uploads/${upload}` : null,
                category_id,
                is_active,
            });
        }
    }, [newsResponse]);

    useEffect(() => {
        if (newsAddOrUpdateResponse && newsAddOrUpdateResponse.status === "SUCCESS") {
            Toast(true, newsAddOrUpdateResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/news`);
        } else if (newsAddOrUpdateResponse) {
            Toast(false, newsAddOrUpdateResponse.message);
        }
    }, [newsAddOrUpdateResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'upload') {
            setFormData({ ...formData, upload: files[0] });
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.headline) formErrors.headline = 'Headline is required';
        if (!formData.description) formErrors.description = 'Description is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('headline', formData.headline);
        data.append('description', formData.description);
        if (formData.upload instanceof File) {
            data.append('upload', formData.upload);
        }
        data.append('is_active', formData.is_active);
        if (categoryData) {
            data.append('category_id', categoryData.id);
        }

        if (newsId) {
            addOrUpdateNewsRequest('PUT', `/news/${newsId}`, data, 'multipart/form-data');
        } else {
            addOrUpdateNewsRequest('POST', '/news', data, 'multipart/form-data');
        }
    };

    return (
        <div className={`add-news container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/news`}>News</Link></li>
                        <li>{newsId ? 'Update News' : 'Add News'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    {errors.headline && <p className="error">{errors.headline}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Type Headline"
                                            name="headline"
                                            value={formData.headline}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    {errors.upload && <p className="error">{errors.upload}</p>}
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="upload"
                                            onChange={handleInputChange}
                                        />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                            </div>
                            <label>Description / Body Copy</label>
                            {errors.description && <p className="error">{errors.description}</p>}
                            <div className="field-add-form">
                                <textarea
                                    placeholder="Type here..."
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={newsId ? "Update" : "Save"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddNews;
