import React, { useState, useEffect, useRef } from 'react';
import { cloudFrontUrl } from '../config';
import useRequest from '../hooks/useRequest';

const UserLogo = () => {
    const [logo, setLogo] = useState('/assets/images/user.png');

    const { request: request, response: response } = useRequest()

    useEffect(() => {
        request('GET', '/auth/user')
    }, []);

    useEffect(() => {
        if (response && response.status === "SUCCESS") {
            if (response?.data?.user_profile?.logo) {
                setLogo(cloudFrontUrl + response?.data?.user_profile?.logo)
            }
        }
    }, [response]);

    return (
        <>
            <li className="user-header"><img src={logo} alt="user" /></li>
        </>
    );
};

export default UserLogo;
