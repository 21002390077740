import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';
import Amenity from './components/Amenity';
import Assets from './components/Assets';
import MyStoryTab from './components/MyStoryTab';
import MyReelTab from './components/MyReelTab';
import Gallery from './components/Gallery';
import { useGetUser } from '../../../hooks/useGetUser';

const Listing = () => {
    const { country, lang, id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const user = useGetUser();

    const [activeVerticalTab, setActiveVerticalTab] = useState('story');

    const [logoPreview, setLogoPreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const logoRef = useRef();

    const [listingId, setListingId] = useState(null);
    const [agentListing, setAgentListing] = useState(null);
    const [agents, setAgents] = useState([]);
    const [descriptionInputStates, setDescriptionInputStates] = useState(true)
    const [metaInputStates, setMetaInputStates] = useState(true);

    const [listing, setListing] = useState({
        title: null,
        location: null,
        status: null,
        mobile: null,
        whatsapp: null,
        google_review: null,
        description: null,
        location_near_by: null,
        meta_title: '',
        meta_description: '',
        meta_tags: ''
    });

    const [errors, setErrors] = useState({
        title: '',
        location: '',
        status: '',
        mobile: '',
        whatsapp: '',
        google_review: '',
    });

    const { request: listingRequest, response: listingResponse } = useRequest()
    const { request: getListingRequest, response: getListingResponse } = useRequest()
    const { request: agentRequest, response: agentResponse } = useRequest()
    const { request: agentListingRequest, response: agentListingResponse } = useRequest()
    const { request: agentListingAddUpdateRequest, response: agentListingAddUpdateResponse } = useRequest()

    const handleAgentSelect = (e) => {
        if (id) {
            const data = {
                agent_id: e.target.value,
                listing_id: id
            }
            if (agentListing) {
                agentListingAddUpdateRequest('PUT', `/agent-listing/${agentListing?.id}`, data)
            } else {
                agentListingAddUpdateRequest('POST', `/agent-listing`, data)
            }
        }
    }

    const handleListingChange = (e) => {
        const { name, value } = e.target;
        setListing((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({
            ...prev,
            [name]: value ? '' : `The ${name} field is required.`,
        }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setLogo(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setErrors((prev) => ({ ...prev, logo: '' }));
        } else {
            setLogo(null)
            logoRef.current.value = '';
            setLogoPreview(null);
            setErrors((prev) => ({ ...prev, logo: 'Logo is required.' }));
        }
    };

    const handleDivClick = () => {
        if (logoRef.current) {
            logoRef.current.click();
        }
    };

    const handleDescriptionInputEnable = () => {
        if (!descriptionInputStates) {
            let formData = new FormData()
            formData.append('description', listing.description)
            if (listingId) {
                listingRequest('PUT', `/listing/${listingId}`, formData)
            } else {
                Toast(false, 'Create a listing')
            }
        }
        setDescriptionInputStates(descriptionInputStates ? false : true);
    };

    const handleMetaInputEnable = () => {
        if (!metaInputStates) {
            let formData = new FormData()
            formData.append('meta_title', listing.meta_title)
            formData.append('meta_description', listing.meta_description)
            formData.append('meta_tags', listing.meta_tags)
            if (listingId) {
                listingRequest('PUT', `/listing/${listingId}`, formData)
            } else {
                Toast(false, 'Create a listing first')
            }
        }
        setMetaInputStates(metaInputStates ? false : true);
    };

    const handleSaveListing = () => {
        if (!id) {
            // Check for required fields
            let newErrors = {};

            if (!listing.title) newErrors.title = 'Listing title is required.';
            if (!listing.location) newErrors.location = 'Location is required.';
            if (!listing.status) newErrors.status = 'Status is required.';
            if (!listing.mobile) newErrors.mobile = 'Mobile is required.';
            if (!listing.whatsapp) newErrors.whatsapp = 'Whats app is required.';
            if (!listing.google_review) newErrors.google_review = 'Google review is required.';

            const logoInput = document.querySelector('input[name="logo"]');
            if (!logoInput.files.length) newErrors.logo = 'Logo is required.';

            if (Object.keys(newErrors).length) {
                setErrors(newErrors);
                return;
            }
        }

        let formData = new FormData()
        if (listing.title) formData.append('title', listing.title);
        if (listing.location) formData.append('location', listing.location);
        if (listing.status) formData.append('status_text', listing.status);
        if (listing.mobile) formData.append('mobile', listing.mobile);
        if (listing.whatsapp) formData.append('whatsapp', listing.whatsapp);
        if (listing.google_review) formData.append('google_place_id', listing.google_review);
        if (listing.description) formData.append('description', listing.description);
        if (listing.location_near_by) formData.append('location_near_by', listing.location_near_by);
        if (logo) formData.append('logo', logo); // Append the logo if it's provided

        if (listing.category_id) formData.append('category_id', listing.category_id);
        if (listing.sub_category_id) formData.append('sub_category_id', listing.sub_category_id);

        if (listing.meta_title) formData.append('meta_title', listing.meta_title)
        if (listing.meta_description) formData.append('meta_description', listing.meta_description)
        if (listing.meta_tags) formData.append('meta_tags', listing.meta_tags)

        if (id) {
            listingRequest('PUT', `/listing/${id}`, formData)
        } else {
            formData.append('user_id', user?.id);
            listingRequest('POST', '/listing', formData)
        }
    }

    useEffect(() => {
        if (id) {
            getListingRequest('GET', `/listing/${id}`)
            agentListingRequest('GET', `/agent-listing/${id}`)
        }
    }, []);

    useEffect(() => {
        if (user && user?.user_profile?.category?.id) {
            setListing((prev) => ({
                ...prev,
                category_id: user?.user_profile?.category?.id,
                sub_category_id: user?.user_profile?.sub_category?.id
            }));
            agentRequest('GET', `/agents/${user?.user_profile?.category?.id}`)
        }
    }, [user]);

    useEffect(() => {
        if (getListingResponse && getListingResponse.status === "SUCCESS") {
            setListingId(getListingResponse?.data?.id)
            setLogoPreview(cloudFrontUrl + getListingResponse.data?.logo);
            setListing({
                title: getListingResponse?.data?.title || '',
                location: getListingResponse?.data?.location || '',
                status: getListingResponse?.data?.status || '',
                mobile: getListingResponse?.data?.mobile || '',
                whatsapp: getListingResponse?.data?.whatsapp || '',
                google_review: getListingResponse?.data?.google_place_id || '',
                description: getListingResponse?.data?.description || '',
                meta_title: getListingResponse?.data?.meta_title || '',
                meta_description: getListingResponse?.data?.meta_description || '',
                meta_tags: getListingResponse?.data?.meta_tags || '',
                location_near_by: getListingResponse?.data?.location_near_by || ''
            });
        }
    }, [getListingResponse]);

    useEffect(() => {
        if (listingResponse && listingResponse.status === "SUCCESS") {
            setListingId(listingResponse.data.id)
            Toast(true, listingResponse?.message)
            setLogo(null)
            logoRef.current.value = '';
            navigate(`/${country}/${lang}/customer/listings`);
        } else {
            Toast(false, listingResponse?.message)
        }
    }, [listingResponse]);

    useEffect(() => {
        if (agentResponse && agentResponse.status === "SUCCESS") {
            setAgents(agentResponse.data)
        }
    }, [agentResponse]);

    useEffect(() => {
        if (agentListingResponse && agentListingResponse.status === "SUCCESS") {
            setAgentListing(agentListingResponse?.data)
        }
    }, [agentListingResponse]);

    useEffect(() => {
        if (agentListingAddUpdateResponse && agentListingAddUpdateResponse.status === "SUCCESS") {
            Toast(true, agentListingAddUpdateResponse?.message)
        } else {
            Toast(false, agentListingAddUpdateResponse?.message)
        }
    }, [agentListingAddUpdateResponse]);


    return (
        <>
            <div className={`store-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <div className="btn-bc">
                            <div className="field-btn-bc">
                                <select onChange={handleAgentSelect}>
                                    <option value="">Select Agent</option>
                                    {agents && agents.map((data, index) => (
                                        <option
                                            key={index}
                                            value={data.id}
                                            selected={data?.id === agentListing?.agent?.id ? true : false}
                                        >
                                            {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <a onClick={handleSaveListing} className="btn btn-primary">{id ? 'Update Listing' : 'Add Listing'} {id ? <i className="fa-solid fa-pencil"></i> : <i className="fa-solid fa-plus"></i>} </a>
                        </div>
                    </div>
                    <div className="upload-gallery">
                        <Gallery listingId={listingId} />
                    </div>
                    <div className="title-db">
                        <div className="row">
                            <div className="col-sm-2">
                                {errors.logo && <div className="error">{errors.logo}</div>}
                                <div className="field-title-db" onClick={handleDivClick} style={{ cursor: 'pointer', width: '130px', height: '120px' }}>
                                    <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                    <div className="attached-input" style={{ width: "120px", height: "100px" }}>
                                        <input type="file" className="form-control" name="logo" ref={logoRef} onChange={handleLogoUpload} style={{ display: 'none' }} required />
                                        {!logoPreview ? (
                                            <small style={{ fontSize: '16px' }}>Upload Logo or Photo</small>
                                        ) : (
                                            <img src={logoPreview} alt="Logo" className="img-circle" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-10">
                                {errors.title && <div className="error">{errors.title}</div>}
                                <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                    <input type="text" name='title' value={listing.title} onChange={handleListingChange} placeholder="Listing Name" />
                                    <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {errors.location && <div className="error">{errors.location}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='location' value={listing.location} onChange={handleListingChange} placeholder="Location" />
                                            {/* <button className="edit-text"><i className="fa-solid fa-pencil"></i></button> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='status' value={listing.status} onChange={handleListingChange} placeholder="Status" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='mobile' value={listing.mobile} onChange={handleListingChange} placeholder="Type Your Mobile Number" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='whatsapp' value={listing.whatsapp} onChange={handleListingChange} placeholder="Type Your Whatsapp Number" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='google_review' value={listing.google_review} onChange={handleListingChange} placeholder="Add Google Review Link" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <button className="edit-text" onClick={handleDescriptionInputEnable}>{descriptionInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        <h3>About</h3>
                        <textarea placeholder="Description" name='description' value={listing.description} onChange={handleListingChange} disabled={descriptionInputStates}></textarea>
                    </div>
                    <div className="box-dashboard">
                        <Amenity categoryId={user?.user_profile?.category?.id} listingId={listingId} />
                    </div>
                    <div className="box-dashboard">
                        <Assets listingId={listingId} />
                    </div>
                    <div className="box-dashboard">
                        <div className="heading">
                            <h2>Location & Near by</h2>
                        </div>
                        <div className="location-dbe">
                            <input type="text" name='location_near_by' value={listing.location_near_by} onChange={handleListingChange} placeholder="Type your location" />
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1723828661752!5m2!1sen!2sin"
                                width="600" height="450" style={{ border: '0;' }} allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="social-activity">
                            <div className="vtab">
                                <ul className="vtab-menu">
                                    <li data-tab="mystories-vtab" className={activeVerticalTab === 'story' ? 'active' : ''} onClick={() => setActiveVerticalTab('story')}><img src="/assets/images/icons/story.svg" alt="story" /> MyStories</li>
                                    <li data-tab="myreels-vtab" className={activeVerticalTab === 'reel' ? 'active' : ''} onClick={() => setActiveVerticalTab('reel')}><img src="/assets/images/icons/reels.svg" alt="reels" /> MyReels</li>
                                </ul>
                                <div className="wraper-vtab">
                                    <div id="mystories-vtab" className={`vtab-content ${activeVerticalTab === 'story' ? 'active' : ''}`}>
                                        <MyStoryTab id={id} activeVerticalTab={activeVerticalTab} />
                                    </div>
                                    <div id="myreels-vtab" className={`vtab-content ${activeVerticalTab === 'reel' ? 'active' : ''}`}>
                                        <MyReelTab id={id} activeVerticalTab={activeVerticalTab} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <button className="edit-text" onClick={handleMetaInputEnable}>{metaInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        <div className="meta-dbe">
                            <div className="heading">
                                <h2>SEO</h2>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Meta Title*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_title' value={listing.meta_title} onChange={handleListingChange} placeholder="Meta Title" disabled={metaInputStates} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Meta Tags*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_tags' value={listing.meta_tags} onChange={handleListingChange} placeholder="Meta Keywords" disabled={metaInputStates} />
                                        </div>
                                    </div>
                                </div>
                                <label>Meta Description*</label>
                                <div className="field-meta-dbe">
                                    <textarea placeholder="Meta Description" name='meta_description' value={listing.meta_description} onChange={handleListingChange} disabled={metaInputStates}></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="submit-dbe">
                        <button type='button' onClick={handleSaveListing}>{id ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Listing;
