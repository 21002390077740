import React, { useState, useEffect } from 'react';

const Pagination = ({ totalRecords, pageSize, currentPage, onPageChange, onPageSizeChange }) => {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        const totalPages = Math.ceil(totalRecords / pageSize);
        const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
        setPages(pageNumbers);
    }, [totalRecords, pageSize]);

    const handlePageSizeChange = (event) => {
        onPageSizeChange(parseInt(event.target.value));
    };

    return (
        <div className="pagination-container">
            <div className="show-per-page">
                <label>Show per page:</label>
                <select value={pageSize} onChange={handlePageSizeChange}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                </select>
            </div>
            <div className="info-pagination">
                <span>{(currentPage * pageSize) + 1} - {Math.min((currentPage + 1) * pageSize, totalRecords)} of {totalRecords} items</span>
                <div className="control-pagination">
                    {pages.map((page) => (
                        <button 
                            key={page} 
                            className={page === currentPage + 1 ? 'active' : ''} 
                            onClick={() => onPageChange(page - 1)}
                        >
                            {page}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Pagination;
