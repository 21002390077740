import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navigation } from 'swiper/modules';
import useRequest from '../../../../../hooks/useRequest';
import { Toast } from '../../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../../config';

const MyStoryTab = ({ id, activeVerticalTab }) => {
    // const { country, lang, category, customer_id, id } = useParams();
    const { t } = useTranslation();
    const imageRef = useRef()

    const [images, setImages] = useState([])

    const { request: Request, response: Response } = useRequest()
    const { request: saveRequest, response: saveResponse } = useRequest()
    const { request: deleteRequest, response: deleteResponse } = useRequest()

    const handleImage = (e) => {
        e.preventDefault()

        if (e.target.files.length === 0) {
            return false
        }

        let formData = new FormData()
        formData.append('story_type', 'LISTING')
        formData.append('listing_id', id)
        formData.append('image', e.target.files[0])

        if (id && activeVerticalTab === "story") {
            saveRequest('POST', `/my-story`, formData)
        } else {
            Toast(false, 'Create a company first')
        }
    }

    const handleDelete = (storyId) => {
        if (id && storyId && activeVerticalTab === "story") {
            deleteRequest('DELETE', `/my-story/${storyId}`)
        }
    }

    const fetchStories = () => {
        let query = `?story_type=LISTING&id=${id}`
        Request('GET', `/my-story${query}`)
    }

    useEffect(() => {
        if (id && activeVerticalTab === "story") {
            fetchStories()
        }
    }, [id, activeVerticalTab]);

    useEffect(() => {
        if (Response && Response.status === "SUCCESS") {
            setImages(Response.data)
        }
    }, [Response]);

    useEffect(() => {
        if (saveResponse && saveResponse.status === "SUCCESS") {
            imageRef.current.value = '';
            Toast(true, saveResponse?.message)
            fetchStories()
        } else {
            Toast(false, saveResponse?.message)
        }
    }, [saveResponse]);

    useEffect(() => {
        if (deleteResponse && deleteResponse.status === "SUCCESS") {
            Toast(true, deleteResponse?.message)
            fetchStories()
        } else {
            Toast(false, deleteResponse?.message)
        }
    }, [deleteResponse]);

    return (
        <>
            <Swiper
                modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next.story-vtab-next',
                    prevEl: '.swiper-button-prev.story-vtab-prev',
                }}
                spaceBetween={10}
                slidesPerView={3}
                className="story-slick-vtab"
            >
                <SwiperSlide>
                    <div className="edit-item-story">
                        <i><img src="/assets/images/icons/story.svg" alt="story" /></i>
                        <p>Upload Image / Photo</p>
                        <div className="attached-input">
                            <input type="file" ref={imageRef} onChange={(e) => handleImage(e)} />
                            <small>Upload</small>
                        </div>
                    </div>
                </SwiperSlide>

                {images && images.map((data, index) => (
                    <SwiperSlide>
                        <div className="item-story" key={index}>
                            <button className="delete-text" onClick={(e) => handleDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                            <div className="cap-item-story">
                                <div className="date-item-story">1 day ago</div>
                            </div>
                            <div className="imgeffect">
                                <img src={`${cloudFrontUrl}${data?.image}`} alt="alt" />
                            </div>
                        </div>
                    </SwiperSlide>
                ))}

                {/* <SwiperSlide>
                    <div className="item-story">
                        <button className="delete-text"><i className="fa-solid fa-times"></i></button>
                        <div className="cap-item-story">
                            <div className="date-item-story">1 day ago</div>
                        </div>
                        <div className="imgeffect">
                            <img src="/assets/images/reels-2.jpg" alt="alt" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item-story">
                        <button className="delete-text"><i className="fa-solid fa-times"></i></button>
                        <div className="cap-item-story">
                            <div className="date-item-story">1 day ago</div>
                        </div>
                        <div className="imgeffect">
                            <img src="/assets/images/reels-1.jpg" alt="alt" />
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="item-story">
                        <button className="delete-text"><i className="fa-solid fa-times"></i></button>
                        <div className="cap-item-story">
                            <div className="date-item-story">1 day ago</div>
                        </div>
                        <div className="imgeffect">
                            <img src="/assets/images/reels-2.jpg" alt="alt" />
                        </div>
                    </div>
                </SwiperSlide> */}
            </Swiper>
            <div class="swiper-control">
                <div class="swiper-button-prev story-vtab-prev" tabindex="0" role="button" aria-label="Previous slide" aria-controls="swiper-wrapper-f633964c13f8a66f"></div>
                <div class="swiper-button-next story-vtab-next" tabindex="0" role="button" aria-label="Next slide" aria-controls="swiper-wrapper-f633964c13f8a66f"></div>
            </div>
        </>
    );
}

export default MyStoryTab;
