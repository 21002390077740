import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const Policy = () => {
    const { country, lang, id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        headline: '',
        description: ''
    });
    const [errors, setErrors] = useState({});
    const [policyData, setPolicyData] = useState(null);

    const { request: fetchCmsPolicyRequest, response: cmsPolicyResponse } = useRequest();
    const { request: addOrUpdatePolicyRequest, response: addOrUpdatePolicyResponse } = useRequest();


    useEffect(() => {
        fetchCmsPolicyRequest('GET', '/cms-policy');
    }, []);

    useEffect(() => {
        if (cmsPolicyResponse && cmsPolicyResponse.status === "SUCCESS" && cmsPolicyResponse.data) {
            const policyData = cmsPolicyResponse.data; // Access the object directly
            setPolicyData(policyData);
            setFormData({
                headline: policyData.headline || '',
                description: policyData.description || ''
            });
        }
    }, [cmsPolicyResponse]);

    useEffect(() => {
        if (addOrUpdatePolicyResponse && addOrUpdatePolicyResponse.status === "SUCCESS") {
            Toast(true, addOrUpdatePolicyResponse.message);
        } else if (addOrUpdatePolicyResponse) {
            Toast(false, addOrUpdatePolicyResponse.message);
        }
    }, [addOrUpdatePolicyResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.headline) formErrors.headline = 'Headline is required';
        if (!formData.description) formErrors.description = 'Description is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            headline: formData.headline,
            description: formData.description
        };

        if (policyData) {
            await addOrUpdatePolicyRequest('PUT', `/cms-policy/${policyData.id}`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } else {
            await addOrUpdatePolicyRequest('POST', '/cms-policy', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
    };

    return (
        <div className={`add-policy container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li>Policy</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <label>Headline</label>
                            {errors.headline && <p className="error">{errors.headline}</p>}
                            <div className="field-add-form">
                                <input
                                    type="text"
                                    placeholder="Type Headline"
                                    name="headline"
                                    value={formData.headline}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <label>Body Copy</label>
                            {errors.description && <p className="error">{errors.description}</p>}
                            <div className="field-add-form">
                                <textarea
                                    placeholder="Type here..."
                                    name="description"
                                    value={formData.description}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Policy;