import React, { useEffect, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import Pagination from "../../../../components/Admin/Pagination";
import { Toast } from "../../../../utils/ToastNotification";

const HowItWork = () => {
  const { country, lang } = useParams();
  const { isSidebarHidden } = useOutletContext();

  const [videoContent, setVideoContent] = useState([]);
  const [categories, setCategories] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);

  const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
  const { request: fetchCmsVideos, response: cmsVideosResponse } = useRequest();
  const { request: deleteCmsVideoRequest, response: deleteCmsVideoResponse } = useRequest();
  // const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

  const handleFetchCmsVideo = () => {
    fetchCmsVideos(
      'GET',
      `/cms-videos?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
    );
  };

  useEffect(() => {
    fetchCategoriesRequest('GET', '/category');
  }, []);

  useEffect(() => {
    if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
      setCategories(categoriesResponse.data || []);
    }
  }, [categoriesResponse]);

  // Fetch CMS 
  useEffect(() => {
    handleFetchCmsVideo();
  }, [currentPage, pageSize, searchQuery]);

  useEffect(() => {
    if (cmsVideosResponse && cmsVideosResponse.status === "SUCCESS") {
      setVideoContent(cmsVideosResponse?.data || []);
      setTotalRecords(cmsVideosResponse?.total || 0);
    }
  }, [cmsVideosResponse]);

  useEffect(() => {
    if (deleteCmsVideoResponse && deleteCmsVideoResponse.status === "SUCCESS") {
      Toast(true, deleteCmsVideoResponse?.message);
      handleFetchCmsVideo(); // Refresh the list after deletion
    } else if (deleteCmsVideoResponse) {
      Toast(false, deleteCmsVideoResponse?.message);
    }
  }, [deleteCmsVideoResponse]);

  // useEffect(() => {
  //   if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
  //     Toast(true, updateStatusResponse.message);
  //     handleFetchCmsVideo();
  //   } else if (updateStatusResponse) {
  //     Toast(false, updateStatusResponse.message);
  //   }
  // }, [updateStatusResponse]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(0);
  };

  const formatDateTime = (dateTime) => {
    const date = new Date(dateTime);
    const day = String(date.getDate()).padStart(2, '0');
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const time = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return `${day} ${month} ${year} | ${time}`;
  };

  const getCategoryName = (categoryId) => {
    const category = categories.find(cat => cat.id === categoryId);
    return category ? category.name : "Unknown"
  }

  const handleSearchChange = (e) => {
    console.log("MyFinder - handleSearchChange - e:", e);
    setSearchQuery(e.target.value);
    setCurrentPage(0);
  };

  const handleDelete = (id) => {
    deleteCmsVideoRequest('DELETE', `/cms-videos/${id}`);
  };

  // const handleToggleStatus = (id, currentStatus) => {
  //   const updatedStatus = !currentStatus;
  //   updateStatusRequest('PUT', `/cms-videos/${id}`, { is_active: updatedStatus });
  // };

  return (
    <>
      <div
        className={`how-it-work-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
          }`}
        id="container-dashboard"
      >
        <div className="container-fluid">
          <div className="bc-dashboard">
            <ul>
              <li>
                CMS Pages
              </li>
              <li>How It Work?</li>
            </ul>
            <div className="d-inline-flex">
              <div className="btn-bc">
                <Link to={`/${country}/${lang}/admin/cms/how-it-work/master-video`} className="btn btn-primary">Master Video
                  <i className="fa-solid fa-plus"></i>
                </Link>
              </div>
              <div className="btn-bc">
                <Link to={`/${country}/${lang}/admin/cms/how-it-work/add-video`} className="btn btn-primary">Add Video
                  <i className="fa-solid fa-plus"></i>
                </Link>
              </div>
            </div>


          </div>
          <div className="box-dashboard">
            <div className="search-wraper">
              <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                <form>
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </form>
              </div>
              <div className="btn-filter"><a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a> </div>
            </div>
            <div className="table-dashboard">
              <table>
                <tbody>
                  <tr>
                    <th>Category<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                    <th>Date & Time<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                    {/* <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th> */}
                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                  </tr>
                  {videoContent.map((video) => (
                    <tr key={video.id}>
                      <td>{getCategoryName(video.category_id)}</td>
                      <td>{formatDateTime(video.created_at)}</td>
                      {/* <td>
                        <div className="btn-toggle">
                          <input type="checkbox" className="checkbox-toggle" checked={!video.is_active} onChange={() => handleToggleStatus(video.id, video.is_active)} />
                          <div className="txt-toggle"> <span>YES</span> </div>
                          <div className="layer-toggle"></div>
                        </div>
                      </td> */}
                      <td>
                        <div className="action-table">
                          <Link to={`/${country}/${lang}/admin/cms/how-it-work/update/${video.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                          <a href='javascript:void(0);' onClick={() => handleDelete(video.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Pagination
              totalRecords={totalRecords}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWork;