import React, { useEffect, useState, useRef } from 'react';
import { useParams, useOutletContext, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import HorizontalTab from './HorizontalTab';
import { cloudFrontUrl } from '../../../config';
import { Toast } from '../../../utils/ToastNotification';
import AttractionMyReelTab from './AttractionMyReelTab';
import AttractionStoryTab from './AttractionMyStory.js'

const AttractionAdd = () => {
    const { country, lang, id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isSidebarHidden } = useOutletContext();
    const [activeHorizontalTab, setActiveHorizontalTab] = useState('about');
    const [activeVerticalTab, setActiveVerticalTab] = useState('story');

    const [inputStates, setInputStates] = useState({
        title: true,
        status: true,
        description: true,
        meta_name: true,
        meta_description: true,
        meta_tags: true
    });

    const [attraction, setAttraction] = useState({
        logo: null,
        sub_category_id: null,
        title: null,
        status_text: null,
        description: null,
        meta_name: null,
        meta_description: null,
        meta_tags: null
    });

    const [errors, setErrors] = useState({
        title: '',
        logo: '',
        status_text: '',
    });

    const [metaInputStates, setMetaInputStates] = useState(true);


    const [attractionId, setAttractionId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState('');

    const [logoPreview, setLogoPreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const logoRef = useRef();

    const { request: attractionRequest, response: attractionResponse } = useRequest()
    const { request: fetchAttraction, response: fetchAttractionResponse } = useRequest()
    const { request: categoryRequest, response: categoryResponse } = useRequest()
    const { request: subCategoryRequest, response: subCategoryResponse } = useRequest()

    useEffect(() => {
        categoryRequest('GET', '/category')
    }, []);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategories(categoryResponse.data)
            if (!categoryId) {
                let id = categoryResponse.data.length > 0 ? categoryResponse.data[0].id : null
                setCategoryId(id)
            }
        }
    }, [categoryResponse]);

    useEffect(() => {
        // Get sub categories by category id
        if (categoryId) {

            subCategoryRequest('GET', `/sub-categories/${categoryId}`)
        }
    }, [categoryId]);

    useEffect(() => {
        if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
            setSubCategories(subCategoryResponse.data)
        }
    }, [subCategoryResponse]);

    useEffect(() => {
        if (subCategoryId) {
            setAttraction((prev) => ({
                ...prev,
                sub_category_id: subCategoryId,
            }));

            setErrors((prev) => ({
                ...prev,
                sub_category_id: subCategoryId ? '' : 'The sub category field is required.',
            }));
        }
    }, [subCategoryId]);

    useEffect(() => {
        if (attractionResponse && attractionResponse.status === "SUCCESS") {
            setAttractionId(attractionResponse.data.id)
            Toast(true, attractionResponse?.message)
            setLogo(null)
            logoRef.current.value = '';
            navigate(`/${country}/${lang}/admin/attraction`);
        } else {
            Toast(false, attractionResponse?.message)
        }
    }, [attractionResponse]);

    const handleInputEnable = (fieldName) => {
        setInputStates((prevState) => ({
            ...prevState,
            [fieldName]: false,
        }));
    };

    const handleMetaInputEnable = () => {
        if (!metaInputStates) {
            let formData = new FormData()
            formData.append('meta_title', attraction.meta_title)
            formData.append('meta_description', attraction.meta_description)
            formData.append('meta_tags', attraction.meta_tags)
            if (attractionId) {
                fetchAttraction('PUT', `/attractions/${attractionId}`, formData)
            } else {
                Toast(false, 'Create a company first')
            }
        }
        setMetaInputStates(metaInputStates ? false : true);
    };

    const handleAttractionChange = (e) => {
        const { name, value } = e.target;
        setAttraction((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({
            ...prev,
            [name]: value ? '' : `The ${name} field is required.`,
        }));
    };


    const handleLogoUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setLogo(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setErrors((prev) => ({ ...prev, logo: '' }));
        } else {
            setLogo(null)
            logoRef.current.value = '';
            setLogoPreview(null);
            setErrors((prev) => ({ ...prev, logo: 'Logo is required.' }));
        }
    };

    const handleDivClick = () => {
        if (logoRef.current) {
            logoRef.current.click();
        }
    };


    const handleSaveAttraction = (e) => {
        e.preventDefault();

        // Validate form inputs
        let newErrors = {};
        if (!attraction.title) newErrors.title = 'Title is required.';
        const logoInput = document.querySelector('input[name="logo"]');
        if (!logoInput?.files.length) newErrors.logo = 'Logo is required.';

        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }

        // Create form data
        let formData = new FormData();
        formData.append('title', attraction.title);
        if (attraction.status_text) formData.append('status_text', attraction.status_text);
        if (attraction.description) formData.append('description', attraction.description);
        if (attraction.meta_description) formData.append('meta_description', attraction.meta_description);
        if (attraction.meta_tags) formData.append('meta_tags', attraction.meta_tags);
        if (attraction.meta_name) formData.append('meta_name', attraction.meta_name);
        if (logo) formData.append('logo', logo);

        // Handle API request (either POST or PUT)
        if (id) {
            attractionRequest('PUT', `/attractions/${id}`, formData);
        } else {
            attractionRequest('POST', '/attractions', formData);
        }
    };

    return (
        <>
            <div class={`add-attraction-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div class="container-fluid">
                    <div class="bc-dashboard">
                        <ul>
                            <li><a href="#">Dashboard</a></li>
                            <li><Link to={`/${country}/${lang}/admin/attraction`}>Attraction</Link></li>
                            <li>Add Attraction</li>
                        </ul>
                    </div>
                    <div class="title-db">
                        <div class="row">
                            <div class="col-sm-2">
                                {errors.logo && <div className="error">{errors.logo}</div>}
                                <div className="field-title-db" onClick={handleDivClick} style={{ cursor: 'pointer', height: '119px' }}>
                                    <div className="attached-input" style={{ height: '90px', width: '90px' }}>
                                        <input type="file" className="form-control" name="logo" ref={logoRef} onChange={handleLogoUpload} style={{ display: 'none' }} required />
                                        {!logoPreview ? (
                                            <small style={{ fontSize: '13px'}}>Upload Logo or Photo</small>
                                        ) : (
                                            <img src={logoPreview} alt="Logo" className="img-circle" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-10">
                                <div class="row">
                                    <div class="col-sm-6">
                                        {errors.title && <div className="error">{errors.title}</div>}
                                        <div class="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name="title" value={attraction.title} onChange={handleAttractionChange} placeholder="Attraction" disabled={inputStates.title} required />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select onChange={(e) => setSubCategoryId(e.target.value)}>
                                                <option value="">Select sub category</option>
                                                {subCategories && subCategories.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.id}
                                                        selected={data?.id === subCategoryId ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name="title" value={attraction.title} onChange={handleAttractionChange} placeholder="Attraction Name" disabled={inputStates.title} />
                                            <button class="edit-text" onClick={() => handleInputEnable('title')}><i class="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='status_text' value={attraction.status_text} placeholder="Status" disabled={inputStates.status_text} />
                                            <button class="edit-text" onClick={() => handleInputEnable('status_text')}><i class="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-dashboard">
                        <HorizontalTab activeHorizontalTab={activeHorizontalTab} setActiveHorizontalTab={setActiveHorizontalTab} attractionId={attractionId} attraction={attraction} handleCompanyChange={handleAttractionChange} />
                    </div>
                    <div class="box-dashboard">
                        <div class="social-activity">
                            <div class="vtab">
                                <ul class="vtab-menu">
                                    <li data-tab="mystories-vtab" className={activeVerticalTab === 'story' ? 'active' : ''} onClick={() => setActiveVerticalTab('story')}><img src="/assets/images/icons/story.svg" alt="story" />/ MyStories</li>
                                    <li data-tab="myreels-vtab" className={activeVerticalTab === 'reel' ? 'active' : ''} onClick={() => setActiveVerticalTab('reel')}><img src="/assets/images/icons/reels.svg" alt="reels" /> MyReels</li>
                                </ul>
                                <div className="wraper-vtab">
                                    <div id="mystories-vtab" className={`vtab-content ${activeVerticalTab === 'story' ? 'active' : ''}`}>
                                        <AttractionStoryTab isVTavShow={activeVerticalTab === 'story' ? true : false} />
                                    </div>
                                    <div id="myreels-vtab" className={`vtab-content ${activeVerticalTab === 'reel' ? 'active' : ''}`}>
                                        <AttractionMyReelTab isVTavShow={activeVerticalTab === 'reel' ? true : false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="box-dashboard">
                        <button className="edit-text" onClick={handleMetaInputEnable}>{metaInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        <div class="meta-dbe">
                            <div class="heading">
                                <h2>SEO</h2>
                            </div>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>Meta Title*</label>
                                        <div class="field-meta-dbe">
                                            <input type="text" name='meta_title' value={attraction.meta_title} onChange={handleAttractionChange} placeholder="Meta Title" disabled={metaInputStates} />
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>Meta Tags*</label>
                                        <div class="field-meta-dbe">
                                            <input type="text" name='meta_tags' value={attraction.meta_tags} onChange={handleAttractionChange} placeholder="Meta Keywords" disabled={metaInputStates} />
                                        </div>
                                    </div>
                                </div>
                                <label>Meta Description*</label>
                                <div class="field-meta-dbe">
                                    <textarea placeholder="Meta Description" name='meta_description' value={attraction.meta_description} onChange={handleAttractionChange} disabled={metaInputStates}></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="submit-dbe">
                        <button type='button' onClick={handleSaveAttraction}>Save</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AttractionAdd;
