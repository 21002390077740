import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddPopularArea = () => {
    const { country, lang, category, popularAreaId } = useParams(); // Get id from the URL for edit mode
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        category_id: '',
        location_name: '',
        city: '',
        state: '',
        country: '',
        lat: '',
        long: '',
    });
    const [errors, setErrors] = useState({});
    const [categoryData, setCategoryData] = useState(null);
    const isEditMode = Boolean(popularAreaId);

    const { request: fetchPopularAreaRequest, response: popularAreaResponse } = useRequest();
    const { request: addPopularAreaRequest, response: addPopularAreaResponse } = useRequest();
    const { request: updatePopularAreaRequest, response: updatePopularAreaResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();

    const states = [
        { value: 'Dubai', label: 'Dubai' },
        { value: 'Abu Dhabi', label: 'Abu Dhabi' }
    ];

    const cities = [
        { value: 'Dubai', label: 'Dubai' },
        { value: 'Abu Dhabi', label: 'Abu Dhabi' }
    ];

    const countries = [
        { value: 'Dubai', label: 'Dubai' },
        { value: 'India', label: 'India' }
    ];

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data);
        }
    }, [categoryResponse]);

    // Fetch existing popular area data if in edit mode
    useEffect(() => {
        if (isEditMode) {
            fetchPopularAreaRequest('GET', `/popular-areas/${popularAreaId}`);
        }
    }, [isEditMode, popularAreaId]);

    useEffect(() => {
        if (popularAreaResponse && popularAreaResponse.status === 'SUCCESS' && isEditMode) {
            const { category_id, location_name, city, state, country, lat, long } = popularAreaResponse.data;
            setFormData({ category_id, location_name, city, state, country, lat, long });
        }
    }, [popularAreaResponse, isEditMode]);

    // Handle add/update popular area response
    useEffect(() => {
        const response = isEditMode ? updatePopularAreaResponse : addPopularAreaResponse;
        if (response && response.status === 'SUCCESS') {
            Toast(true, response.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/popular-area`);

        } else if (response) {
            Toast(false, response.message);
        }
    }, [addPopularAreaResponse, updatePopularAreaResponse]);


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.location_name) formErrors.location_name = 'Location Name is required';
        if (!formData.city) formErrors.city = 'City is required';
        if (!formData.country) formErrors.country = 'Country is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('category_id', categoryData.id);
        data.append('location_name', formData.location_name);
        data.append('city', formData.city);
        data.append('state', formData.state);
        data.append('country', formData.country);
        data.append('lat', formData.lat || '');  // Send empty if null
        data.append('long', formData.long || '');  // Send empty if null

        if (isEditMode) {
            await updatePopularAreaRequest('PUT', `/popular-areas/${popularAreaId}`, data, {
                headers: {
                    'Content-Type': 'application/form-data',
                },
            });
        } else {
            await addPopularAreaRequest('POST', '/popular-areas', data, {
                headers: {
                    'Content-Type': 'application/form-data',
                },
            });
        }
    };



    return (
        <div className={`add-popular-area container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/popular-area`}>Popular Area</Link></li>
                        <li>{isEditMode ? 'Edit Popular Area' : 'Add Popular Area'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>State</label>
                                    <div className="field-add-form">
                                        <select
                                            name="state"
                                            value={formData.state}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select State</option>
                                            {states.map(state => (
                                                <option key={state.value} value={state.value}>{state.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>City</label>
                                    <div className="field-add-form">
                                        <select
                                            name="city"
                                            value={formData.city}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select City</option>
                                            {cities.map(city => (
                                                <option key={city.value} value={city.value}>{city.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Country</label>
                                    <div className="field-add-form">
                                        <select
                                            name="country"
                                            value={formData.country}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Area</option>
                                            {countries.map(country => (
                                                <option key={country.value} value={country.value}>{country.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <label>Type Location Name</label>
                            <div className="field-add-form">
                                <input
                                    type="text"
                                    name="location_name"
                                    value={formData.location_name}
                                    onChange={handleInputChange}
                                    placeholder="Dubai Marina"
                                />
                            </div>
                            <div className="map-add-form">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1723187082398!5m2!1sen!2sin"
                                    allowFullScreen
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={isEditMode ? "Update" : "Save Changes"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPopularArea;
