import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AdminSubCategoryAdd = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState(null);

    const [subCategories, setSubCategories] = useState([{ value: '', error: '' }]);

    const { request: categroyRequest, response: categoryResponse } = useRequest()
    const { request: subCategroyAddRequest, response: subCategoryAddResponse } = useRequest()

    // Function to add more input fields
    const handleAddMore = () => {
        setSubCategories([...subCategories, { value: '', error: '' }]);
    };

    // Function to handle input changes
    const handleInputChange = (index, event) => {
        const newSubCategories = [...subCategories];
        newSubCategories[index].value = event.target.value;
        newSubCategories[index].error = ''; // Clear error on change
        setSubCategories(newSubCategories);
    };

    // Function to handle removing an input field
    const handleRemoveInput = (index) => {
        const newSubCategories = subCategories.filter((_, i) => i !== index);
        setSubCategories(newSubCategories);
    };

    // Function to handle form submission
    const handleSubmit = (event) => {
        event.preventDefault();

        let hasError = false;
        const validatedSubCategories = subCategories.map(subCategory => {
            if (subCategory.value.trim() === '') {
                hasError = true;
                return { ...subCategory, error: 'Subcategory is required' };
            }
            return { ...subCategory, error: '' };
        });

        if (hasError) {
            setSubCategories(validatedSubCategories);
            return;
        }

        // Prepare data to send to API
        const data = {
            sub_categories: subCategories.map(subCategory => ({
                category_id: categoryData.id, // Assuming category.id is available from the params
                name: subCategory.value
            }))
        };

        subCategroyAddRequest('POST', '/sub-category', data)
    };

    useEffect(() => {
        if (category) {
            categroyRequest('GET', `/category/${category}`)
        }
    }, []);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse?.data)
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (subCategoryAddResponse && subCategoryAddResponse.status === "SUCCESS") {
            Toast(true, subCategoryAddResponse?.message)
            navigate(`/${country}/${lang}/admin/directory/${category}/sub-category`);
        }else{
            Toast(false, subCategoryAddResponse?.message)
        }
    }, [subCategoryAddResponse]);

    return (
        <div className={`add-subcategory container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Dashboard</a></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/sub-category`}>Subcategory</Link></li>
                        <li>Add Subcategory</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            {subCategories.map((subCategory, index) => (
                                <div className="row" key={index}>
                                    <div className="col-sm-10">
                                        <label>Subcategory</label>
                                        {subCategory.error && <p className="error">{subCategory.error}</p>}
                                        <div className="field-add-form">
                                            <input
                                                type="text"
                                                placeholder="Type here"
                                                value={subCategory.value}
                                                onChange={(event) => handleInputChange(index, event)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="more-add-form">
                                            {index === subCategories.length - 1 ? (
                                                <button type="button" className="btn btn-primary" onClick={handleAddMore}>
                                                    Add More <i className="fa-solid fa-plus"></i>
                                                </button>
                                            ) : (
                                                <button type="button" className="btn btn-danger" onClick={() => handleRemoveInput(index)}>
                                                    Delete <i className="fa-solid fa-trash"></i>
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminSubCategoryAdd;
