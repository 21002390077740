import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import Pagination from '../../../components/Admin/Pagination';

const CustomerManagement = () => {
    const { isSidebarHidden } = useOutletContext();

    const [customers, setCustomers] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchCustomerRequest, response: customerResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: subCategoriesRequest, response: subCategoriesResponse } = useRequest();

    useEffect(() => {
        fetchCategoryRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === 'SUCCESS') {
            setCategoryData(categoryResponse.data || []);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData && categoryData.length > 0) {
            const selectedCategory = categoryData[0].id;
            subCategoriesRequest('GET', `/public/sub-category/${selectedCategory}`);
        }
    }, [categoryData]);

    useEffect(() => {
        if (subCategoriesResponse && subCategoriesResponse.status === 'SUCCESS') {
            setSubCategories(subCategoriesResponse.data || []);
        }
    }, [subCategoriesResponse]);

    const handleFetchCustomers = () => {
        fetchCustomerRequest(
            'GET',
            `/customers?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
        );
    };

    useEffect(() => {
        handleFetchCustomers();
    }, [currentPage, pageSize, categoryData, subCategories, searchQuery]);

    useEffect(() => {
        if (customerResponse && customerResponse.status === 'SUCCESS') {
            setCustomers(customerResponse.data || []);
            setTotalRecords(customerResponse.total || 0);
        }
    }, [customerResponse]);

    return (
        <div>
            <div className={`customer-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Dashboard</a></li>
                            <li>Customer Management</li>
                        </ul>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db">
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </div>
                            <div className="btn-filter">
                                <a href="#">Suspended Account</a>
                                <a href="#">Re-Activate Account</a>
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Category</th>
                                        <th>Subcategory</th>
                                        <th>Active</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customers.length > 0 ? (
                                        customers.map((customer) => (
                                            <tr key={customer.id}>
                                                <td>{customer.name}</td>
                                                <td>{customer.email}</td>
                                                <td>{customer.user_profile?.category?.name || 'N/A'}</td>
                                                <td>{customer.user_profile?.sub_category?.name || 'N/A'}</td>
                                                <td><div className="btn-toggle">
                                                    <input type="checkbox" className="checkbox-toggle" />
                                                    <div className="txt-toggle"> <span>YES</span> </div>
                                                    <div className="layer-toggle"></div>
                                                </div></td>
                                                <td>
                                                    <div class="action-table"><a href="#">Edit <i class="fa-solid fa-pencil"></i></a>
                                                        <a href="#">Delete<i class="fa-solid fa-trash"></i></a></div>
                                                </td>

                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No customers found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            currentPage={currentPage}
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                            onPageSizeChange={(size) => setPageSize(size)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerManagement;