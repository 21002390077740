import React from 'react';
import { useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

const CustomerSideBar = ({ hideSidebar, showSidebar, isSidebarHidden }) => {
    const { country, lang } = useParams();

    return (
        <>
            <div className={`sidebar-dashboard ${isSidebarHidden ? 'hidden' : ''}`} id="sidebar-dashboard">
                <button className="toggle-sidebar" onClick={hideSidebar} style={{ display: isSidebarHidden ? 'none' : 'block' }} id="hide-sidebar"><i className="fa-solid fa-angle-left"></i></button>
                <button className="toggle-sidebar" onClick={showSidebar} style={{ display: isSidebarHidden ? 'block' : 'none' }} id="show-sidebar"><i className="fa-solid fa-angle-right"></i></button>
                <div className="logo-sidebar"><Link to={`/${country}/${lang}/customer`}><img src="/assets/images/logo.svg" alt="MyFinder" /></Link></div>
                <ul className="menu-sidebar">
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/list.svg" alt="list" /></i>
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/company-profile`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/list.svg" alt="list" /></i>
                            Company/Module
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/listings`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/lists.svg" alt="lists" /></i>
                            My Listing/MyStores
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/agents`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/user.svg" alt="user" /></i>
                            MyAgents
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/mystory`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/story.svg" alt="story" /></i>
                            MyStory
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/myreel`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/reels.svg" alt="reels" /></i>
                            MyReels
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/mylive`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/live.svg" alt="live" /></i>
                            MyLive
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/myinqueries`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/enquiry.svg" alt="enquiry" /></i>
                            MyInquiries
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/post_job`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/jobs.svg" alt="jobs" /></i>
                            Post a Job
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/mycv`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/cv.svg" alt="cv" /></i>
                            MyCV's
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/myreview`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/review.svg" alt="review" /></i>
                            MyReview
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/my_recommendation`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/recommendation.svg" alt="recommendation" /></i>
                            MyRecommendation
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/community`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/community.svg" alt="community" /></i>
                            My Community
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/customer/announcement`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/thumb-1.svg" alt="thumb-1" /></i>
                            Announcement
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default CustomerSideBar;