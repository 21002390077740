import { useEffect, useState } from 'react';
import useRequest from './useRequest'; // Import your custom useRequest hook

// Create a custom hook to fetch user data
export const useGetUser = () => {
    const [user, setUser] = useState(null);
    const { request: fetchUserRequest, response: userResponse } = useRequest();

    // Fetch user data on mount
    useEffect(() => {
        fetchUserRequest('GET', '/auth/user');
    }, []); // Call only on component mount

    // Update user state when the API response is available
    useEffect(() => {
        if (userResponse && userResponse.status === 'SUCCESS') {
            setUser(userResponse.data);
        }
    }, [userResponse]); // Trigger when the response changes

    return user; // Return the fetched user data
};
