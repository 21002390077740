import { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { Toast } from "../../../utils/ToastNotification";
import { cloudFrontUrl } from "../../../config";
const Announcement = () => {
  const { country, lang } = useParams();
  const { isSidebarHidden } = useOutletContext();
  const navigate = useNavigate();
  const [announcements, setAnnouncements] = useState([]);

  const { request: fetchAnnouncementRequest, response: announcementResponse } =
    useRequest();
  const { request: addAnnouncementRequest, response: addAnnouncementResponse } =
    useRequest();
  const {
    request: deleteAnnouncementRequest,
    response: announcementDeleteResponse,
  } = useRequest();

  useEffect(() => {
    fetchAnnouncementRequest("GET", "/announcements/latest");
  }, []);

  useEffect(() => {
    if (announcementResponse && announcementResponse.status === "SUCCESS") {
      setAnnouncements([announcementResponse.data]);
    }
  }, [announcementResponse]);

  useEffect(() => {
    if (addAnnouncementResponse) {
      if (addAnnouncementResponse.status === "SUCCESS") {
        Toast(true, addAnnouncementResponse.message);
        navigate(`/${country}/${lang}/admin/announcement`);
      } else {
        Toast(false, addAnnouncementResponse.message);
      }
    }
  }, [addAnnouncementResponse, country, lang, navigate]);

  
  useEffect(() => {
    if (announcementDeleteResponse) {
      if (announcementDeleteResponse.status === "SUCCESS") {
        Toast(true, announcementDeleteResponse.message);
        fetchAnnouncementRequest("GET", "/announcements/latest");
      } else {
        Toast(false, announcementDeleteResponse.message);
      }
    }
  }, [announcementDeleteResponse, fetchAnnouncementRequest]);




  return (
    <div
      className={`announcement-page container-dashboard ${
        isSidebarHidden ? "sidebar-dashboard-hidden" : ""
      }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <h1>Announcement</h1>
          <div className="box-dashboard">
            {announcements.map((announcement) => (
              <div key={announcement.id} className="announcement-add-form">
                <img
                  src={cloudFrontUrl + announcement.image}
                  className="announcement-image-preview"
                  alt="announcement"
                />
              </div>
            ))}
          </div>
      </div>
    </div>
  );
};
export default Announcement;
