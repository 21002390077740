import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';

const UserSocialMedia = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [socialMedia, setSocialMedia] = useState([]);

    const { request: fetchSocialMediaRequest, response: socialMediaResponse } = useRequest();
    const { request: deleteSocialMediaRequest, response: deleteSocialMediaResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchSocialMedia = () => {
        fetchSocialMediaRequest(
            'GET',
            '/social-media'
        );
    };

    useEffect(() => {
        handleFetchSocialMedia();
    }, []);

    useEffect(() => {
        if (socialMediaResponse && socialMediaResponse.status === "SUCCESS") {
            setSocialMedia(socialMediaResponse?.data || []);
        }
    }, [socialMediaResponse]);

    useEffect(() => {
        if (deleteSocialMediaResponse && deleteSocialMediaResponse.status === "SUCCESS") {
            Toast(true, deleteSocialMediaResponse?.message);
            handleFetchSocialMedia();
        } else if (deleteSocialMediaResponse) {
            Toast(false, deleteSocialMediaResponse?.message);
        }
    }, [deleteSocialMediaResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchSocialMedia();
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handleDeleteSocialMedia = (id) => {
        deleteSocialMediaRequest('DELETE', `/social-media/${id}`);
    };

    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        updateStatusRequest('PUT', `/social-media/${id}`, { is_active: updatedStatus });
    };

    return (
        <div className={`social-media-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to={`/${country}/${lang}/admin`}>Dashboard</Link></li>
                        <li>Social Media</li>
                    </ul>
                    <div className="btn-bc">
                        <Link to={`/${country}/${lang}/admin/social/add`} className="btn btn-primary">
                            Add Social Media <i className="fa-solid fa-plus"></i>
                        </Link>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {socialMedia.map((media) => (
                                    <tr key={media.id}>
                                        <td>{media.social_media_name}</td>
                                        <td>
                                            <div className="btn-toggle">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-toggle"
                                                    checked={!media.is_active} onChange={() => handleToggleStatus(media.id, media.is_active)}
                                                />
                                                <div className="txt-toggle">
                                                    <span>{media.is_active ? "YES" : "NO"}</span>
                                                </div>
                                                <div className="layer-toggle"></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-table">
                                                <Link to={`/${country}/${lang}/admin/social/edit/${media.id}`}>
                                                    Edit <i className="fa-solid fa-pencil"></i>
                                                </Link>
                                                <a href="#" onClick={() => handleDeleteSocialMedia(media.id)}>
                                                    Delete <i className="fa-solid fa-trash"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserSocialMedia;