import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { useEffect, useState } from "react";
import { Toast } from "../../../utils/ToastNotification";

const MyStory = () => {
  const { country, lang } = useParams();
  const { isSidebarHidden } = useOutletContext();
  const { t } = useTranslation();
  const [story, setStory] = useState([]);
  //   const navigate = useNavigate();
  const { request: request, response: response } = useRequest();
  const { request: deleteRequest, response: deleteResponse } = useRequest();
  const fetchStory = () => {
    request("GET", "/my-story");
  };

  useEffect(() => {
    fetchStory();
  }, []);

  useEffect(() => {
    if (response && response.status === "SUCCESS") {
      setStory(response.data);
    }
  }, [response, setStory]);
  console.log(story);

  const handleDelete = (id) => {
    deleteRequest("DELETE", `/my-story/${id}`);
  };

  useEffect(() => {
    if (deleteResponse && deleteResponse.status === "SUCCESS") {
      fetchStory();
      Toast(true, deleteResponse?.message);
    } else {
      Toast(false, deleteResponse?.message);
    }
  }, [deleteResponse]);
  return (
    <div
      className={`story-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
        }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="bc-dashboard">
          <ul>
            <li>
              <a href="#">Dashboard</a>
            </li>
            <li>Story</li>
          </ul>
        </div>
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input type="text" placeholder="Search" />
              </form>
            </div>
            <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">Company</a>
              <a href="#" className="btn btn-primary">Store / Listing</a>
            </div>
            <div className="btn-filter">
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />{" "}
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />{" "}
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <tbody>
                <tr>
                  <th>
                    Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Total
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    View
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Active
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Action
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>
                {story.map((item, index) => (
                  <tr key={index}>
                    <td>Lorem ipsum dolor sit amet</td>
                    <td>5</td>
                    <td>6384</td>
                    <td>
                      <div className="btn-toggle">
                        <input
                          type="checkbox"
                          className="checkbox-toggle"
                        />
                        <div className="txt-toggle">
                          <span>YES</span>
                        </div>
                        <div className="layer-toggle"></div>
                      </div>
                    </td>
                    <td>
                      <div className="action-table">
                        <a href="javascript:void(0);">
                          Edit <i className="fa-solid fa-pencil"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          onClick={() => handleDelete(item.id)}
                        >
                          Delete<i className="fa-solid fa-trash"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <div className="show-per-page">
              <label>Show per page:</label>
              <select>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="info-pagination">
              <span>1 - 10 of 11 items</span>
              <div className="control-pagination">
                <button className="active">1</button>
                <button>2</button>
                <button>3</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyStory;
