import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ categoriesStats }) => {
    const labels = categoriesStats && categoriesStats.map(category => category.name || 'Unknown');
    const dataValues = categoriesStats && categoriesStats.map(category => category.total_listings || 0);

    const data = {
        labels: labels ? labels : ['Property', 'Job', 'Events', 'Venue'],
        datasets: [
            {
                label: '# of Listings',
                data: dataValues.length > 0 ? dataValues : [35, 14, 10, 7],
                backgroundColor: ['#0000CD', '#F400A1', '#FF7F50', '#6699CC', '#FF69B4', '#FA8072', '#FA8098', '#FA3268', '#FA8085', '#FA8788', '#FA2020', '#FA8654', '#FA2609', '#FA8027'],
                borderColor: [
                    '#FFFFFF', // White border to create gaps
                ],
                borderWidth: 5, // Increase border width for visibility
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    usePointStyle: true, // Use circle markers for the legend
                    boxWidth: 10, // Marker size
                },
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const label = tooltipItem.label || ''; // Get the label
                        const value = tooltipItem.raw || 0; // Get the value
                        return `${label}: ${value}`; // Format the tooltip
                    },
                },
            },
        },
        cutout: '50%',
    };

    return (
        <div style={{ width: '400px', height: '400px' }}>
            <Doughnut data={data} options={options} />
        </div>
    );
}

export default DoughnutChart;
