import React from 'react';
import { Navigate } from 'react-router-dom';
import { getUserLocale } from './getUserLocale';

const Authenticated = ({ children }) => {
    const { lang, country } = getUserLocale();
    const accessToken = localStorage.getItem('access_token');

    if (accessToken) {
        // Redirect to the secure page if token is present
        return <Navigate to={`/${country}/${lang}`} replace />;
    }

    // Render children if no token is present
    return children;
};

export default Authenticated;
