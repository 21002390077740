import React, { useEffect, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import Pagination from "../../../../components/Admin/Pagination";
import { Toast } from "../../../../utils/ToastNotification";

const Faqs = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [faqs, setFaqs] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchFaqsRequest, response: faqsResponse } = useRequest();
    const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
    const { request: deleteFaqRequest, response: deleteFaqResponse } = useRequest();

    const handleFetchFaqs = () => {
        if (categoryData) {
            fetchFaqsRequest(
                'GET',
                `/faqs?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}&category_id=${categoryData.id}`
            );
        }
    };

    useEffect(() => {
        // Fetch category data using the category slug from the URL parameters
        if (category) {
            fetchCategoriesRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        // Set category data after fetching and trigger fetching amenities
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategoryData(categoriesResponse.data || {});
        }
    }, [categoriesResponse]);

    useEffect(() => {
        // Fetch category data using the category slug from the URL parameters
        if (categoryData) {
            handleFetchFaqs();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (faqsResponse && faqsResponse.status === "SUCCESS") {
            setFaqs(faqsResponse?.data || []);
            setTotalRecords(faqsResponse?.total || 0);
        }
    }, [faqsResponse]);

    useEffect(() => {
        if (deleteFaqResponse && deleteFaqResponse.status === "SUCCESS") {
            Toast(true, deleteFaqResponse?.message);
            handleFetchFaqs();
        } else if (deleteFaqResponse) {
            Toast(false, deleteFaqResponse?.message);
        }
    }, [deleteFaqResponse]);

    const handleDeleteFaq = (id) => {
        deleteFaqRequest('DELETE', `/faqs/${id}`);
    };

    return (
        <div
            className={`faq-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`}
            id="container-dashboard"
        >
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>FAQs</li>
                    </ul>
                    <div className="btn-bc">
                        <Link to={`/${country}/${lang}/admin/directory/${category}/faqs/add`} className="btn btn-primary">
                            Add FAQs <i className="fa-solid fa-plus"></i>
                        </Link>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Question<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Answer<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {faqs.map(faq => (
                                    <tr key={faq.id}>
                                        <td>{faq.question}</td>
                                        <td>{faq.answer}</td>
                                        <td>
                                            <div className="action-table">
                                                <Link to={`/${country}/${lang}/admin/directory/${category}/faqs/edit/${faq.id}`}>
                                                    Edit <i className="fa-solid fa-pencil"></i>
                                                </Link>
                                                <a href="#" onClick={() => handleDeleteFaq(faq.id)}>
                                                    Delete <i className="fa-solid fa-trash"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={totalRecords}
                        currentPage={currentPage}
                        pageSize={pageSize}
                        onPageChange={setCurrentPage}
                        onPageSizeChange={setPageSize}
                    />
                </div>
            </div>
        </div>
    );
};

export default Faqs;