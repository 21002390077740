import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .use(LanguageDetector) // Detects user language.
  .use(HttpApi) // Loads translations using HTTP.
  .init({
    supportedLngs: ['en', 'ae'], // List your supported languages here.
    fallbackLng: 'en', // Fallback language if user language is not available.
    debug: true,
    detection: {
      order: ['localStorage', 'cookie', 'htmlTag', 'path', 'subdomain'],
      caches: ['localStorage', 'cookie'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to translation files.
    },
    react: {
      useSuspense: false, // Turn off suspense.
    },
  });

export default i18n;
