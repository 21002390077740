import React from 'react';
import { useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

const GuestSideBar = ({ hideSidebar, showSidebar, isSidebarHidden }) => {
    const { country, lang } = useParams();

    return (
        <>
            <div className={`sidebar-dashboard ${isSidebarHidden ? 'hidden' : ''}`} id="sidebar-dashboard">
                <button className="toggle-sidebar" onClick={hideSidebar} style={{ display: isSidebarHidden ? 'none' : 'block' }} id="hide-sidebar"><i className="fa-solid fa-angle-left"></i></button>
                <button className="toggle-sidebar" onClick={showSidebar} style={{ display: isSidebarHidden ? 'block' : 'none' }} id="show-sidebar"><i className="fa-solid fa-angle-right"></i></button>
                <div className="logo-sidebar"><Link to={`/${country}/${lang}/customer`}><img src="/assets/images/logo.svg" alt="MyFinder" /></Link></div>
                <ul className="menu-sidebar">
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/dashboard.svg" alt="dashboard" /></i>
                            Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/upload_cv`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/upload-2.svg" alt="upload-2" /></i>
                            Upload a CVs
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/applied_jobs`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/jobs.svg" alt="jobs" /></i>
                            Applied Jobs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/reviews`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/review.svg" alt="review" /></i>
                            Reviews
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/recommendation`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/recommendation.svg" alt="recommendation" /></i>
                            Recommendation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/inquiries`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/enquiry.svg" alt="enquiry" /></i>
                            Inquiries
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/mycommunity`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/community.svg" alt="community" /></i>
                            MyCommunity
                        </NavLink>
                    </li>

                    <li>
                        <NavLink
                            to={`/${country}/${lang}/guest/announcement`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                            end
                        >
                            <i><img src="/assets/images/icons/thumb-1.svg" alt="thumb-1" /></i>
                            Announcement
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default GuestSideBar;
