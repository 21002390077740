import React, { useState, useEffect, useRef } from 'react';
import useRequest from '../../../../hooks/useRequest';
import { cloudFrontUrl } from '../../../../config';
import { Toast } from '../../../../utils/ToastNotification';

const Gallery = ({ listingId }) => {
    const imageRef = useRef(null)

    const [galleries, setGalleries] = useState([])
    const { request: request, response: response } = useRequest();
    const { request: addRequest, response: addResponse } = useRequest();
    const { request: deleteRequest, response: deleteResponse } = useRequest();

    const handleFetchGallery = () => {
        request('GET', `/listing-galleries/${listingId}`);
    };

    const handleImageUpload = (e) => {
        if (e.target.files.length > 0) {
            if(!listingId){
                Toast(false, 'Create listing first')
                return false
            }
            let formData = new FormData()
            formData.append('listing_id', listingId)
            formData.append('image', e.target.files[0])
            addRequest('POST', `/listing-gallery`, formData)
        }
    }

    const handleDeleteGallery = (id) => {
        deleteRequest('DELETE', `/listing-gallery/${id}`)
    }

    useEffect(() => {
        if (listingId) {
            handleFetchGallery();
        }
    }, [listingId]);

    useEffect(() => {
        if (response && response.status === 'SUCCESS') {
            setGalleries(response.data);
        }
    }, [response]);

    useEffect(() => {
        if (addResponse && addResponse.status === 'SUCCESS') {
            Toast(true, addResponse?.message)
            handleFetchGallery();
            imageRef.current.value = null
        }else{
            Toast(false, addResponse?.message)
        }
    }, [addResponse]);

    useEffect(() => {
        if (deleteResponse && deleteResponse.status === 'SUCCESS') {
            Toast(true, deleteResponse?.message)
            handleFetchGallery();
        }else{
            Toast(false, deleteResponse?.message)
        }
    }, [deleteResponse]);

    return (
        <>
            <div className="row">
                <div className="col-sm-10">
                    <div class="attached-input">
                        <input type="file" ref={imageRef} onChange={handleImageUpload} />
                        <small><i className="fa-solid fa-plus"></i> Upload Your Gallery / Photo</small>
                    </div>
                </div>
                <div className="col-sm-2">
                    {galleries && galleries.slice(0, 4).map((data, index) => (
                        <div className="prev-upload-gallery" key={index}>
                            <img src={cloudFrontUrl + data?.image} alt="alt" />
                            <button className="delete-text" onClick={() => handleDeleteGallery(data?.id)}>
                                <i className="fa-solid fa-minus"></i>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Gallery;
