import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getUserLocale } from '../utils/getUserLocale';

export const PrivateRoute = ({ requiredType }) => {
  const { lang, country } = getUserLocale();
  const isAuth = Boolean(localStorage.getItem("access_token"));
  const userType = localStorage.getItem("user_type");

  const isAuthorized = isAuth && (!requiredType || userType === requiredType);
  if(!isAuthorized){
    localStorage.clear()
  }

  return isAuthorized ? <Outlet /> : <Navigate to={`/${country}/${lang}/auth/login`} />;
};
