import React, { useEffect, useState, useRef } from 'react';
import { useParams, useOutletContext, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';
import HTabModule from './TabModule/HTabModule';
import MyStoryTab from './TabModule/MyStoryTab';
import MyReelTab from './TabModule/MyReelTab';

const Customer = () => {
    const { country, language } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [activeHorizontalTab, setActiveHorizontalTab] = useState('about');
    const [activeVerticalTab, setActiveVerticalTab] = useState('story');

    // Get user data
    const [user, setUser] = useState(null);

    // User request 
    const { request: userRequest, response: userResponse } = useRequest()
    const { request: userUpdateRequest, response: userUpdateResponse } = useRequest()

    // Category and Sub category List and Selected data
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState(null);

    // Category and Sub category request
    const { request: categroyRequest, response: categoryResponse } = useRequest()
    const { request: subCategroyRequest, response: subCategoryResponse } = useRequest()

    // User update states
    const [company, setCompany] = useState({
        category_id: null,
        sub_category_id: null,
        name: null,
        status: null,
        description: null,
        meta_name: null,
        meta_description: null,
        meta_tags: null
    });

    const [errors, setErrors] = useState({
        name: '',
        logo: '',
        category_id: '',
        sub_category_id: '',
        status: '',
    });

    const [inputStates, setInputStates] = useState({
        name: true,
        status: true,
        description: true,
        meta_name: true,
        meta_description: true,
        meta_tags: true
    });

    const [logoPreview, setLogoPreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const logoRef = useRef();
    const [isInitial, setIsInitial] = useState(true);

    // Get initial data
    useEffect(() => {
        userRequest('GET', '/auth/user')
    }, []);

    useEffect(() => {
        if (userResponse && userResponse.status === "SUCCESS") {
            setUser(userResponse.data)
        }
    }, [userResponse]);

    useEffect(() => {
        if (user && isInitial) {
            setIsInitial(false)
            categroyRequest('GET', '/category')
            setSubCategoryId(user?.user_profile?.sub_category?.id)
            if(user?.user_profile?.logo == null) {
                setLogoPreview(user?.user_profile?.logo)
            }else{
                setLogoPreview(cloudFrontUrl + user?.user_profile?.logo)
            }
            
            setCompany(
                {
                    category_id: user?.user_profile?.category?.id,
                    sub_category_id: user?.user_profile?.sub_category?.id,
                    name: user?.name,
                    description: user?.user_profile?.description,
                    status: user?.user_profile?.status,
                    meta_name: user?.user_profile?.meta_name,
                    meta_description: user?.user_profile?.meta_description,
                    meta_tags: user?.user_profile?.meta_tags
                }
            )
        }
    }, [user]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategories(categoryResponse.data)
            if (!categoryId) {
                let id = categoryResponse.data.length > 0 ? categoryResponse.data[0].id : null
                setCategoryId(id)
            }
        }
    }, [categoryResponse]);

    useEffect(() => {
        // Get sub categories by category id
        if (categoryId) {
            setCompany((prev) => ({
                ...prev,
                category_id: categoryId,
            }));

            setErrors((prev) => ({
                ...prev,
                category_id: categoryId ? '' : 'The category field is required.',
            }));

            subCategroyRequest('GET', `/sub-categories/${categoryId}`)
        }
    }, [categoryId]);

    useEffect(() => {
        if (categories) {
            setCategoryId(user?.user_profile?.category?.id)
        }
    }, [categories]);

    useEffect(() => {
        if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
            setSubCategories(subCategoryResponse.data)
        }
    }, [subCategoryResponse]);

    useEffect(() => {
        if (subCategoryId) {
            setCompany((prev) => ({
                ...prev,
                sub_category_id: subCategoryId,
            }));

            setErrors((prev) => ({
                ...prev,
                sub_category_id: subCategoryId ? '' : 'The sub category field is required.',
            }));
        }
    }, [subCategoryId]);

    const handleCompanyChange = (e) => {
        const { name, value } = e.target;
        setCompany((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({
            ...prev,
            [name]: value ? '' : `The ${name} field is required.`,
        }));
    };

    const handleInputEnableUpdate = (fieldName) => {
        setInputStates((prevState) => ({
            ...prevState,
            [fieldName]: !prevState[fieldName], // Toggle the state between true and false
        }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setLogo(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setErrors((prev) => ({ ...prev, logo: '' }));
            logoRef.current.value = '';
        } else {
            setLogo(null)
            logoRef.current.value = '';
            setLogoPreview(null);
            setErrors((prev) => ({ ...prev, logo: 'Logo is required.' }));
        }
    };

    const handleDivClick = () => {
        if (logoRef.current) {
            logoRef.current.click();
        }
    };

    // useEffect(() => {
    //     if (logo) {
    //         handleUserUpdate();
    //     }
    // }, [logo]);

    const handleUserUpdate = () => {
        // Check for required fields
        let newErrors = {};

        if (!company.name) newErrors.name = 'Company name is required.';

        if (!logoPreview) newErrors.logo = 'Logo is required.';

        if (Object.keys(newErrors).length) {
            setErrors(newErrors);
            return;
        }


        let formData = new FormData()
        if (company.name) formData.append('name', company.name);
        if (company.status) formData.append('status_text', company.status);
        if (company.description) formData.append('description', company.description);
        if (company.category_id) formData.append('category_id', company.category_id);
        if (company.sub_category_id) formData.append('sub_category_id', company.sub_category_id);
        if (company.meta_name) formData.append('meta_name', company.meta_name);
        if (company.meta_tags) formData.append('meta_tags', company.meta_tags);
        if (company.meta_description) formData.append('meta_description', company.meta_description);
        if (logo) formData.append('logo', logo); // Append the logo if it's provided

        // Update user data
        userUpdateRequest('PUT', `/auth/user`, formData)
    }

    useEffect(() => {
        if (userUpdateResponse && userUpdateResponse.status === "SUCCESS") {
            setUser(userUpdateResponse.data)
            setCategoryId(userUpdateResponse?.user_profile?.category?.id)
            Toast(true, userUpdateResponse?.message)
        } else {
            Toast(false, userUpdateResponse?.message)
        }
    }, [userUpdateResponse]);

    return (
        <>
            <div className={`company-profile container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="title-db">
                        <div className="row">
                            <div className="col-sm-2">
                                {errors.logo && <div className="error">{errors.logo}</div>}
                                <div className="field-title-db" onClick={handleDivClick} style={{ cursor: 'pointer', width: '130px', height: '120px' }}>
                                    <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                    <div className="attached-input" style={{ width: "120px", height: "100px" }}>
                                        <input type="file" className="form-control" name="logo" ref={logoRef} onChange={handleLogoUpload} style={{ display: 'none' }} required />
                                        {!logoPreview ? (
                                            <small style={{ fontSize: '16px' }}>Upload Logo or Photo</small>
                                        ) : (
                                            <img src={logoPreview} alt="Logo" className="img-circle" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="row">
                                    <div className="col-sm-6">
                                        {errors.category_id && <div className="error">{errors.category_id}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select onChange={(e) => setCategoryId(e.target.value)}>
                                                <option value="">Select category</option>
                                                {categories && categories.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.id}
                                                        selected={data?.id === categoryId ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.sub_category_id && <div className="error">{errors.sub_category_id}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select onChange={(e) => setSubCategoryId(e.target.value)}>
                                                <option value="">Select sub category</option>
                                                {subCategories && subCategories.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.id}
                                                        selected={data?.id === subCategoryId ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {errors.name && <div className="error">{errors.name}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='name' value={company.name} onChange={handleCompanyChange} placeholder="Type Your Company Name / Name" disabled={inputStates.name} required />
                                            <button className="edit-text" onClick={() => handleInputEnableUpdate('name')}>{inputStates.name ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='status' value={company.status} onChange={handleCompanyChange} placeholder="Status" disabled={inputStates.status} required />
                                            <button className="edit-text" onClick={() => handleInputEnableUpdate('status')}>{inputStates.status ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <HTabModule activeHorizontalTab={activeHorizontalTab} setActiveHorizontalTab={setActiveHorizontalTab} customerId={user?.id} company={company} handleCompanyChange={handleCompanyChange} inputStates={inputStates} handleInputEnableUpdate={handleInputEnableUpdate} />
                    </div>
                    <div className="box-dashboard">
                        <div className="social-activity">
                            <div className="vtab">
                                <ul className="vtab-menu">
                                    <li data-tab="mystories-vtab" className={activeVerticalTab === 'story' ? 'active' : ''} onClick={() => setActiveVerticalTab('story')}><img src="/assets/images/icons/story.svg" alt="story" /> MyStories
                                    </li>
                                    <li data-tab="myreels-vtab" className={activeVerticalTab === 'reel' ? 'active' : ''} onClick={() => setActiveVerticalTab('reel')}><img src="/assets/images/icons/reels.svg" alt="reels" /> MyReels</li>
                                </ul>
                                <div className="wraper-vtab">
                                    <div id="mystories-vtab" className={`vtab-content ${activeVerticalTab === 'story' ? 'active' : ''}`}>
                                        <MyStoryTab user={user} activeVerticalTab={activeVerticalTab} />
                                    </div>
                                    <div id="myreels-vtab" className={`vtab-content ${activeVerticalTab === 'reel' ? 'active' : ''}`}>
                                        <MyReelTab user={user} activeVerticalTab={activeVerticalTab} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="meta-dbe">
                            <div className="heading">
                                <h2>SEO</h2>
                            </div>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Meta Title*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_name' value={company.meta_name} onChange={handleCompanyChange} placeholder="Meta Title" disabled={inputStates.meta_name} />
                                            <button type='button' className="edit-text" onClick={() => handleInputEnableUpdate('meta_name')}>{inputStates.meta_name ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Meta Tags*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_tags' value={company.meta_tags} onChange={handleCompanyChange} placeholder="Meta Keywords" disabled={inputStates.meta_tags} />
                                            <button type='button' className="edit-text" onClick={() => handleInputEnableUpdate('meta_tags')}>{inputStates.meta_tags ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                                        </div>
                                    </div>
                                </div>
                                <label>Meta Description*</label>
                                <div className="field-meta-dbe">
                                    <textarea placeholder="Meta Description" name='meta_description' value={company.meta_description} onChange={handleCompanyChange} disabled={inputStates.meta_description}></textarea>
                                    <button type='button' className="edit-text" onClick={() => handleInputEnableUpdate('meta_description')}>{inputStates.meta_description ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="submit-dbe">
                        <button type='button' onClick={handleUserUpdate}>Save</button>
                        {/* <a href="#" className="btn btn-primary">Add Agent <i className="fa-solid fa-plus"></i></a> */}
                        <Link to={`/${country}/${language}/customer/agents`} className="btn btn-primary"> Add Agent <i className="fa-solid fa-plus"></i></Link>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Customer;
