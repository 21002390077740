import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Toast } from "../../utils/ToastNotification";
import useRequest from "../../hooks/useRequest";

const Login = () => {
    const { country, lang } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        email: "",
        password: ""
    });
    const { request: loginRequest, response: loginResponse } = useRequest()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to backend
        console.log("Form data submitted:", formData);
        loginRequest('POST', '/auth/login', formData);
    };

    useEffect(() => {
        if (loginResponse && loginResponse?.status === "SUCCESS") {
            Toast(true, loginResponse?.message)
            if (!loginResponse?.data?.is_email_verify) {
                // navigate(`/${country}/${lang}/auth/otp-verification`)
            } else {
                localStorage.setItem("access_token", loginResponse.data?.access_token)
                localStorage.setItem("refresh_token", loginResponse.data?.refresh_token)
                localStorage.setItem("name", loginResponse.data?.name)
                localStorage.setItem("username", loginResponse.data?.username)
                localStorage.setItem("user_type", loginResponse.data?.user_type)
                localStorage.setItem("user_id", loginResponse.data?.id)
                if (loginResponse.data?.user_type === "ADMIN") {
                    navigate(`/${country}/${lang}/admin`);
                } else if (loginResponse.data?.user_type === "GUEST") {
                    navigate(`/${country}/${lang}/guest`);
                } else {
                    navigate(`/${country}/${lang}/customer`);
                }
            }
        } else {
            Toast(false, loginResponse?.message)
        }
    }, [loginResponse]);

    return (
        <>
            <div className="login-page">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 offset-4">
                            <div className="logo"><img src="/assets/images/logo.svg" alt="MyFinder" /></div>
                            <form onSubmit={handleSubmit}>
                                <div className="field-login"><i className="fa-solid fa-user"></i>
                                    <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Username" required />
                                </div>
                                <div className="field-login"><i className="fa-solid fa-lock"></i>
                                    <input type="password" name="password" value={formData.password} onChange={handleChange} placeholder="Password" required />
                                </div>
                                <input type="submit" value="Login" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Login;