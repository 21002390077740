import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AmenityAdd = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        icon: null,
        is_active: true
    });
    const [errors, setErrors] = useState({});
    const [previewIcon, setPreviewIcon] = useState(null);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: addAmenityRequest, response: amenityAddResponse } = useRequest();

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (amenityAddResponse && amenityAddResponse.status === "SUCCESS") {
            Toast(true, amenityAddResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/amenities`);
        } else if (amenityAddResponse) {
            Toast(false, amenityAddResponse.message);
        }
    }, [amenityAddResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'icon') {
            setFormData({ ...formData, icon: files[0] });
            setPreviewIcon(URL.createObjectURL(files[0])); // Set preview of the icon
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.icon) formErrors.icon = 'Icon is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('name', formData.name);
        data.append('icon', formData.icon);
        data.append('category_id', categoryData.id);
        data.append('language', lang);
        data.append('is_active', formData.is_active);

        addAmenityRequest('POST', '/amenity', data);
    };

    return (
        <div className={`add-amenity container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Venues</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/amenities`}>Amenity</Link></li>
                        <li>Add Amenity</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Name</label>
                                    {errors.name && <p className="error">{errors.name}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Amenity Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Icon</label>
                                    {errors.icon && <p className="error">{errors.icon}</p>}
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="icon"
                                            onChange={handleInputChange}
                                        />
                                        <small>Upload Icon <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Preview</label>
                                    <div className="preview-add-form">
                                        {previewIcon ? (
                                            <img src={previewIcon} alt="Icon Preview" />
                                        ) : (
                                            <p>No icon selected</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmenityAdd;
