import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const HelpCenter = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [helpCenter, setHelpCenter] = useState(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        email: '',
        whatsapp_number: ''
    });

    const { request: fetchHelpCenterRequest, response: helpCenterResponse } = useRequest();
    const { request: addOrUpdateHelpCenterRequest, response: addOrUpdateHelpCenterResponse } = useRequest();

    useEffect(() => {
        fetchHelpCenterRequest('GET', '/cms-help-center');
    }, []);

    useEffect(() => {
        if (helpCenterResponse && helpCenterResponse.status === "SUCCESS" && helpCenterResponse.data) {
            const helpCenterData = helpCenterResponse.data;
            setHelpCenter(helpCenterData);
            setFormData({
                email: helpCenterData.email || '',
                whatsapp_number: helpCenterData.whatsapp_number || ''
            });
        }
    }, [helpCenterResponse]);

    useEffect(() => {
        if (addOrUpdateHelpCenterResponse && addOrUpdateHelpCenterResponse.status === "SUCCESS") {
            Toast(true, addOrUpdateHelpCenterResponse.message);
        } else if (addOrUpdateHelpCenterResponse) {
            Toast(false, addOrUpdateHelpCenterResponse.message);
        }
    }, [addOrUpdateHelpCenterResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.whatsapp_number) formErrors.whatsapp_number = 'WhatsApp number is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            email: formData.email,
            whatsapp_number: formData.whatsapp_number
        };

        if (helpCenter) {
            await addOrUpdateHelpCenterRequest('PUT', `/cms-help-center/${helpCenter.id}`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } else {
            await addOrUpdateHelpCenterRequest('POST', '/cms-help-center', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        }
    };

    return (
        <div className={`help-center container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li>Help Center</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Email Address</label>
                                    {errors.email && <p className="error">{errors.email}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            placeholder="help@myfinder.ai"
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>WhatsApp Number</label>
                                    {errors.whatsapp_number && <p className="error">{errors.whatsapp_number}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="whatsapp_number"
                                            value={formData.whatsapp_number}
                                            onChange={handleInputChange}
                                            placeholder="+971 55 1234567"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HelpCenter;