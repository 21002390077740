import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';
import { useGetUser } from '../../../hooks/useGetUser';

const Agent = () => {
    const { country, lang, agent_id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const user = useGetUser();

    const [formData, setFormData] = useState({
        category_id: '',
        logo: null,
        name: '',
        description: '',
        mobile: '',
        email: '',
        location: '',
        languages: '',
        meta_title: '',
        meta_description: '',
        meta_tags: ''
    });
    const [errors, setErrors] = useState({
        name: '',
        mobile: '',
        email: '',
        languages: '',
        location: '',
        meta_title: '',
        meta_description: '',
        meta_tags: ''
    });
    const [previewLogo, setPreviewLogo] = useState(null);
    const logoInputRef = useRef(null);
    const [agent, setAgents] = useState({});


    const { request: fetchAgentRequest, response: agentResponse } = useRequest();
    const { request: addAgentRequest, response: agentAddResponse } = useRequest();
    const { request: updateAgentRequest, response: agentUpdateResponse } = useRequest();

    useEffect(() => {
        if (agent_id) {
            fetchAgentRequest('GET', `/agent/${agent_id}`)
        }
    }, [agent_id]);

    useEffect(() => {
        if (user) {
            setFormData((prevData) => ({ ...prevData, category_id: user?.user_profile?.category?.id }));
        }
    }, [user]);

    useEffect(() => {
        if (agentResponse && agentResponse.status === "SUCCESS") {
            setPreviewLogo(cloudFrontUrl + agentResponse?.data?.logo)
            setFormData({
                category_id: agentResponse?.data?.category_id,
                logo: null,
                name: agentResponse?.data?.name,
                description: agentResponse?.data?.description,
                mobile: agentResponse?.data?.mobile,
                email: agentResponse?.data?.email,
                location: agentResponse?.data?.location,
                languages: agentResponse?.data?.languages,
                meta_title: agentResponse?.data?.meta_title,
                meta_description: agentResponse?.data?.meta_description,
                meta_tags: agentResponse?.data?.meta_tags
            })
        }
    }, [agentResponse]);

    useEffect(() => {
        if (agentAddResponse && agentAddResponse.status === "SUCCESS") {
            Toast(true, agentAddResponse.message);
            navigate(`/${country}/${lang}/customer/agents`);
        } else if (agentAddResponse) {
            Toast(false, agentAddResponse.message);
        }
    }, [agentAddResponse]);

    useEffect(() => {
        if (agentUpdateResponse && agentUpdateResponse.status === "SUCCESS") {
            Toast(true, agentUpdateResponse.message);
            navigate(`/${country}/${lang}/customer/agents`);
        } else if (agentUpdateResponse) {
            Toast(false, agentUpdateResponse.message);
        }
    }, [agentUpdateResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'logo') {
            setFormData({ ...formData, logo: files[0] });
            setPreviewLogo(URL.createObjectURL(files[0])); // Set preview of the logo
            logoInputRef.current.value = ''; // Clear file input after setting the logo
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };
    const handleDivClick = () => {
        if (logoInputRef.current) {
            logoInputRef.current.click();
        }
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.mobile) formErrors.mobile = 'Mobile number is required';
        if (!formData.email) formErrors.email = 'Email is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        if (formData.category_id) data.append('category_id', formData.category_id);
        if (formData.logo) data.append('logo', formData.logo);
        if (formData.name) data.append('name', formData.name);
        if (formData.description) data.append('description', formData.description);
        if (formData.mobile) data.append('mobile', formData.mobile);
        if (formData.email) data.append('email', formData.email);
        if (formData.location) data.append('location', formData.location);
        if (formData.languages) data.append('languages', formData.languages);
        if (formData.meta_title) data.append('meta_title', formData.meta_title);
        if (formData.meta_description) data.append('meta_description', formData.meta_description);
        if (formData.meta_tags) data.append('meta_tags', formData.meta_tags);

        if (agent_id) {
            updateAgentRequest('PUT', `/agent/${agent_id}`, data)
        } else {
            data.append('user_id', user?.id);
            addAgentRequest('POST', '/agent', data);
        }
    };

    return (
        <div className={`agent-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="container-user-contact box-dashboard">
                    <form onSubmit={handleSubmit}>
                        <div className="title-db box-dashboard mb-5">
                            <div className="row">
                                <div className="col-sm-2">
                                <div className="field-title-db" onClick={handleDivClick} style={{ cursor: 'pointer', width: '130px', height: '120px' }}>
                                    <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                    <div className="attached-input" style={{ width: "120px", height: "100px" }}>
                                        <input type="file" className="form-control" name="logo" ref={logoInputRef} onChange={handleInputChange} style={{ display: 'none' }} required />
                                        {!previewLogo ? (
                                            <small style={{ fontSize: '16px' }}>Upload Logo or Photo</small>
                                        ) : (
                                            <img src={previewLogo} alt="Logo" className="img-circle" />
                                        )}
                                    </div>
                                </div>
                                </div>

                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            {errors.name && (
                                                <small className="text-danger">{errors.name}</small>
                                            )}
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/tick.svg" alt="tick" />
                                                <button className="edit-text"> <i className="fa-solid fa-pencil"></i></button>
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Add Your Name"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            {errors.mobile && (
                                                <small className="text-danger">{errors.mobile}</small>
                                            )}
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/mobile.svg" alt="mobile" />
                                                <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    placeholder="Add Mobile Number"
                                                    value={formData.mobile}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            {errors.email && (
                                                <small className="text-danger">{errors.email}</small>
                                            )}
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/email.svg" alt="email" />
                                                <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Add Email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/location.svg" alt="location" />
                                                <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                                <input
                                                    type="text"
                                                    name="location"
                                                    placeholder="Add Location"
                                                    value={formData.location}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/language.svg" alt="language" />
                                                <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                                <input
                                                    type="text"
                                                    name="languages"
                                                    placeholder="Add Language"
                                                    value={formData.languages}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-dashboard">
                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                            <div className="heading">
                                <h2>Description</h2>
                            </div>
                            <textarea
                                name="description"
                                placeholder="Add Description"
                                value={formData.description}
                                onChange={handleInputChange}
                                className="form-control"
                            ></textarea>
                        </div>
                        <div class="box-dashboard">
                            {/* <button class="edit-text"><i class="fa-solid fa-pencil"></i></button> */}
                            <div class="meta-dbe">
                                <div class="heading">
                                    <h2>SEO</h2>
                                </div>
                                <form>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Meta Title*</label>
                                            <div class="field-meta-dbe">
                                                <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                                <input type="text" name='meta_title' value={formData.meta_title} onChange={handleInputChange} placeholder="Meta Title" />
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Meta Tags*</label>
                                            <div class="field-meta-dbe">
                                                <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                                <input type="text" name='meta_tags' value={formData.meta_tags} onChange={handleInputChange} placeholder="Meta Keywords" />
                                            </div>
                                        </div>
                                    </div>
                                    <label>Meta Description*</label>
                                    <div class="field-meta-dbe">
                                        <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        <textarea placeholder="Meta Description" name='meta_description' value={formData.meta_description} onChange={handleInputChange}></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="submit-dbe">
                            <button type="submit">{agent_id ? 'Update' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Agent;
