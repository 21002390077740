import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddLandmark = () => {
    const { country, lang, category, landmarkId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        icon: null,
        category_id: ''
    });
    const [errors, setErrors] = useState({});
    const [previewIcon, setPreviewIcon] = useState(null);
    const isEditMode = Boolean(landmarkId);  // Check if in edit mode

    const { request: addLandmarkRequest, response: addLandmarkResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: fetchLandmarkRequest, response: landmarkResponse } = useRequest();
    const { request: updateLandmarkRequest, response: updateLandmarkResponse } = useRequest();

    // Fetch category data
    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (isEditMode) {
            fetchLandmarkRequest('GET', `/landmarks/${landmarkId}`);
        }
    }, [isEditMode, landmarkId]);

    useEffect(() => {
        if (landmarkResponse && landmarkResponse.status === 'SUCCESS' && isEditMode) {
            const { name, category_id, icon } = landmarkResponse.data;
            setFormData({ name, category_id, icon: null }); // Reset icon for editing
            setPreviewIcon(icon);
        }
    }, [landmarkResponse, isEditMode]);

    useEffect(() => {
        const response = isEditMode ? updateLandmarkResponse : addLandmarkResponse;
        if (response && response.status === 'SUCCESS') {
            Toast(true, response.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/landmark`);
        } else if (response) {
            Toast(false, response.message);
        }
    }, [addLandmarkResponse, updateLandmarkResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'icon') {
            setFormData({ ...formData, icon: files[0] });
            setPreviewIcon(URL.createObjectURL(files[0]));
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('name', formData.name);
        if (formData.icon) {
            data.append('icon', formData.icon);
        }
        data.append('category_id', categoryData.id);

        if (isEditMode) {
            await updateLandmarkRequest('PUT', `/landmarks/${landmarkId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } else {
            await addLandmarkRequest('POST', '/landmarks', data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        }
    };

    return (
        <div className={`landmark-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Directory</a></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/landmark`}>Landmark</Link></li>
                        <li>{isEditMode ? 'Edit Landmark' : 'Add Landmark'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Name</label>
                                    {errors.name && <p className="error">{errors.name}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Landmark Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Icon</label>
                                    <div className="attached-input">
                                        <input type="file" name="icon" onChange={handleInputChange} />
                                        <small>Upload Icon <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Preview</label>
                                    <div className="preview-add-form">
                                        {previewIcon ? (
                                            <img src={previewIcon} alt="Icon Preview" />
                                        ) : (
                                            <p>No icon selected</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={isEditMode ? 'Update' : 'Save'} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddLandmark;