import * as React from 'react';
import {
    LineChart,
    lineElementClasses,
    markElementClasses,
} from '@mui/x-charts/LineChart';

const DashedLine = ({ visitorStats }) => {
    const currentWeekNewVisitors = visitorStats?.new_visitors?.current_week;
    const growthRateNewVisitors = visitorStats?.new_visitors?.growth_rate;

    const currentWeekRepeatVisitors = visitorStats?.repeat_visitors?.current_week;
    const growthRateRepeatVisitors = visitorStats?.repeat_visitors?.growth_rate;

    const calculatePreviousWeek = (currentWeek, growthRate) => {
        return Math.round(currentWeek / (1 + growthRate / 100));
    };

    const uData = [currentWeekNewVisitors, currentWeekRepeatVisitors];
    const pData = [
        calculatePreviousWeek(currentWeekNewVisitors, growthRateNewVisitors),
        calculatePreviousWeek(currentWeekRepeatVisitors, growthRateRepeatVisitors),
    ];

    // Use months as x-axis labels
    const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

    return (
        <LineChart
            width={500}
            height={400}
            series={[
                { data: pData, label: 'Last Week', id: 'uvId' }, // Calculated previous week data
                { data: uData, label: 'This Week', id: 'pvId' }, // Current week data
            ]}
            xAxis={[{ scaleType: 'point', data: xLabels }]}
            sx={{
                [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
                    strokeWidth: 1,
                },
                '.MuiLineElement-series-pvId': {
                    strokeDasharray: '5 5',
                },
                '.MuiLineElement-series-uvId': {
                    strokeDasharray: '3 4 5 2',
                },
                [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
                    fill: '#fff',
                },
                [`& .${markElementClasses.highlighted}`]: {
                    stroke: 'none',
                },
            }}
        />
    );
}

export default DashedLine;
