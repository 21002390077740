import "./App.css";
import React from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { getUserLocale } from "./utils/getUserLocale";
import ScrollTop from "./utils/ScrollTop";
import { PrivateRoute } from "./routes/PrivateRoute";
import Authenticated from './utils/Autheticated';
import AdminLayout from './layout/AdminLayout';
import CustomerLayout from './layout/CustomerLayout';
import Customer from './pages/customer/company/Customer';
import Login from './pages/auth/Login';
import AuthLayout from './layout/AuthLayout';
import Directory from './pages/admin/directory/Directory';
import AdminCustomerListing from './pages/admin/directory/company/AdminCustomerListing';
import AdminCustomer from './pages/admin/directory/company/AdminCustomer';
import AdminDashboard from './pages/admin/dashboard/Dashboard';
import UserManagement from './pages/admin/user/UserManagement';
import AddSubAdmin from './pages/admin/user/AddSubAdmin';
import AdminStats from './pages/admin/directory/stats/Stats';
import AdminSubCategories from './pages/admin/directory/subcategory/SubCategories';
import AdminSubCategoryAdd from './pages/admin/directory/subcategory/SubCategoryAdd';
import AdminSubCategoryUpdate from './pages/admin/directory/subcategory/SubCategoryUpdate';
import UpdateSubAdmin from './pages/admin/user/UpdateSubAdmin';
import AdminAmenities from './pages/admin/directory/amenity/Amenities';
import AmenityAdd from './pages/admin/directory/amenity/AmenityAdd';
import AmenityUpdate from './pages/admin/directory/amenity/AmenityUpdate';
import Dashboard from './pages/customer/Dashboard';
import AdminProfile from './pages/admin/profile/AdminProfile';
import Profile from './pages/customer/profile/Profile';
import AdminListing from './pages/admin/directory/listing/Listing';
import AdminAddListing from './pages/admin/directory/listing/AddListing';
import AdminAgents from './pages/admin/directory/agent/Agents';
import AdminAgent from './pages/admin/directory/agent/Agent';
import Listings from './pages/customer/listings/Listings';
import Listing from './pages/customer/listings/Listing';
import Agents from './pages/customer/agents/Agents';
import Agent from './pages/customer/agents/Agent';
import HowItWork from './pages/admin/cms/howItWorks/HowItWork';
import AddCmsVideo from './pages/admin/cms/howItWorks/AddCmsVideo';
import UpdateCmsVideo from './pages/admin/cms/howItWorks/UpdateCmsVideo';
import AddMasterVideo from './pages/admin/cms/howItWorks/AddMasterVideo';
import HelpCenter from './pages/admin/cms/helpCenter/HelpCenter';
import FrequentlyAskedQuestions from './pages/admin/cms/faqs/FAQs';
import AboutUs from './pages/admin/cms/about/AboutUs';
import Policy from './pages/admin/cms/policy/Policy';
import AddFaqS from './pages/admin/cms/faqs/AddFaqs';
import AddAboutUs from './pages/admin/cms/about/AddAboutUs';
import AdminLandmark from './pages/admin/directory/landmark/landmark';
import AddLandmark from './pages/admin/directory/landmark/AddLandmark';
import AdminPopularArea from './pages/admin/directory/popularArea/PopularArea';
import AddPopularArea from './pages/admin/directory/popularArea/AddPopularArea';
import Social from './pages/admin/social/Social';
import AddSocial from './pages/admin/social/AddSocial';
import MyStory from './pages/customer/mystory/mystory';
import MyReel from './pages/customer/myreel/myreel';

import RightSidePanel from './pages/admin/directory/rightSidePanel/RightSidePanel';
import AddVideo from './pages/admin/directory/rightSidePanel/AddVideo';
import Advertisement from './pages/admin/directory/rightSidePanel/Advertisement';
import Registration from './pages/admin/directory/rightSidePanel/Registration';
import Banner from './pages/admin/directory/banner/banner';
import TransferUserProfile from './pages/admin/directory/transferUserProfile/TrasferUserProfile';
import Blogs from './pages/admin/blog/Blogs';
import AddBlogs from './pages/admin/blog/AddBlogs';
import Newsletter from './pages/admin/newsletter/Newsletter';
import CustomerManagement from './pages/admin/customer/CustomerManagement';
import Announcement from './pages/admin/announcement/Announcement';
import AdminControl from './pages/admin/admincontrol/AdminControl';
import MyLive from './pages/mylive/MyLive';
import MyCommunity from './pages/admin/mycommunity/MyCommunity';
import Attraction from './pages/admin/attraction/Attraction';
import Landing from './pages/admin/attraction/Landing';
import AttractionListing from './pages/admin/attraction/AttractionListing';
import AddAttraction from './pages/admin/attraction/AddAttractions';
import Review from './pages/admin/review/review';
import Recommendation from './pages/admin/recommendation/recommendation';
import Blog from './pages/admin/directory/blog/Blog';
import AddBlog from './pages/admin/directory/blog/AddBlog';
import NewsAndUpdate from './pages/admin/directory/newsandupdate/NewsAndUpdate';
import AddNews from './pages/admin/directory/newsandupdate/AddNews';
import Faqs from './pages/admin/directory/faq/Faqs';
import AddFaqs from './pages/admin/directory/faq/AddFaqs';
import Inquiries from './pages/admin/directory/inquiries/Inquiries';
import Story from './pages/admin/directory/story/Story';
import Reels from './pages/admin/directory/reels/Reels';
import AiTools from './pages/admin/directory/aiTools/AiTools';

// Customer Pages
import MyLiveCustomer from './pages/customer/mylive/mylive';
import AddLive from './pages/customer/mylive/addLive';
import MyInqueries from "./pages/customer/my_inquiries/my_inqueries";
import ApplyJob from "./pages/customer/post_job/apply_job";
import MyCv from "./pages/customer/mycv/mycv";
import MyReview from "./pages/customer/myreview/myreview";
import MyRecommendation from "./pages/customer/my_recommendation/my_recommendation";
import CustomerCommunity from "./pages/customer/mycommunity/CustomerMyCommunity";
import CustomerMyLive from './pages/customer/mylive/mylive';

// Guest Pages
import GuestLayout from "./layout/GuestLayout";
import GuestDashbaord from "./pages/guest/guest";
import UploadCv from "./pages/guest/upload-cv/upload-cv";
import GuestProfile from "./pages/guest/profile/profile";
import AppliedJobs from "./pages/guest/applied-jobs/applied-jobs";
import GuestReviews from "./pages/guest/reviews/reviews";
import GuestRecommendation from "./pages/guest/recommendation/recommendation";
import InquiriesGuest from "./pages/guest/inquiries/inquiries";
import GuestAnnouncement from "./pages/guest/announcement/announcement";
import GuestMyCommunity from "./pages/guest/mycommunity/GuestMyCommunity";


function App() {
  const { lang, country } = getUserLocale();

  // Mock function to get user type
  const getUserType = () => {
    // Logic to get user type, e.g., from localStorage, context, or an API call
    return localStorage.getItem("user_type"); // Example usage
  };

  const userType = getUserType();

  const getDashboardPath = () => {
    if (userType === "ADMIN") {
      return `/${country}/${lang}/admin`;
    } else if (userType === "CUSTOMER") {
      return `/${country}/${lang}/customer`;
    }
    return `/${country}/${lang}/auth/login`; // Redirect to login if no valid user type
  };

  return (
    <BrowserRouter forceRefresh={true}>
      <ScrollTop />
      <Routes>
        {/* Fallback route for unmatched paths */}
        <Route
          path="*"
          element={<Navigate to={getDashboardPath()} replace />}
        />

        {/* Redirect from root to country/lang */}
        <Route
          path="/"
          element={<Navigate to={getDashboardPath()} replace />}
        />

        {/* Authentication Routes */}
        <Route path="/:country/:lang/auth" element={<AuthLayout />}>
          <Route
            path="login"
            element={
              <Authenticated>
                <Login />
              </Authenticated>
            }
          />
        </Route>

        {/* Protected Admin Routes */}
        <Route
          path="/:country/:lang/admin/*"
          element={<PrivateRoute requiredType="ADMIN" />}
        >
          <Route path="" element={<AdminLayout />}>
            {/* Dashboard */}
            <Route index path="" element={<AdminDashboard />} />

            {/* Profile */}
            <Route path="profile" element={<AdminProfile />} />

            {/* Directory */}
            <Route path="directory/:category" element={<Directory />} />

            {/* Directory Stats */}
            <Route path="directory/:category/stats" element={<AdminStats />} />

            {/* Directory Company */}
            <Route
              path="directory/:category/customer-listing"
              element={<AdminCustomerListing />}
            />
            <Route
              path="directory/:category/customer"
              element={<AdminCustomer />}
            />
            <Route
              path="directory/:category/customer/:id"
              element={<AdminCustomer />}
            />

            {/* Directory Listing */}
            <Route
              path="directory/:category/listing"
              element={<AdminListing />}
            />
            <Route
              path="directory/:category/listing/add/:customer_id"
              element={<AdminAddListing />}
            />
            <Route
              path="directory/:category/listing/update/:id"
              element={<AdminAddListing />}
            />

            {/* Directory Agent */}
            <Route
              path="directory/:category/agents"
              element={<AdminAgents />}
            />
            <Route
              path="directory/:category/agents/:customer_id/add"
              element={<AdminAgent />}
            />
            <Route
              path="directory/:category/agents/update/:agent_id"
              element={<AdminAgent />}
            />

            {/* Directory Sub Category */}
            <Route
              path="directory/:category/sub-category"
              element={<AdminSubCategories />}
            />
            <Route
              path="directory/:category/sub-category/add"
              element={<AdminSubCategoryAdd />}
            />
            <Route
              path="directory/:category/sub-category/update/:sub_category_id"
              element={<AdminSubCategoryUpdate />}
            />

            {/* Directory Amenity */}
            <Route
              path="directory/:category/amenities"
              element={<AdminAmenities />}
            />
            <Route
              path="directory/:category/amenities/add"
              element={<AmenityAdd />}
            />
            <Route
              path="directory/:category/amenities/update/:amenity_id"
              element={<AmenityUpdate />}
            />

            {/* Directory Landmark */}
            <Route
              path="directory/:category/landmark"
              element={<AdminLandmark />}
            />
            <Route
              path="directory/:category/landmark/add"
              element={<AddLandmark />}
            />
            <Route
              path="directory/:category/landmark/edit/:landmarkId"
              element={<AddLandmark />}
            />

            {/* Directory Popular Area */}
            <Route path="directory/:category/popular-area" element={<AdminPopularArea />} />
            <Route path="directory/:category/popular-area/add" element={<AddPopularArea />} />
            <Route path="directory/:category/popular-area/edit/:popularAreaId" element={<AddPopularArea />} />

            {/* Directory Faqs */}
            <Route path="directory/:category/faqs" element={<Faqs />} />
            <Route path="directory/:category/faqs/add" element={<AddFaqs />} />
            <Route path="directory/:category/faqs/edit/:faqId" element={<AddFaqs />} />

            {/* Directory Inquiries */}
            <Route path="directory/:category/inquiries" element={<Inquiries />} />

            {/* Directory Story */}
            <Route path="directory/:category/my-story" element={<Story />} />

            {/* Directory Reels */}
            <Route path="directory/:category/my-reel" element={<Reels />} />

            {/* Directory Ai Tools */}
            <Route path="directory/:category/ai-tools" element={<AiTools />} />

            {/* Directory Right Side Panel */}
            <Route path="directory/:category/right-side-panel" element={<RightSidePanel />} />
            <Route path="directory/:category/right-side-panel/add" element={<AddVideo />} />
            <Route path="directory/:category/right-side-panel/edit/:videoId" element={<AddVideo />} />
            <Route path="directory/:category/right-side-panel/advertisement" element={<Advertisement />} />
            <Route path="directory/:category/right-side-panel/registration" element={<Registration />} />

            {/* Directory Banners */}
            <Route path="directory/:category/banners" element={<Banner />} />

            {/* Directory Transfer User Profile */}
            <Route path="directory/:category/transfer-user-profile" element={<TransferUserProfile />} />

            {/* Directory Blog */}
            <Route path="directory/:category/blog" element={<Blog />} />
            <Route path="directory/:category/blog/add" element={<AddBlog />} />
            <Route path="directory/:category/blog/edit/:blogId" element={<AddBlog />} />

            {/* Directory News & Update */}
            <Route path="directory/:category/news" element={<NewsAndUpdate />} />
            <Route path="directory/:category/news/add" element={<AddNews />} />
            <Route path="directory/:category/news/edit/:newsId" element={<AddNews />} />

            {/* Attraction */}
            <Route path="attraction" element={<Attraction />} />
            <Route path="attraction/landmark" element={<Landing />} />
            <Route path="attraction/listing" element={<AttractionListing />} />
            <Route path="attraction/add" element={<AddAttraction />} />
            <Route path="attraction/edit/:id" element={<AddAttraction />} />

            {/* MyCommunity */}
            <Route path="community" element={<MyCommunity />} />

            {/* User Management */}
            <Route path="sub-admin" element={<UserManagement />} />
            <Route path="sub-admin/add" element={<AddSubAdmin />} />
            <Route path="sub-admin/update/:sub_admin_id" element={<UpdateSubAdmin />} />

            {/* Customer Management */}
            <Route path="customer" element={<CustomerManagement />} />

            {/* CMS Pages */}
            <Route path="cms/how-it-work" element={<HowItWork />} />
            <Route path="cms/how-it-work/add-video" element={<AddCmsVideo />} />
            <Route path="cms/how-it-work/master-video" element={<AddMasterVideo />} />
            <Route path="cms/how-it-work/update/:video_id" element={<UpdateCmsVideo />} />
            <Route path="cms/help-center" element={<HelpCenter />} />
            <Route path="cms/faqs" element={<FrequentlyAskedQuestions />} />
            <Route path="cms/faqs/add" element={<AddFaqS />} />
            <Route path="cms/faqs/edit/:faqId" element={<AddFaqS />} />
            <Route path="cms/about-us" element={<AboutUs />} />
            <Route path="cms/about-us/add" element={<AddAboutUs />} />
            <Route path="cms/about-us/edit/:aboutUsId" element={<AddAboutUs />} />
            <Route path="cms/policy" element={<Policy />} />

            {/* Blog */}
            <Route path="blog" element={<Blogs />} />
            <Route path="blog/add" element={<AddBlogs />} />
            <Route path="blog/edit/:blogId" element={<AddBlogs />} />

            {/* MyLive */}
            <Route path="live" element={<MyLive />} />

            {/* Social Media */}
            <Route path="social" element={<Social />} />
            <Route path="social/add" element={<AddSocial />} />
            <Route path="social/edit/:socialId" element={<AddSocial />} />

            {/* Newsletter */}
            <Route path="newsletter" element={<Newsletter />} />

            {/* Review */}
            <Route path="review" element={<Review />} />

            {/* Recommendation */}
            <Route path="recommendation" element={<Recommendation />} />

            {/* Announcement */}
            <Route path="announcement" element={<Announcement />} />

            {/* Admin Control */}
            <Route path='admin-control' element={<AdminControl />} />

          </Route>
        </Route>

        {/* Protected Customer Routes */}
        <Route
          path="/:country/:lang/customer/*"
          element={<PrivateRoute requiredType="CUSTOMER" />}
        >
          <Route path="" element={<CustomerLayout />}>
            {/* Dashboard */}
            <Route index path="" element={<Dashboard />} />

            {/* Profile */}
            <Route path="profile" element={<Profile />} />

            {/* Customer/Company */}
            <Route path="company-profile" element={<Customer />} />

            {/* Listings/Stores */}
            <Route path="listings" element={<Listings />} />
            <Route path="listings/add" element={<Listing />} />
            <Route path="listings/update/:id" element={<Listing />} />

            {/* My Agents */}
            <Route path="agents" element={<Agents />} />
            <Route path="agents/add" element={<Agent />} />
            <Route path="agents/update/:agent_id" element={<Agent />} />

            {/* My Story  */}
            <Route path="mystory" element={<MyStory />} />
            {/* My Reel  */}
            <Route path="myreel" element={<MyReel />} />
            {/* My Live  */}
            <Route path="mylive" element={<MyLiveCustomer />} />
            <Route path="mylive/add" element={<AddLive />} />
            <Route path="myinqueries" element={<MyInqueries />} />
            <Route path="post_job" element={<ApplyJob />} />
            <Route path="post_job/add" element={<ApplyJob />} />
            <Route path="mycv" element={<MyCv />} />
            <Route path="myreview" element={<MyReview />} />
            <Route path="my_recommendation" element={<MyRecommendation />} />
            <Route path="community" element={<CustomerCommunity />} />
            <Route index path="announcement" element={<GuestAnnouncement />} />
          </Route>
        </Route>

        {/* Protected Guest Routes */}
        <Route
          path="/:country/:lang/guest/*"
          element={<PrivateRoute requiredType="GUEST" />}
        >
          <Route path="" element={<GuestLayout />}>
            {/*Guest Dashboard */}
            <Route index path="" element={<GuestDashbaord />} />
            <Route index path="upload_cv" element={<UploadCv />} />
            <Route index path="profile" element={<GuestProfile />} />
            <Route index path="applied_jobs" element={<AppliedJobs />} />
            <Route index path="reviews" element={<GuestReviews />} />
            <Route index path="recommendation" element={<GuestRecommendation />} />
            <Route index path="inquiries" element={<InquiriesGuest />} />
            <Route index path="mycommunity" element={<GuestMyCommunity />} />
            <Route index path="announcement" element={<GuestAnnouncement />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
