import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const MyLive = () => {
    const { isSidebarHidden } = useOutletContext();

    return (
        <div className={`mylive-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Dashboard</a></li>
                        <li>MyLive</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input type="text" placeholder="Search" />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <div className="select-btn-filter">
                                <select defaultValue="Category">
                                    <option value="Property">Property</option>
                                    <option value="Motors">Motors</option>
                                    <option value="Jobs">Jobs</option>
                                </select>
                            </div>
                            <div className="select-btn-filter">
                                <select defaultValue="Subcategory">
                                    <option value="Entertainment">Entertainment</option>
                                    <option value="Restaurants">Restaurants</option>
                                    <option value="Rooftops">Rooftops</option>
                                </select>
                            </div>
                            <a href="#">
                                <img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30
                            </a>
                            <a href="#">
                                <img src="/assets/images/icons/download.svg" alt="Download" /> Download
                            </a>
                        </div>
                    </div>

                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Total <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Publish Date <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Status <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Lorem ipsum dolor sit amet</td>
                                    <td>03</td>
                                    <td>Mar 5, 2024, 12:35 PM</td>
                                    <td><button className="published-status">Published</button></td>
                                    <td>
                                        <div className="btn-toggle">
                                            <input type="checkbox" className="checkbox-toggle" />
                                            <div className="txt-toggle"><span>YES</span></div>
                                            <div className="layer-toggle"></div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="action-table">
                                            <a href="#">Edit <i className="fa-solid fa-pencil"></i></a>
                                            <a href="#">Delete <i className="fa-solid fa-trash"></i></a>
                                        </div>
                                    </td>
                                </tr>
                                {/* Repeat other rows similarly */}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination-container">
                        <div className="show-per-page">
                            <label>Show per page:</label>
                            <select>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <div className="info-pagination">
                            <span>1 - 10 of 11 items</span>
                            <div className="control-pagination">
                                <button className="active">1</button>
                                <button>2</button>
                                <button>3</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyLive;