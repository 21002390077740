import React, { useState, useEffect } from 'react';
import { useParams, useOutletContext, Link } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';

const Landing = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();

    // State and handlers for Master Banner
    const [masterFormData, setMasterFormData] = useState({ headline: '', subHeadline: '', description: '', image: null });
    const [masterBanners, setMasterBanners] = useState([]);
    const { request: fetchMasterBanners, response: masterBannersResponse } = useRequest();
    const { request: postMasterBanner, response: postMasterBannerResponse } = useRequest();
    const { request: deleteMasterBanner, response: deleteMasterBannerResponse } = useRequest();

    // State and handlers for Attraction Categories
    const [attractionFormData, setAttractionFormData] = useState({ headline: '', description: '', image: null });
    const [attractionCategories, setAttractionCategories] = useState([]);
    const { request: fetchAttractionCategories, response: attractionCategoriesResponse } = useRequest();
    const { request: postAttractionCategory, response: postAttractionCategoryResponse } = useRequest();
    const { request: deleteAttractionCategory, response: deleteAttractionCategoryResponse } = useRequest();

    // State and handlers for Covered Attractions
    const [coveredFormData, setCoveredFormData] = useState({ headline: '', description: '', image: null });
    const [coveredAttractions, setCoveredAttractions] = useState([]);
    const { request: fetchCoveredAttractions, response: coveredAttractionsResponse } = useRequest();
    const { request: postCoveredAttraction, response: postCoveredAttractionResponse } = useRequest();
    const { request: deleteCoveredAttraction, response: deleteCoveredAttractionResponse } = useRequest();

    // State and handlers for Top Attractions
    const [topFormData, setTopFormData] = useState({ headline: '', description: '', image: null });
    const [topAttractions, setTopAttractions] = useState([]);
    const { request: fetchTopAttractions, response: topAttractionsResponse } = useRequest();
    const { request: postTopAttraction, response: postTopAttractionResponse } = useRequest();
    const { request: deleteTopAttraction, response: deleteTopAttractionResponse } = useRequest();

    // State and handlers for Explore Destinations
    const [exploreFormData, setExploreFormData] = useState({ headline: '', description: '', image: null });
    const [exploreDestinations, setExploreDestinations] = useState([]);
    const { request: fetchExploreDestinations, response: exploreDestinationsResponse } = useRequest();
    const { request: postExploreDestination, response: postExploreDestinationResponse } = useRequest();
    const { request: deleteExploreDestination, response: deleteExploreDestinationResponse } = useRequest();

    // State and handlers for Banner
    const [bannerFormData, setBannerFormData] = useState({ image: null, headline: '', description: '', button: '' });
    const [banners, setBanners] = useState([]);
    const { request: fetchBanners, response: bannersResponse } = useRequest();
    const { request: postBanner, response: postBannerResponse } = useRequest();
    const { request: deleteBanner, response: deleteBannerResponse } = useRequest();

    // Fetch data for each section on mount
    useEffect(() => {
        fetchMasterBanners('GET', '/attraction-master-banner');
        fetchAttractionCategories('GET', '/attraction-category');
        fetchCoveredAttractions('GET', '/attraction-covered');
        fetchTopAttractions('GET', '/attraction-top');
        fetchExploreDestinations('GET', '/attraction-destination');
        fetchBanners('GET', '/attraction-banner');
    }, []);

    // Handle responses for each section
    useEffect(() => {
        if (masterBannersResponse?.status === 'SUCCESS') {
            setMasterBanners(masterBannersResponse.data);
        }
    }, [masterBannersResponse]);

    useEffect(() => {
        if (attractionCategoriesResponse?.status === 'SUCCESS') {
            setAttractionCategories(attractionCategoriesResponse.data);
        }
    }, [attractionCategoriesResponse]);

    useEffect(() => {
        if (coveredAttractionsResponse?.status === 'SUCCESS') {
            setCoveredAttractions(coveredAttractionsResponse.data);
        }
    }, [coveredAttractionsResponse]);

    useEffect(() => {
        if (topAttractionsResponse?.status === 'SUCCESS') {
            setTopAttractions(topAttractionsResponse.data);
        }
    }, [topAttractionsResponse]);

    useEffect(() => {
        if (exploreDestinationsResponse?.status === 'SUCCESS') {
            setExploreDestinations(exploreDestinationsResponse.data);
        }
    }, [exploreDestinationsResponse]);

    useEffect(() => {
        if (bannersResponse?.status === 'SUCCESS') {
            setBanners(bannersResponse.data);
        }
    }, [bannersResponse]);

    // Handle form input changes
    const handleInputChange = (e, setFormData) => {
        const { name, value, files } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: name === 'image' ? files[0] : value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e, endpoint, formData, postRequest) => {
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key]) data.append(key, formData[key]);
        });

        try {
            await postRequest('POST', endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } });
            Toast(true, 'Item created successfully');
            // Fetch updated data
            switch (endpoint) {
                case '/attraction-master-banner':
                    fetchMasterBanners('GET', endpoint);
                    break;
                case '/attraction-category':
                    fetchAttractionCategories('GET', endpoint);
                    break;
                case '/attraction-covered':
                    fetchCoveredAttractions('GET', endpoint);
                    break;
                case '/attraction-top':
                    fetchTopAttractions('GET', endpoint);
                    break;
                case '/attraction-destination':
                    fetchExploreDestinations('GET', endpoint);
                    break;
                case '/attraction-banner':
                    fetchBanners('GET', endpoint);
                    break;
                default:
                    break;
            }
        } catch (error) {
            Toast(false, 'Failed to create item. Please try again.');
        }
    };

    // Handle item deletion
    const handleDelete = async (id, endpoint, deleteRequest) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                await deleteRequest('DELETE', `${endpoint}/${id}`);
                Toast(true, 'Item deleted successfully');
                // Fetch updated data
                switch (endpoint) {
                    case '/attraction-master-banner':
                        fetchMasterBanners('GET', endpoint);
                        break;
                    case '/attraction-category':
                        fetchAttractionCategories('GET', endpoint);
                        break;
                    case '/attraction-covered':
                        fetchCoveredAttractions('GET', endpoint);
                        break;
                    case '/attraction-top':
                        fetchTopAttractions('GET', endpoint);
                        break;
                    case '/attraction-destination':
                        fetchExploreDestinations('GET', endpoint);
                        break;
                    case '/attraction-banner':
                        fetchBanners('GET', endpoint);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                Toast(false, 'Failed to delete item. Please try again.');
            }
        }
    };

    return (
        <div className={`add-banner container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Dashboard</a></li>
                        <li><Link to={`/${country}/${lang}/admin/attraction`}>Attraction</Link></li>
                        <li>Add Landing Page</li>
                    </ul>
                </div>

                {/* Master Banner Section */}
                <h3>Master Banner</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-master-banner', masterFormData, postMasterBanner)}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setMasterFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" placeholder="Type Headline" value={masterFormData.headline} onChange={(e) => handleInputChange(e, setMasterFormData)} />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Subheadline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="subHeadline" placeholder="Type Subheadline" value={masterFormData.subHeadline} onChange={(e) => handleInputChange(e, setMasterFormData)} />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {masterBanners.map(banner => (
                            <li key={banner.id}>
                                <img src={cloudFrontUrl + banner.image} alt="Preview" />
                                <button className="delete-text" onClick={() => handleDelete(banner.id, '/attraction-master-banner', deleteMasterBanner)}>
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Attraction Categories Section */}
                <h3>Top Things to Do by Category</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-category', attractionFormData, postAttractionCategory)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setAttractionFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" placeholder="Type Headline" value={attractionFormData.headline} onChange={(e) => handleInputChange(e, setAttractionFormData)} />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea name="description" placeholder="Type Description" value={attractionFormData.description} onChange={(e) => handleInputChange(e, setAttractionFormData)} />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {attractionCategories.map(category => (
                            <li key={category.id}>
                                <img src={cloudFrontUrl + category.image} alt="Preview" />
                                <button className="delete-text" onClick={() => handleDelete(category.id, '/attraction-category', deleteAttractionCategory)}>
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Covered Attractions Section */}
                <h3>We’ve Got You Covered</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-covered', coveredFormData, postCoveredAttraction)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setCoveredFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" placeholder="Type Headline" value={coveredFormData.headline} onChange={(e) => handleInputChange(e, setCoveredFormData)} />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea name="description" placeholder="Type Description" value={coveredFormData.description} onChange={(e) => handleInputChange(e, setCoveredFormData)} />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {coveredAttractions.map(attraction => (
                            <li key={attraction.id}>
                                <img src={cloudFrontUrl + attraction.image} alt="Preview" />
                                <button className="delete-text" onClick={() => handleDelete(attraction.id, '/attraction-covered', deleteCoveredAttraction)}>
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Top Attractions Section */}
                <h3>Top Attractions</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-top', topFormData, postTopAttraction)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setTopFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" placeholder="Type Headline" value={topFormData.headline} onChange={(e) => handleInputChange(e, setTopFormData)} />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea name="description" placeholder="Type Description" value={topFormData.description} onChange={(e) => handleInputChange(e, setTopFormData)} />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {topAttractions.map(attraction => (
                            <li key={attraction.id}>
                                <img src={cloudFrontUrl + attraction.image} alt="Preview" />
                                <button className="delete-text" onClick={() => handleDelete(attraction.id, '/attraction-top', deleteTopAttraction)}>
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Banner Section */}
                <h3>Banner</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-banner', bannerFormData, postBanner)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Icon / Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setBannerFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" placeholder="Type Headline" value={bannerFormData.headline} onChange={(e) => handleInputChange(e, setBannerFormData)} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Description</label>
                                    <div className="field-add-form">
                                        <textarea name="description" placeholder="Type Description" value={bannerFormData.description} onChange={(e) => handleInputChange(e, setBannerFormData)} />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Button</label>
                                    <div className="field-add-form">
                                        <textarea name="button" placeholder="URL" value={bannerFormData.button} onChange={(e) => handleInputChange(e, setBannerFormData)} />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {banners.map(banner => (
                            <li key={banner.id}>
                                <img src={cloudFrontUrl + banner.image} alt="Preview" />
                                <button className="delete-text" onClick={() => handleDelete(banner.id, '/attraction-banner', deleteBanner)}>
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Explore Destinations Section */}
                <h3>Explore More Destination</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-destination', exploreFormData, postExploreDestination)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setExploreFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" placeholder="Type Headline" value={exploreFormData.headline} onChange={(e) => handleInputChange(e, setExploreFormData)} />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea name="description" placeholder="Type Description" value={exploreFormData.description} onChange={(e) => handleInputChange(e, setExploreFormData)} />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {exploreDestinations.map(destination => (
                            <li key={destination.id}>
                                <img src={cloudFrontUrl + destination.image} alt="Preview" />
                                <button className="delete-text" onClick={() => handleDelete(destination.id, '/attraction-destination', deleteExploreDestination)}>
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Landing;