import React, { useEffect, useState, useRef } from 'react';
import { useParams, useOutletContext, Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';
import MyStoryTab from './tabs/MyStoryTab';
import MyReelTab from './tabs/MyReelTab';
import Htabs from './htabs/Htabs';
import { cloudFrontUrl } from '../../../../config';

const AdminCustomer = () => {
    const { country, lang, category, id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isSidebarHidden } = useOutletContext();
    const [activeHorizontalTab, setActiveHorizontalTab] = useState('about');
    const [activeVerticalTab, setActiveVerticalTab] = useState('story');

    const [inputStates, setInputStates] = useState({
        companyName: true,
        status: true,
        username: true,
        password: true,
        email: true,
    });

    const [metaInputStates, setMetaInputStates] = useState(true);

    const [customerId, setCustomerId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryId, setCategoryId] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [subCategory, setSubCategory] = useState('');

    const [logoPreview, setLogoPreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const logoRef = useRef();

    const [company, setCompany] = useState({
        category_id: null,
        sub_category_id: null,
        name: null,
        username: null,
        email: null,
        password: null,
        description: null,
        meta_title: null,
        meta_description: null,
        meta_tags: null
    });

    const [errors, setErrors] = useState({
        name: '',
        logo: '',
        category_id: '',
        sub_category_id: '',
        username: '',
        email: '',
        password: '',
        status: '',
    });

    const { request: categroyRequest, response: categoryResponse } = useRequest()
    const { request: subCategroyRequest, response: subCategoryResponse } = useRequest()
    const { request: customerRequest, response: customerResponse } = useRequest()
    const { request: getCustomerRequest, response: getCustomerResponse } = useRequest()

    const handleInputEnable = (fieldName) => {
        setInputStates((prevState) => ({
            ...prevState,
            [fieldName]: false, // Enable the input field by setting its state to false
        }));
    };

    const handleMetaInputEnable = () => {
        if (!metaInputStates) {
            let formData = new FormData()
            formData.append('meta_name', company.meta_title)
            formData.append('meta_description', company.meta_description)
            formData.append('meta_tags', company.meta_tags)
            if (customerId) {
                customerRequest('PUT', `/customers/${customerId}`, formData)
            } else {
                Toast(false, 'Create a company first')
            }
        }
        setMetaInputStates(metaInputStates ? false : true);
    };

    const handleCompanyChange = (e) => {
        const { name, value } = e.target;
        setCompany((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({
            ...prev,
            [name]: value ? '' : `The ${name} field is required.`,
        }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setLogo(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setErrors((prev) => ({ ...prev, logo: '' }));
        } else {
            setLogo(null)
            logoRef.current.value = '';
            setLogoPreview(null);
            setErrors((prev) => ({ ...prev, logo: 'Logo is required.' }));
        }
    };

    const handleDivClick = () => {
        if (logoRef.current) {
            logoRef.current.click();
        }
    };

    const handleCategoryChange = (e) => {
        const selectedCategory = categories.find(data => data.slug === e.target.value);
        setCategoryId(selectedCategory?.id || null);
        setCompany((prev) => ({
            ...prev,
            category_id: selectedCategory?.id,
        }));

        setErrors((prev) => ({
            ...prev,
            category_id: selectedCategory?.id ? '' : 'The category field is required.',
        }));
    };

    const handleSubCategoryChange = (e) => {
        const selectedSubCategory = subCategories.find(data => data.slug === e.target.value);
        setCompany((prev) => ({
            ...prev,
            sub_category_id: selectedSubCategory?.id,
        }));

        setErrors((prev) => ({
            ...prev,
            sub_category_id: selectedSubCategory?.id ? '' : 'The sub-category field is required.',
        }));
    };

    const handleSaveCompany = () => {
        if (!id) {
            // Check for required fields
            let newErrors = {};

            if (!company.name) newErrors.name = 'Company name is required.';
            if (!company.username) newErrors.username = 'Username is required.';
            if (!company.email) newErrors.email = 'Email is required.';
            if (!company.password) newErrors.password = 'Password is required.';
            if (!company.status) newErrors.status = 'Status is required.';
            if (!company.category_id) newErrors.category_id = 'Category is required.';
            if (!company.sub_category_id) newErrors.sub_category_id = 'Sub-category is required.';

            const logoInput = document.querySelector('input[name="logo"]');
            if (!logoInput.files.length) newErrors.logo = 'Logo is required.';

            if (Object.keys(newErrors).length) {
                setErrors(newErrors);
                return;
            }
        }

        let formData = new FormData()
        if (company.name) formData.append('name', company.name);
        if (company.username) formData.append('username', company.username);
        if (company.email) formData.append('email', company.email);
        if (company.status) formData.append('status', company.status);
        if (company.password) formData.append('password', company.password);
        if (company.category_id) formData.append('category_id', company.category_id);
        if (company.sub_category_id) formData.append('sub_category_id', company.sub_category_id);
        if (logo) formData.append('logo', logo); // Append the logo if it's provided


        if (id) {
            customerRequest('PUT', `/customers/${id}`, formData)
        } else {
            customerRequest('POST', '/customers', formData)
        }
    }

    useEffect(() => {
        if (customerResponse && customerResponse.status === "SUCCESS") {
            setCustomerId(customerResponse.data.id)
            Toast(true, customerResponse?.message)
            setLogo(null)
            logoRef.current.value = '';
            navigate(`/${country}/${lang}/admin/directory/${category}/customer-listing`);
        } else {
            Toast(false, customerResponse?.message)
        }
    }, [customerResponse]);

    useEffect(() => {
        categroyRequest('GET', '/category')
        if (id) {
            getCustomerRequest('GET', `/customers/${id}`)
        }
    }, []);

    useEffect(() => {
        if (getCustomerResponse && getCustomerResponse.status === "SUCCESS") {
            setCustomerId(getCustomerResponse.data.id)
            setLogoPreview(cloudFrontUrl + getCustomerResponse.data?.user_profile?.logo);
            setSubCategory(getCustomerResponse?.data?.user_profile?.sub_category?.slug)
            setCompany({
                category_id: getCustomerResponse?.data?.user_profile?.category?.id || null,
                sub_category_id: getCustomerResponse?.data?.user_profile?.sub_category?.id || null,
                name: getCustomerResponse?.data?.name || null,
                username: getCustomerResponse?.data?.username || null,
                email: getCustomerResponse?.data?.email || null,
                password: '', // Typically, password will not be included in the API response for security reasons
                description: getCustomerResponse?.data?.user_profile?.description || null,
                meta_title: getCustomerResponse?.data?.user_profile?.meta_name || null,
                meta_description: getCustomerResponse?.data?.user_profile?.meta_description || null,
                meta_tags: getCustomerResponse?.data?.user_profile?.meta_tags || null,
            });
        }
    }, [getCustomerResponse]);

    useEffect(() => {
        if (categories && categoryId === null) {
            const selectedCategory = categories.find(d => d.slug === category);
            setCategoryId(selectedCategory?.id || null);
            setCompany((prev) => ({
                ...prev,
                category_id: selectedCategory?.id,
            }));
        }
        if (categoryId) {
            subCategroyRequest('GET', `/sub-categories/${categoryId}`);
        }
    }, [categoryId, categories]);

    useEffect(() => {
        if (categoryResponse && categoryResponse?.status === "SUCCESS") {
            setCategories(categoryResponse?.data)
        }
        if (subCategoryResponse && subCategoryResponse?.status === "SUCCESS") {
            setSubCategories(subCategoryResponse?.data)
        }
    }, [categoryResponse, subCategoryResponse]);



    return (
        <>
            <div className={`profile-detail-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Directory</a></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}/customer-listing`}>Company Listing</Link></li>
                            <li>Company Profile Page</li>
                        </ul>
                        <div className="btn-bc">
                            {
                                id ?
                                    <Link to={`/${country}/${lang}/admin/directory/${category}/listing/add/${id}`} className="btn btn-primary">Add MyStore/Listing <i className="fa-solid fa-plus"></i></Link>
                                    : null
                            }

                            {
                                id ?
                                    <Link to={`/${country}/${lang}/admin/directory/${category}/agents/${id}/add`} className="btn btn-secondary">Add Agent <i className="fa-solid fa-plus"></i></Link>
                                    : null
                            }

                            <a onClick={handleSaveCompany} className="btn btn-info">{id ? 'Update Company' : 'Add Company'} {id ? <i className="fa-solid fa-pencil"></i> : <i className="fa-solid fa-plus"></i>} </a>
                        </div>
                    </div>
                    <div className="title-db">
                        <div className="row">
                            <div className="col-sm-2">
                                {errors.logo && <div className="error">{errors.logo}</div>}
                                <div className="field-title-db" onClick={handleDivClick} style={{ cursor: 'pointer', width: '130px', height: '120px' }}>
                                    <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                    <div className="attached-input" style={{ width: "120px", height: "100px" }}>
                                        <input type="file" className="form-control" name="logo" ref={logoRef} onChange={handleLogoUpload} style={{ display: 'none' }} required />
                                        {!logoPreview ? (
                                            <small style={{ fontSize: '16px' }}>Upload Logo or Photo</small>
                                        ) : (
                                            <img src={logoPreview} alt="Logo" className="img-circle" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <div className="row">
                                    <div className="col-sm-6">
                                        {errors.category_id && <div className="error">{errors.category_id}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select name='category_id' onChange={handleCategoryChange} disabled>
                                                <option value="">Select category</option>
                                                {categories && categories.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.slug}
                                                        selected={data?.slug === category ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.sub_category_id && <div className="error">{errors.sub_category_id}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select name='sub_category_id' onChange={handleSubCategoryChange}>
                                                <option value="">Select sub category</option>
                                                {subCategories && subCategories.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.slug}
                                                        selected={data?.slug === subCategory ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {errors.name && <div className="error">{errors.name}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                            <input type="text" name="name" value={company.name} onChange={handleCompanyChange} placeholder="Type Your Company Name / Name" disabled={inputStates.companyName && id} required />
                                            {
                                                id &&
                                                <button className="edit-text" onClick={() => handleInputEnable('companyName')}><i className="fa-solid fa-pencil"></i></button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                        <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                            <input type="text" name="status" value={company.status} onChange={handleCompanyChange} placeholder="Status" disabled={inputStates.status && id} required />
                                            {
                                                id &&
                                                <button className="edit-text" onClick={() => handleInputEnable('status')}><i className="fa-solid fa-pencil"></i></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        {errors.username && <div className="error">{errors.username}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                            <input type="text" name="username" value={company.username} onChange={handleCompanyChange} placeholder="Username" disabled={inputStates.username && id} required />
                                            {
                                                id &&
                                                <button className="edit-text" onClick={() => handleInputEnable('username')}><i className="fa-solid fa-pencil"></i></button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        {errors.email && <div className="error">{errors.email}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                            <input type="email" name="email" value={company.email} onChange={handleCompanyChange} placeholder="Email" disabled={inputStates.email && id} required />
                                            {
                                                id &&
                                                <button className="edit-text" onClick={() => handleInputEnable('email')}><i className="fa-solid fa-pencil"></i></button>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        {errors.password && <div className="error">{errors.password}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                            <input type="password" name="password" value={company.password} onChange={handleCompanyChange} placeholder="Password" disabled={inputStates.password && id} required />
                                            {
                                                id &&
                                                <button className="edit-text" onClick={() => handleInputEnable('password')}><i className="fa-solid fa-pencil"></i></button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <Htabs activeHorizontalTab={activeHorizontalTab} setActiveHorizontalTab={setActiveHorizontalTab} customerId={customerId} company={company} handleCompanyChange={handleCompanyChange} />
                    </div>
                    <div className="box-dashboard">
                        <div className="social-activity">
                            <div className="vtab">
                                <ul className="vtab-menu">
                                    <li data-tab="mystories-vtab" className={activeVerticalTab === 'story' ? 'active' : ''} onClick={() => setActiveVerticalTab('story')}><img src="/assets/images/icons/story.svg" alt="story" /> MyStories</li>
                                    <li data-tab="myreels-vtab" className={activeVerticalTab === 'reel' ? 'active' : ''} onClick={() => setActiveVerticalTab('reel')}><img src="/assets/images/icons/reels.svg" alt="reels" /> MyReels</li>
                                </ul>
                                <div className="wraper-vtab">
                                    <div id="mystories-vtab" className={`vtab-content ${activeVerticalTab === 'story' ? 'active' : ''}`}>
                                        <MyStoryTab isVTavShow={activeVerticalTab === 'story' ? true : false} />
                                    </div>
                                    <div id="myreels-vtab" className={`vtab-content ${activeVerticalTab === 'reel' ? 'active' : ''}`}>
                                        <MyReelTab isVTavShow={activeVerticalTab === 'reel' ? true : false} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        {
                            id &&
                            <button className="edit-text" onClick={handleMetaInputEnable}>{metaInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        }
                        <div className="meta-dbe">
                            <div className="heading">
                                <h2>SEO</h2>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Meta Title*</label>
                                        <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_title' value={company.meta_title} onChange={handleCompanyChange} placeholder="Meta Title" disabled={metaInputStates && id} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Meta Tags*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_tags' value={company.meta_tags} onChange={handleCompanyChange} placeholder="Meta Keywords" disabled={metaInputStates && id} />
                                        </div>
                                    </div>
                                </div>
                                <label>Meta Description*</label>
                                <div className="field-meta-dbe">
                                    <textarea placeholder="Meta Description" name='meta_description' value={company.meta_description} onChange={handleCompanyChange} disabled={metaInputStates && id}></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminCustomer;
