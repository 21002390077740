import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddVideo = (props) => {
    const { country, lang, category, videoId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        video_url: '',

    });

    const [categoryId, setCategoryId] = useState(null);

    const { request: fetchVideoRequest, response: videoResponse } = useRequest();
    const { request: addOrUpdateVideoRequest, response: videoAddOrUpdateResponse } = useRequest();
    const { request: categoryRequest, response: categoryResponse } = useRequest();

    useEffect(() => {
        if (category) {
            categoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            console.log('Category Response:', categoryResponse);
            setCategoryId(categoryResponse?.data?.id);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (videoId) {
            fetchVideoRequest('GET', `/right_side_video/${videoId}`);
        }
    }, [videoId]);

    useEffect(() => {
        if (videoResponse && videoResponse.status === "SUCCESS") {
            const { video_url } = videoResponse.data;
            setFormData({
                video_url,
            });
        }
    }, [videoResponse]);

    useEffect(() => {
        if (videoAddOrUpdateResponse && videoAddOrUpdateResponse.status === "SUCCESS") {
            Toast(true, videoAddOrUpdateResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/right-side-panel`);
        } else if (videoAddOrUpdateResponse) {
            Toast(false, videoAddOrUpdateResponse.message);
        }
    }, [videoAddOrUpdateResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.video_url) formErrors.video_url = 'Video URL is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            return;
        }

        if (!categoryId) {
            Toast(false, 'Category ID is missing.');
            return;
        }

        const data = new URLSearchParams();
        data.append('video_url', formData.video_url);
        data.append('category_id', categoryId);

        console.log('Data being sent:', data.toString());

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        if (videoId) {
            addOrUpdateVideoRequest('PUT', `/right_side_video/${videoId}`, data, config);
        } else {
            addOrUpdateVideoRequest('POST', '/right_side_video', data, config);
        }
    };

    return (
        <div className={`add-side-panel container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel`}>Right Side Panel</Link></li>
                        <li>{videoId ? 'Edit Video' : 'Add Video'}</li>
                    </ul>
                </div>
                <h3>{videoId ? 'Edit Video' : 'Add Video'}</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="field-add-form">
                                <input
                                    type="text"
                                    placeholder="https://youtu.be/YXtWPVFk5TQ"
                                    name="video_url"
                                    value={formData.video_url}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="more-add-form">
                    <button className="btn btn-primary">Add More <i className="fa-solid fa-plus"></i></button>
                </div>
            </div>
        </div>
    );
};

export default AddVideo;