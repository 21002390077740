import { getUserLocale } from './getUserLocale';

export const Logout = async (navigate) => {
    const { lang, country } = getUserLocale();

    let isLogout = false
    try {
      localStorage.clear(); // Clears all data from localStorage
      isLogout = true
      navigate(`/${country}/${lang}/auth/login`);
    } catch (e) {
      console.log(e);
    }
    return isLogout
}