import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import Pagination from "../../../components/Admin/Pagination";

const MyRecommendation = () => {
  const { isSidebarHidden } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [recommendations, setRecommendations] = useState([]);
  const { request: request, response: response } = useRequest();

  const handleSearchChange = (e) => {
    const query = e.target.value;
    // setSearchQuery(query);
    fetchRecommendations(query); // Fetch Inqueries whenever search input changes
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(0); // Reset to first page when page size changes
  };

  const fetchRecommendations = (search = "") => {
    request(
      "GET",
      `/recommendations?page_num=${currentPage}&page_size=${pageSize}&search=${search}`
    );
  };

  useEffect(() => {
    fetchRecommendations();
  }, []);

  useEffect(() => {
    if (response && response.status === "SUCCESS") {
      setRecommendations(response.data);
      setTotalRecords(response.total);
    }
  }, [response]);
  console.log(recommendations);

  return (
    <div
      className={`recommendation-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""
        }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input
                  type="text"
                  placeholder="Search"
                  onChange={handleSearchChange}
                />
              </form>
            </div>
            <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">Company</a>
              <a href="#" className="btn btn-primary">Store / Listing</a>
            </div>
            {/* <div className="btn-search-db">
              <a href="#" className="btn btn-outline-primary">
                Company
              </a>
              <a href="#" className="btn btn-primary">
                Store / Listing
              </a>
            </div> */}
            <div className="btn-filter">
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <thead>
                <tr>
                  <th>
                    Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Contributor
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Email
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Phone
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Message
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Rank
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {recommendations.map((item, index) => (
                  <tr key={index}>
                    <td>{"Name"}</td>
                    <td>{"Contributer"}</td>
                    <td>{"Email"}</td>
                    <td>{"Mobile No"}</td>
                    <td>{item.message}</td>
                    <td>
                      <div className="rank-table">{"4/5"}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Pagination
              totalRecords={totalRecords}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyRecommendation;
