import React, { useState, useEffect } from 'react';
import { NavLink, useParams, Link, useLocation } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { cloudFrontUrl } from '../../config';

const AdminSideBar = ({ hideSidebar, showSidebar, isSidebarHidden }) => {
    
    const { country, lang } = useParams();

    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [isCmsSubMenuOpen, setIsCmsSubMenuOpen] = useState(false);

    const [categories, setCategories] = useState([]);

    const { request: categroyRequest, response: categoryResponse } = useRequest()

    const location = useLocation(); // Get the current location

    const isDirectoryActive = location.pathname.startsWith(`/${country}/${lang}/admin/directory`);

    const isCmsActive = location.pathname.startsWith(`/${country}/${lang}/admin/cms`);

    const handleMenuClick = () => {
        setIsSubMenuOpen(isSubMenuOpen ? false : true);
    };

    const handleCmsMenuClick = () => {
        setIsCmsSubMenuOpen(isCmsSubMenuOpen ? false : true);
    };

    useEffect(() => {
        categroyRequest('GET', '/category')
    }, []);

    useEffect(() => {
        if (categoryResponse && categoryResponse?.status === "SUCCESS") {
            setCategories(categoryResponse?.data)
        }
    }, [categoryResponse]);

    return (
        <>
            <div className={`sidebar-dashboard ${isSidebarHidden ? 'hidden' : ''}`} id="sidebar-dashboard">
                <button className="toggle-sidebar" onClick={hideSidebar} style={{ display: isSidebarHidden ? 'none' : 'block' }} id="hide-sidebar"><i className="fa-solid fa-angle-left"></i></button>
                <button className="toggle-sidebar" onClick={showSidebar} style={{ display: isSidebarHidden ? 'block' : 'none' }} id="show-sidebar"><i className="fa-solid fa-angle-right"></i></button>
                <div className="logo-sidebar"><Link to={`/${country}/${lang}/admin`}><img src="/assets/images/logo.svg" alt="MyFinder" /></Link></div>
                <ul className="menu-sidebar">
                    <li>
                        <li>
                            <NavLink
                                to={`/${country}/${lang}/admin`}
                                className={({ isActive }) => (isActive ? "active" : "")}
                                end
                            >
                                <i><img src="/assets/images/icons/list.svg" alt="list" /></i>
                                Dashboard
                            </NavLink>
                        </li>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) => (isDirectoryActive && isActive ? "active" : "")}
                            onClick={handleMenuClick}
                        >
                            <i><img src="/assets/images/icons/list.svg" alt="list" /></i>
                            Category
                            <i className={`fa-solid icon-ms ${isSubMenuOpen ? "fa-angle-up" : "fa-angle-down"}`}></i>
                        </NavLink>
                        <ul className="dropmenu-sidebar" style={{ display: isSubMenuOpen ? "block" : "none" }}>
                            {categories && categories.map((data, index) => (
                                <li key={index}>
                                    <NavLink
                                        to={`/${country}/${lang}/admin/directory/${data.slug}`}
                                        className={({ isActive }) => (isActive ? "selected" : "")}
                                    >
                                        {console.log(data)}
                                        <i><img src={cloudFrontUrl + data.icon} alt={data.slug} /></i>
                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/attraction`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/attraction.svg" alt="attraction" /></i>
                            Attraction
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/community`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/community.svg" alt="MyCommunity" /></i>
                            MyCommunity
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/sub-admin`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/user.svg" alt="user" /></i>
                            User Management
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/customer`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/community.svg" alt="customer" /></i>
                            Customer Management
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            className={({ isActive }) => (isCmsActive && isActive ? "active" : "")}
                            onClick={handleCmsMenuClick}
                        >
                            <i><img src="/assets/images/icons/cms.svg" alt="cms" /></i>
                            CMS Pages
                            <i className={`fa-solid icon-ms ${isCmsSubMenuOpen ? "fa-angle-up" : "fa-angle-down"}`}></i>
                        </NavLink>
                        <ul className="dropmenu-sidebar" style={{ display: isCmsSubMenuOpen ? "block" : "none" }}>
                            <li>
                                <NavLink
                                    to={`/${country}/${lang}/admin/cms/how-it-work`}
                                    className={({ isActive }) => (isActive ? "selected" : "")}
                                >
                                    How It Work?
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={`/${country}/${lang}/admin/cms/help-center`}
                                    className={({ isActive }) => (isActive ? "selected" : "")}
                                >
                                    Help Center
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={`/${country}/${lang}/admin/cms/faqs`}
                                    className={({ isActive }) => (isActive ? "selected" : "")}
                                >
                                    FAQs
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={`/${country}/${lang}/admin/cms/about-us`}
                                    className={({ isActive }) => (isActive ? "selected" : "")}
                                >
                                    About Us
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    to={`/${country}/${lang}/admin/cms/policy`}
                                    className={({ isActive }) => (isActive ? "selected" : "")}
                                >
                                    Policy
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/blog`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/lists.svg" alt="lists" /></i>
                            Blogs
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/live`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/live.svg" alt="live" /></i>
                            MyLive
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/review`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/review.svg" alt="review" /></i>
                            Review
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/recommendation`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/recommendation.svg" alt="recommendation" /></i>
                            Recommendation
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/social`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/social.svg" alt="Social" /></i>
                            Social Media
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/newsletter`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/newsletter.svg" alt="newsletter" /></i>
                            Newsletters
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/announcement`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/admin.svg" alt="announcement" /></i>
                            Announcement
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to={`/${country}/${lang}/admin/admin-control`}
                            className={({ isActive }) => (isActive ? "active" : "")}
                        >
                            <i><img src="/assets/images/icons/admin.svg" alt="admin" /></i>
                            Admin Control
                        </NavLink>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default AdminSideBar;
