import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';

const AddSubAdmin = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        category_id: '',
        sub_category_id: '',
        username: '',
        password: ''
    });
    const [errors, setErrors] = useState({});

    const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
    const { request: fetchSubCategoriesRequest, response: subCategoriesResponse } = useRequest();
    const { request: addSubAdminRequest, response: addSubAdminResponse } = useRequest();

    useEffect(() => {
        fetchCategoriesRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategories(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    useEffect(() => {
        if (formData.category_id) {
            fetchSubCategoriesRequest('GET', `/sub-categories/${formData.category_id}`);
        }
    }, [formData.category_id]);

    useEffect(() => {
        if (subCategoriesResponse && subCategoriesResponse.status === "SUCCESS") {
            setSubCategories(subCategoriesResponse.data || []);
        }
    }, [subCategoriesResponse]);

    useEffect(() => {
        if (addSubAdminResponse && addSubAdminResponse.status === "SUCCESS") {
            Toast(true, addSubAdminResponse.message);
            navigate(`/${country}/${lang}/admin/sub-admin`);
        } else if (addSubAdminResponse) {
            Toast(false, addSubAdminResponse.message);
        }
    }, [addSubAdminResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.category_id) formErrors.category_id = 'Category is required';
        if (!formData.sub_category_id) formErrors.sub_category_id = 'Subcategory is required';
        if (!formData.username) formErrors.username = 'Username is required';
        if (!formData.password) formErrors.password = 'Password is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            name: formData.name,
            email: formData.email,
            username: formData.username,
            password: formData.password,
            category_id: formData.category_id,
            sub_category_id: formData.sub_category_id
        };

        addSubAdminRequest('POST', '/sub-admin', data);
    };

    return (
        <div className={`add-sub-admin container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to={`/${country}/${lang}/admin`}>Dashboard</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/sub-admin`}>Sub Admin</Link></li>
                        <li>Add Sub Admin</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Name</label>
                                    {errors.name && <p className="error">{errors.name}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Type Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Email</label>
                                    {errors.email && <p className="error">{errors.email}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="email"
                                            placeholder="Enter Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Select Category</label>
                                    {errors.category_id && <p className="error">{errors.category_id}</p>}
                                    <div className="field-add-form">
                                        <select
                                            name="category_id"
                                            value={formData.category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Select SubCategory</label>
                                    {errors.sub_category_id && <p className="error">{errors.sub_category_id}</p>}
                                    <div className="field-add-form">
                                        <select
                                            name="sub_category_id"
                                            value={formData.sub_category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select SubCategory</option>
                                            {subCategories.map(subCategory => (
                                                <option key={subCategory.id} value={subCategory.id}>
                                                    {subCategory.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Username</label>
                                    {errors.username && <p className="error">{errors.username}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Username"
                                            name="username"
                                            value={formData.username}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Password</label>
                                    {errors.password && <p className="error">{errors.password}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="password"
                                            placeholder="Password"
                                            name="password"
                                            value={formData.password}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddSubAdmin;
