import { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Toast } from "../utils/ToastNotification";
import { Logout } from '../utils/Logout';
import { getUserLocale } from '../utils/getUserLocale';
import {baseURL} from '../config';

const useRequest = () => {
  const { lang, country } = getUserLocale();
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const navigate = useNavigate();

  const startFetching = () => {
    setResponse(null);
    setError(null);
  };

  const clear = () => {
    setResponse(null);
    setError(null);
  };

  const fetchedData = () => {
    setError(null);
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem(" ");
    try {
      const res = await axios.post(`${baseURL}/auth/refresh`, { refresh_token: refreshToken });
      localStorage.setItem("access_token", res.data.access_token);
      return res.data.access_token;
    } catch (err) {
      console.error("Failed to refresh token:", err);
      Logout();
      throw err;
    }
  };

  const requestData = async (method, url, data) => {
    let accessToken = localStorage.getItem("access_token");
    const config = {
      method,
      url: `${baseURL}${url}`,
      headers: {
        Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
        "Accept-Language": `${lang}-${country}`
      },
      data,
    };

    startFetching();

    try {
      const res = await axios(config);
      fetchedData();
      setResponse(res.data);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        try {
          const newAccessToken = await refreshToken();
          // Retry original request with new access token
          config.headers.Authorization = `Bearer ${newAccessToken}`;
          const res = await axios(config);
          fetchedData();
          setResponse(res.data);
        } catch (refreshErr) {
          console.error("Refresh token expired or failed:", refreshErr);
          Logout();
        }
      } else if (err.response && err.response.status === 404) {
        navigate("/404");
      } else {
        Toast(false, err.response?.data?.message || "An error occurred")
      }
    }
  };

  return {
    error,
    request: requestData,
    clear,
    response,
    setError,
  };
};

export default useRequest;
