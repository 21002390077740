import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
const AppliedJobs = () => {
  const { isSidebarHidden } = useOutletContext();
  const [jobs,setJobs] = useState();


  const {request,response} = useRequest();
  const fetchAppliedJobs = async()=>{
    request("GET","/applied-jobs");
  }
  useEffect(()=>{
    fetchAppliedJobs()
  },[]);

  useEffect(()=>{
    if(response){
      setJobs(response.data);
    }
  },[response])

  return (
    <div
      className={`applied-job-page container-dashboard ${
        isSidebarHidden ? "sidebar-dashboard-hidden" : ""
      }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <h1>Applied Jobs</h1>
        <div className="box-dashboard">
          <div className="search-wraper">
            <div className="search-db">
              <i className="fa-solid fa-magnifying-glass"></i>
              <form>
                <input type="text" placeholder="Search" />
              </form>
            </div>
            <div className="btn-filter">
              <a href="#">
                <img
                  src="/assets/images/icons/calendar.svg"
                  alt="calendar"
                />
                Apr 10 - Apr 30
              </a>
              <a href="#">
                <img
                  src="/assets/images/icons/download.svg"
                  alt="Download"
                />
                Download
              </a>
            </div>
          </div>
          <div className="table-dashboard">
            <table>
              <tbody>
                <tr>
                  <th>
                    Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Company Name
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Salary
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Status
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Applied On
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                  <th>
                    Action
                    <img src="/assets/images/icons/sort.svg" alt="sort" />
                  </th>
                </tr>
                {jobs && jobs?.map((job, index) => (
                  <tr key={index}>
                    <td>{job.name}</td>
                    <td>{job.email}</td>
                    <td>{job.mobile}</td>
                    <td>{job?.job?.title}</td>
                    <td>{new Date(job.created_at).toDateString()}</td>
                    <td>
                      <div className="action-table">
                        <a href="#">
                          Edit <i className="fa-solid fa-pencil"></i>
                        </a>
                        <a href="#">
                          Delete <i className="fa-solid fa-trash"></i>
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <div className="show-per-page">
              <label>Show per page:</label>
              <select>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
              </select>
            </div>
            <div className="info-pagination">
              <span>1 - 10 of 11 items</span>
              <div className="control-pagination">
                <button className="active">1</button>
                <button>2</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AppliedJobs;
