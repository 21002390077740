import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from "../../../../hooks/useRequest";
import { Toast } from '../../../../utils/ToastNotification';

const UpdateCmsVideo = () => {
    const { country, lang, video_id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        category_id: '',
        cover_video_image: null,
        video_url: ''
    });

    const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
    const { request: fetchCmsVideoRequest, response: fetchCmsVideoResponse } = useRequest();
    const { request: updateCmsVideoRequest, response: updateCmsVideoResponse } = useRequest();

    useEffect(() => {
        fetchCategoriesRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategories(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    useEffect(() => {
        fetchCmsVideoRequest('GET', `/cms-videos/${video_id}`);
    }, [video_id]);

    useEffect(() => {
        if (fetchCmsVideoResponse && fetchCmsVideoResponse.status === "SUCCESS") {
            setFormData({
                category_id: fetchCmsVideoResponse.data.category_id || '',
                cover_video_image: fetchCmsVideoResponse.cover_video_image,
                video_url: fetchCmsVideoResponse.data.video_url || ''
            });
        }
    }, [fetchCmsVideoResponse]);

    useEffect(() => {
        if (updateCmsVideoResponse && updateCmsVideoResponse.status === "SUCCESS") {
            Toast(true, updateCmsVideoResponse.message);
            navigate(`/${country}/${lang}/admin/cms/how-it-work`);
        } else if (updateCmsVideoResponse) {
            Toast(false, updateCmsVideoResponse.message);
        }
    }, [updateCmsVideoResponse]);

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setFormData({ ...formData, [name]: type === 'file' ? e.target.files[0] : value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.category_id) formErrors.category_id = 'Category is required';
        if (!formData.video_url) formErrors.video_url = 'Video url is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('category_id', formData.category_id);
        if (formData.cover_video_image) {
            data.append('cover_video_image', formData.cover_video_image);
        }
        data.append('video_url', formData.video_url);

        await updateCmsVideoRequest('PUT', `/cms-videos/${video_id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

    };

    return (
        <div className={`add-video container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li><Link to={`/${country}/${lang}/admin/cms/how-it-work`}>How It Work?</Link></li>
                        <li>Update Video</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Select Category</label>
                                    {errors.category_id && <p className="error">{errors.category_id}</p>}

                                    <div className="field-add-form">
                                        <select name="category_id" value={formData.category_id} onChange={handleInputChange}>
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Upload Video Cover</label>
                                    {errors.cover_video_image && <p className="error">{errors.cover_video_image}</p>}
                                    {fetchCmsVideoResponse?.data?.cover_video_image && (
                                        <div className="existing-cover">
                                            <img src={fetchCmsVideoResponse.data.cover_video_image} alt="Current Cover" style={{ width: '100px', marginBottom: '10px' }} />
                                            <p>Current Cover Image</p>
                                        </div>
                                    )}
                                    <div className="attached-input">
                                        <input type="file" name="cover_video_image" onChange={handleInputChange} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                            </div>
                            <label>Add Video URL</label>
                            {errors.video_url && <p className="error">{errors.video_url}</p>}
                            <div className="field-add-form">
                                <input type="text" value={formData.video_url} placeholder="Add Video URL" name="video_url" onChange={handleInputChange} />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateCmsVideo;
