import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';
import Pagination from '../../../../components/Admin/Pagination';
import { cloudFrontUrl } from '../../../../config';
import Amenity from './components/Amenity';
import Assets from './components/Assets';
import MyStoryTab from './components/MyStoryTab';
import MyReelTab from './components/MyReelTab';
import Gallery from './components/Gallery';

const AdminAddListing = () => {
    const { country, lang, category, customer_id, id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [activeVerticalTab, setActiveVerticalTab] = useState('story');

    const [logoPreview, setLogoPreview] = useState(null);
    const [logo, setLogo] = useState(null);
    const logoRef = useRef();

    const [listingId, setListingId] = useState(null);
    const [categoryId, setCategoryId] = useState(null);
    const [categoryData, setCategoryData] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [agentListing, setAgentListing] = useState(null);
    const [agents, setAgents] = useState([]);
    const [subCategory, setSubCategory] = useState('');
    const [descriptionInputStates, setDescriptionInputStates] = useState(true)
    const [metaInputStates, setMetaInputStates] = useState(true);

    const [listing, setListing] = useState({
        category_id: categoryId,
        sub_category_id: null,
        title: null,
        location: null,
        status: null,
        mobile: null,
        whatsapp: null,
        google_review: null,
        description: null,
        location_near_by: null,
        meta_title: null,
        meta_description: null,
        meta_tags: null
    });

    const [errors, setErrors] = useState({
        title: '',
        location: '',
        status: '',
        mobile: '',
        whatsapp: '',
        google_review: '',
    });

    const { request: categroyRequest, response: categoryResponse } = useRequest()
    const { request: subCategroyRequest, response: subCategoryResponse } = useRequest()
    const { request: listingRequest, response: listingResponse } = useRequest()
    const { request: getListingRequest, response: getListingResponse } = useRequest()
    const { request: agentRequest, response: agentResponse } = useRequest()
    const { request: agentListingRequest, response: agentListingResponse } = useRequest()
    const { request: agentListingAddUpdateRequest, response: agentListingAddUpdateResponse } = useRequest()

    const handleAgentSelect = (e) => {
        if(id){
            const data = {
                agent_id: e.target.value,
                listing_id: id
            }
            if(agentListing){
                agentListingAddUpdateRequest('PUT', `/agent-listing/${agentListing?.id}`, data)
            }else{
                agentListingAddUpdateRequest('POST', `/agent-listing`, data)
            }
        }
    }

    const handleListingChange = (e) => {
        const { name, value } = e.target;
        setListing((prev) => ({
            ...prev,
            [name]: value,
        }));

        setErrors((prev) => ({
            ...prev,
            [name]: value ? '' : `The ${name} field is required.`,
        }));
    };

    const handleSubCategoryChange = (e) => {
        const selectedSubCategory = subCategories.find(data => data.slug === e.target.value);
        setListing((prev) => ({
            ...prev,
            sub_category_id: selectedSubCategory?.id,
        }));

        setErrors((prev) => ({
            ...prev,
            sub_category_id: selectedSubCategory?.id ? '' : 'The sub-category field is required.',
        }));
    };

    const handleLogoUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            setLogo(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
            setErrors((prev) => ({ ...prev, logo: '' }));
        } else {
            setLogo(null)
            logoRef.current.value = '';
            setLogoPreview(null);
            setErrors((prev) => ({ ...prev, logo: 'Logo is required.' }));
        }
    };

    const handleDivClick = () => {
        if (logoRef.current) {
            logoRef.current.click(); 
        }
    };

    const handleDescriptionInputEnable = () => {
        if (!descriptionInputStates) {
            let formData = new FormData()
            formData.append('description', listing.description)
            if (listingId) {
                listingRequest('PUT', `/listing/${listingId}`, formData)
            } else {
                Toast(false, 'Create a listing')
            }
        }
        setDescriptionInputStates(descriptionInputStates ? false : true);
    };

    const handleMetaInputEnable = () => {
        if (!metaInputStates) {
            let formData = new FormData()
            formData.append('meta_title', listing.meta_title)
            formData.append('meta_description', listing.meta_description)
            formData.append('meta_tags', listing.meta_tags)
            if (listingId) {
                listingRequest('PUT', `/listing/${listingId}`, formData)
            } else {
                Toast(false, 'Create a listing first')
            }
        }
        setMetaInputStates(metaInputStates ? false : true);
    };

    const handleSaveListing = (isBack) => {
        if (!id) {
            // Check for required fields
            let newErrors = {};

            if (!listing.title) newErrors.title = 'Listing title is required.';
            if (!listing.location) newErrors.location = 'Location is required.';
            if (!listing.status) newErrors.status = 'Status is required.';
            if (!listing.mobile) newErrors.mobile = 'Mobile is required.';
            if (!listing.whatsapp) newErrors.whatsapp = 'Whats app is required.';
            if (!listing.google_review) newErrors.google_review = 'Google review is required.';
            if (!listing.category_id) newErrors.category_id = 'Category is required.';
            if (!listing.sub_category_id) newErrors.sub_category_id = 'Sub-category is required.';

            const logoInput = document.querySelector('input[name="logo"]');
            if (!logoInput.files.length) newErrors.logo = 'Logo is required.';

            if (Object.keys(newErrors).length) {
                setErrors(newErrors);
                return;
            }
        }

        let formData = new FormData()
        if (customer_id) formData.append('user_id', customer_id);
        if (listing.title) formData.append('title', listing.title);
        if (listing.location) formData.append('location', listing.location);
        if (listing.status) formData.append('status_text', listing.status);
        if (listing.mobile) formData.append('mobile', listing.mobile);
        if (listing.whatsapp) formData.append('whatsapp', listing.whatsapp);
        if (listing.google_review) formData.append('google_place_id', listing.google_review);
        if (listing.description) formData.append('description', listing.description);
        if (listing.category_id) formData.append('category_id', listing.category_id);
        if (listing.sub_category_id) formData.append('sub_category_id', listing.sub_category_id);
        if (listing.location_near_by) formData.append('location_near_by', listing.location_near_by);
        if (logo) formData.append('logo', logo); // Append the logo if it's provided
        formData.append('meta_title', listing.meta_title)
        formData.append('meta_description', listing.meta_description)
        formData.append('meta_tags', listing.meta_tags)

        if (id) {
            listingRequest('PUT', `/listing/${id}`, formData)
        } else {
            listingRequest('POST', '/listing', formData)
        }
    }

    useEffect(() => {
        categroyRequest('GET', '/category')
        if (id) {
            getListingRequest('GET', `/listing/${id}`)
            agentListingRequest('GET', `/agent-listing/${id}`)
        }
    }, []);

    useEffect(() => {
        if (getListingResponse && getListingResponse.status === "SUCCESS") {
            setListingId(getListingResponse?.data?.id)
            setLogoPreview(cloudFrontUrl + getListingResponse.data?.logo);
            setSubCategory(getListingResponse?.data?.sub_category?.slug)
            setListing({
                category_id: getListingResponse?.data?.category?.id || null,
                sub_category_id: getListingResponse?.data?.sub_category?.id || null,
                title: getListingResponse?.data?.title || null,
                location: getListingResponse?.data?.location || null,
                status: getListingResponse?.data?.status || null,
                mobile: getListingResponse?.data?.mobile || null,
                whatsapp: getListingResponse?.data?.whatsapp || null,
                google_review: getListingResponse?.data?.google_place_id || null,
                description: getListingResponse?.data?.description || null,
                meta_title: getListingResponse?.data?.meta_title || null,
                meta_description: getListingResponse?.data?.meta_description || null,
                meta_tags: getListingResponse?.data?.meta_tags || null,
                location_near_by: getListingResponse?.data?.location_near_by || null
            });
        }
    }, [getListingResponse]);

    useEffect(() => {
        if (listingResponse && listingResponse.status === "SUCCESS") {
            setListingId(listingResponse.data.id)
            Toast(true, listingResponse?.message)
            setLogo(null)
            logoRef.current.value = '';
            navigate(`/${country}/${lang}/admin/directory/${category}/listing`);
        } else {
            Toast(false, listingResponse?.message)
        }
    }, [listingResponse]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data)
            let newCategories = categoryResponse.data
            const selectedCategory = newCategories.find(d => d.slug === category);
            setCategoryId(selectedCategory?.id || null);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryId) {
            setListing((prev) => ({
                ...prev,
                category_id: categoryId,
            }));

            setErrors((prev) => ({
                ...prev,
                category_id: categoryId ? '' : 'The category field is required.',
            }));

            subCategroyRequest('GET', `/sub-categories/${categoryId}`)
            agentRequest('GET', `/agents/${categoryId}`)
        }
    }, [categoryId]);

    useEffect(() => {
        if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
            setSubCategories(subCategoryResponse.data)
        }
    }, [subCategoryResponse]);

    useEffect(() => {
        if (agentResponse && agentResponse.status === "SUCCESS") {
            setAgents(agentResponse.data)
        }
    }, [agentResponse]);

    useEffect(() => {
        if (agentListingResponse && agentListingResponse.status === "SUCCESS") {
            setAgentListing(agentListingResponse?.data)
        }
    }, [agentListingResponse]);

    useEffect(() => {
        if (agentListingAddUpdateResponse && agentListingAddUpdateResponse.status === "SUCCESS") {
            Toast(true, agentListingAddUpdateResponse?.message)
        }else{
            Toast(false, agentListingAddUpdateResponse?.message)
        }
    }, [agentListingAddUpdateResponse]);


    return (
        <>
            <div className={`store-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Directory</a></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}/listing`}>Stores/Listings</Link></li>
                            {/* <li><a href="#">Company Profile Page</a></li> */}
                            <li>Store Page</li>
                        </ul>
                        <div className="btn-bc">
                            <div className="field-btn-bc">
                                <select onChange={handleAgentSelect}>
                                    <option value="">Select Agent</option>
                                    {agents && agents.map((data, index) => (
                                        <option
                                            key={index}
                                            value={data.id}
                                            selected={data?.id === agentListing?.agent?.id ? true : false}
                                        >
                                            {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <a onClick={handleSaveListing} className="btn btn-primary">{id ? 'Update Listing' : 'Add Listing'} {id ? <i className="fa-solid fa-pencil"></i> : <i className="fa-solid fa-plus"></i>} </a>
                        </div>
                    </div>
                    <div className="upload-gallery">
                        <Gallery listingId={listingId} />
                    </div>
                    <div className="title-db">
                        <div className="row">
                            <div className="col-sm-2">
                                {errors.logo && <div className="error">{errors.logo}</div>}
                                <div className="field-title-db" onClick={handleDivClick} style={{ cursor: 'pointer', height: '15rem'}}>
                                    <div className="attached-input" style={{ height: "103px", width: "103px" }}>  
                                        <input type="file" className="form-control" name="logo" ref={logoRef} onChange={handleLogoUpload} style={{ display: 'none' }} required />
                                        {!logoPreview ? (
                                            <small>Upload Logo or Photo</small>
                                        ) : (
                                            <img src={logoPreview} alt="Logo" className="img-circle" />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-10">
                                {errors.title && <div className="error">{errors.title}</div>}
                                <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                    <input type="text" name='title' value={listing.title} onChange={handleListingChange} placeholder="Listing Name" />
                                    <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select name='category_id' disabled>
                                                <option value="">Select Your Category</option>
                                                {categoryData && categoryData.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.slug}
                                                        selected={data?.slug === category ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.sub_category_id && <div className="error">{errors.sub_category_id}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <select name='sub_category_id' onChange={handleSubCategoryChange}>
                                                <option value="">Select sub category</option>
                                                {subCategories && subCategories.map((data, index) => (
                                                    <option
                                                        key={index}
                                                        value={data.slug}
                                                        selected={data?.slug === subCategory ? true : false}
                                                    >
                                                        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        {errors.location && <div className="error">{errors.location}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='location' value={listing.location} onChange={handleListingChange} placeholder="Location" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='status' value={listing.status} onChange={handleListingChange} placeholder="Status" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-4">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='mobile' value={listing.mobile} onChange={handleListingChange} placeholder="Type Your Mobile Number" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='whatsapp' value={listing.whatsapp} onChange={handleListingChange} placeholder="Type Your Whatsapp Number" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        {errors.status && <div className="error">{errors.status}</div>}
                                        <div className="field-title-db"><img src="/assets/images/icons/tick.svg" alt="tick" />
                                            <input type="text" name='google_review' value={listing.google_review} onChange={handleListingChange} placeholder="Add Google Review Link" />
                                            <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <button className="edit-text" onClick={handleDescriptionInputEnable}>{descriptionInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        <h3>About</h3>
                        <textarea placeholder="Description" name='description' value={listing.description} onChange={handleListingChange} disabled={descriptionInputStates}></textarea>
                    </div>
                    <div className="box-dashboard">
                        <Amenity categoryId={categoryId} listingId={listingId} />
                    </div>
                    <div className="box-dashboard">
                        <Assets listingId={listingId} />
                    </div>
                    <div className="box-dashboard">
                        <div className="heading">
                            <h2>Location & Near by</h2>
                        </div>
                        <div className="location-dbe">
                            <input type="text" name='location_near_by' value={listing.location_near_by} onChange={handleListingChange} placeholder="Type your location" />
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462561.6574537445!2d55.22748795!3d25.076022449999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1723828661752!5m2!1sen!2sin"
                                width="600" height="450" style={{ border: '0;' }} allowfullscreen="" loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="social-activity">
                            <div className="vtab">
                                <ul className="vtab-menu">
                                    <li data-tab="mystories-vtab" className={activeVerticalTab === 'story' ? 'active' : ''} onClick={() => setActiveVerticalTab('story')}><img src="/assets/images/icons/story.svg" alt="story" /> MyStories</li>
                                    <li data-tab="myreels-vtab" className={activeVerticalTab === 'reel' ? 'active' : ''} onClick={() => setActiveVerticalTab('reel')}><img src="/assets/images/icons/reels.svg" alt="reels" /> MyReels</li>
                                </ul>
                                <div className="wraper-vtab">
                                    <div id="mystories-vtab" className={`vtab-content ${activeVerticalTab === 'story' ? 'active' : ''}`}>
                                        <MyStoryTab id={id} activeVerticalTab={activeVerticalTab} />
                                    </div>
                                    <div id="myreels-vtab" className={`vtab-content ${activeVerticalTab === 'reel' ? 'active' : ''}`}>
                                        <MyReelTab id={id} activeVerticalTab={activeVerticalTab} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <button className="edit-text" onClick={handleMetaInputEnable}>{metaInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        <div className="meta-dbe">
                            <div className="heading">
                                <h2>SEO</h2>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Meta Title*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_title' value={listing.meta_title} onChange={handleListingChange} placeholder="Meta Title" disabled={metaInputStates} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <label>Meta Tags*</label>
                                        <div className="field-meta-dbe">
                                            <input type="text" name='meta_tags' value={listing.meta_tags} onChange={handleListingChange} placeholder="Meta Keywords" disabled={metaInputStates} />
                                        </div>
                                    </div>
                                </div>
                                <label>Meta Description*</label>
                                <div className="field-meta-dbe">
                                    <textarea placeholder="Meta Description" name='meta_description' value={listing.meta_description} onChange={handleListingChange} disabled={metaInputStates}></textarea>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="submit-dbe">
                        <button type='button' onClick={handleSaveListing}>{id ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminAddListing;
