import React, { useState, useEffect, useRef } from 'react';
import useRequest from '../../../../../hooks/useRequest';
import { Toast } from '../../../../../utils/ToastNotification';
import { cloudFrontUrl, googleMapApiKey } from '../../../../../config';

const Htabs = ({ activeHorizontalTab, setActiveHorizontalTab, customerId, company, handleCompanyChange }) => {
    const [descriptionInputStates, setDescriptionInputStates] = useState(true);
    const galleryImageRef = useRef();
    const serviceImageRef = useRef();
    const downloadImageRef = useRef();
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);


    // About description
    const { request: customerDescriptionRequest, response: customerDescriptionResponse } = useRequest()

    const handleDescriptionInputEnable = () => {
        if (!descriptionInputStates) {
            let formData = new FormData()
            formData.append('description', company.description)
            if (customerId) {
                customerDescriptionRequest('PUT', `/customers/${customerId}`, formData)
            } else {
                Toast(false, 'Create a company')
            }
        }
        setDescriptionInputStates(descriptionInputStates ? false : true);
    };

    useEffect(() => {
        if (customerDescriptionResponse && customerDescriptionResponse.status === "SUCCESS") {
            Toast(true, customerDescriptionResponse?.message)
        } else {
            Toast(false, customerDescriptionResponse?.message)
        }
    }, [customerDescriptionResponse]);

    // Gallery
    const [galleries, setGalleries] = useState([]);
    const { request: galleryRequest, response: galleryResponse } = useRequest();
    const { request: addGalleryRequest, response: addGalleryResponse } = useRequest();
    const { request: deleteGalleryRequest, response: deleteGalleryResponse } = useRequest();

    const [image, setImage] = useState(null);
    const [url, setUrl] = useState(null);

    const handleAddGallery = (event, mediaType) => {
        event.preventDefault()
        if (!customerId) {
            Toast(false, 'Create a company first')
        }
        let formData = new FormData()
        formData.append('user_id', customerId)
        if (mediaType == "image") {
            formData.append('media_type', 'IMAGE')
            formData.append('image', image)
        } else {
            formData.append('media_type', 'VIDEO')
            formData.append('video_url', url)
        }
        if (customerId) {
            addGalleryRequest('POST', `/user-gallery`, formData)
        }
    }

    const handleGalleryDelete = (id) => {
        deleteGalleryRequest('DELETE', `/user-gallery/${id}`)
    }

    useEffect(() => {
        if (customerId && activeHorizontalTab === "gallery") {
            galleryRequest('GET', `/user-gallery/${customerId}`)
        }
        if (customerId && activeHorizontalTab === "service") {
            serviceRequest('GET', `/user-service/${customerId}`)
        }
        if (customerId && activeHorizontalTab === "offer") {
            offerRequest('GET', `/user-offer/${customerId}`)
        }
        if (customerId && activeHorizontalTab === "client") {
            clientRequest('GET', `/user-clients/${customerId}`)
        }
        if (customerId && activeHorizontalTab === "download") {
            downloadRequest('GET', `/user-download/${customerId}`)
        }
        if (customerId && activeHorizontalTab === "contact") {
            loadGoogleMapsScript()
            contactRequest('GET', `/user-contact/${customerId}`)
            fetchSocialMediaData()
        }
    }, [activeHorizontalTab]);

    useEffect(() => {
        if (galleryResponse && galleryResponse.status === "SUCCESS") {
            setGalleries(galleryResponse.data)
        }
    }, [galleryResponse]);

    useEffect(() => {
        if (addGalleryResponse && addGalleryResponse.status === "SUCCESS") {
            galleryImageRef.current.value = '';
            setImage(null)
            setUrl(null)
            Toast(true, addGalleryResponse?.message)
            galleryRequest('GET', `/user-gallery/${customerId}`)
        } else {
            Toast(false, addGalleryResponse?.message)
        }
    }, [addGalleryResponse]);

    useEffect(() => {
        if (deleteGalleryResponse && deleteGalleryResponse.status === "SUCCESS") {
            Toast(true, deleteGalleryResponse?.message)
            galleryRequest('GET', `/user-gallery/${customerId}`)
        } else {
            Toast(false, deleteGalleryResponse?.message)
        }
    }, [deleteGalleryResponse]);

    // Service
    const [services, setServices] = useState([]);
    const [downloads, setDownloads] = useState([]);
    const { request: serviceRequest, response: serviceResponse } = useRequest();
    const { request: addServiceRequest, response: addServiceResponse } = useRequest();
    const { request: deleteServiceRequest, response: deleteServiceResponse } = useRequest();
    const { request: downloadRequest, response: downloadResponse } = useRequest();
    const { request: addDownloadRequest, response: addDownloadResponse } = useRequest();
    const { request: deleteDownloadRequest, response: deleteDownloadResponse } = useRequest();

    const [serviceImage, setServiceImage] = useState(null);
    const [serviceTitle, setServiceTitle] = useState(null);
    const [downloadImage, setDownloadImage] = useState(null);
    const [downloadTitle, setDownloadTitle] = useState(null);

    const handleSaveService = (event) => {
        event.preventDefault()
        if (!customerId) {
            Toast(false, 'Create a company first')
        }
        let formData = new FormData()
        formData.append('user_id', customerId)
        formData.append('title', serviceTitle)
        formData.append('image', serviceImage)
        if (customerId) {
            addServiceRequest('POST', `/user-service`, formData)
        }
    }

    const handleSaveDownload = (event) => {
        event.preventDefault()
        if (!customerId) {
            Toast(false, 'Create a company first')
        }
        let formData = new FormData()
        formData.append('user_id', customerId)
        formData.append('title', downloadTitle)
        formData.append('image', downloadImage)
        if (customerId) {
            addDownloadRequest('POST', `/user-download`, formData)
        }
    }

    const handleServiceDelete = (id) => {
        deleteServiceRequest('DELETE', `/user-service/${id}`)
    }

    const handleDownloadDelete = (id) => {
        deleteDownloadRequest('DELETE', `/user-download/${id}`)
    }

    useEffect(() => {
        if (serviceResponse && serviceResponse.status === "SUCCESS") {
            setServices(serviceResponse.data)
        }
    }, [serviceResponse]);

    useEffect(() => {
        if (downloadResponse && downloadResponse.status === "SUCCESS") {
            setDownloads(downloadResponse.data)
        }
    }, [downloadResponse]);

    useEffect(() => {
        if (addServiceResponse && addServiceResponse.status === "SUCCESS") {
            serviceImageRef.current.value = '';
            setServiceImage(null)
            setServiceTitle(null)
            Toast(true, addServiceResponse?.message)
            serviceRequest('GET', `/user-service/${customerId}`)
        } else {
            Toast(false, addGalleryResponse?.message)
        }
    }, [addServiceResponse]);

    useEffect(() => {
        if (addDownloadResponse && addDownloadResponse.status === "SUCCESS") {
            downloadImageRef.current.value = '';
            setDownloadImage(null)
            setDownloadTitle(null)
            Toast(true, addDownloadResponse?.message)
            downloadRequest('GET', `/user-download/${customerId}`)
        } else {
            Toast(false, addDownloadResponse?.message)
        }
    }, [addDownloadResponse]);

    useEffect(() => {
        if (deleteServiceResponse && deleteServiceResponse.status === "SUCCESS") {
            Toast(true, deleteServiceResponse?.message)
            serviceRequest('GET', `/user-service/${customerId}`)
        } else {
            Toast(false, deleteServiceResponse?.message)
        }
    }, [deleteServiceResponse]);

    useEffect(() => {
        if (deleteDownloadResponse && deleteDownloadResponse.status === "SUCCESS") {
            Toast(true, deleteDownloadResponse?.message)
            downloadRequest('GET', `/user-download/${customerId}`)
        } else {
            Toast(false, deleteDownloadResponse?.message)
        }
    }, [deleteDownloadResponse]);

    // Offer and Client
    const [offers, setOffers] = useState([]);
    const [clients, setClients] = useState([]);
    const { request: offerRequest, response: offerResponse } = useRequest();
    const { request: addOfferRequest, response: addOfferResponse } = useRequest();
    const { request: deleteOfferRequest, response: deleteOfferResponse } = useRequest();
    const { request: clientRequest, response: clientResponse } = useRequest();
    const { request: addClientRequest, response: addClientResponse } = useRequest();
    const { request: deleteClientRequest, response: deleteClientResponse } = useRequest();
    const offerImageRef = useRef();
    const clientImageRef = useRef();
    const [offerImage, setOfferImage] = useState(null);
    const [clientImage, setClientImage] = useState(null);

    const handleClientOffer = (event) => {
        event.preventDefault()
        if (!customerId) {
            Toast(false, 'Create a company first')
        }
        let formData = new FormData()
        formData.append('user_id', customerId)
        if (customerId && activeHorizontalTab === "offer") {
            formData.append('image', offerImage)
            addOfferRequest('POST', `/user-offer`, formData)
        }
        if (customerId && activeHorizontalTab === "client") {
            formData.append('image', clientImage)
            addClientRequest('POST', `/user-clients`, formData)
        }
    }

    const handleClientOfferDelete = (id) => {
        if (customerId && activeHorizontalTab === "offer") {
            deleteOfferRequest('DELETE', `/user-offer/${id}`)
        }
        if (customerId && activeHorizontalTab === "client") {
            deleteClientRequest('DELETE', `/user-clients/${id}`)
        }
    }

    useEffect(() => {
        if (offerResponse && offerResponse.status === "SUCCESS") {
            setOffers(offerResponse.data)
        }
        if (clientResponse && clientResponse.status === "SUCCESS") {
            setClients(clientResponse.data)
        }
    }, [offerResponse, clientResponse]);

    useEffect(() => {
        if (addOfferResponse && addOfferResponse.status === "SUCCESS") {
            offerImageRef.current.value = '';
            setOfferImage(null)
            Toast(true, addOfferResponse?.message)
            offerRequest('GET', `/user-offer/${customerId}`)
        }
        if (addClientResponse && addClientResponse.status === "SUCCESS") {
            clientImageRef.current.value = '';
            setClientImage(null)
            Toast(true, addClientResponse?.message)
            clientRequest('GET', `/user-clients/${customerId}`)
        }
    }, [addOfferResponse, addClientResponse]);

    useEffect(() => {
        if (deleteOfferResponse && deleteOfferResponse.status === "SUCCESS") {
            Toast(true, deleteOfferResponse?.message)
            offerRequest('GET', `/user-offer/${customerId}`)
        } else {
            Toast(false, deleteOfferResponse?.message)
        }
        if (deleteClientResponse && deleteClientResponse.status === "SUCCESS") {
            Toast(true, deleteClientResponse?.message)
            clientRequest('GET', `/user-clients/${customerId}`)
        } else {
            Toast(false, deleteClientResponse?.message)
        }
    }, [deleteOfferResponse, deleteClientResponse]);

    // Customer contact update
    const locationInputRef = useRef(null);
    const autocompleteRef = useRef(null);
    const qrCodeImageRef = useRef(null)
    const [qrCodeImage, setQrCodeImage] = useState(null)
    const [contact, setContact] = useState({
        id: null,
        user_id: customerId,
        location: null,
        lat: null,
        long: null,
        mobile: null,
        email: null,
        website_url: null,
        google_place_id: null,
        qr_code_image: qrCodeImage
    });
    const { request: contactRequest, response: contactResponse } = useRequest();
    const { request: contactUpdateRequest, response: contactUpdateResponse } = useRequest();

    const initializeAutocomplete = () => {
        if (window.google) {
            // Initialize Google Places Autocomplete
            autocompleteRef.current = new window.google.maps.places.Autocomplete(locationInputRef.current, {
                types: ['geocode'], // Limit the types to geocode (addresses)
            });

            autocompleteRef.current.addListener('place_changed', () => {
                const place = autocompleteRef.current.getPlace();
                if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const long = place.geometry.location.lng();
                    const placeId = place.place_id;

                    // Update the contact state with place details
                    setContact((prevState) => ({
                        ...prevState,
                        location: place.formatted_address,
                        lat: lat,
                        long: long,
                        google_place_id: placeId,
                    }));
                }
            });
        }
    };

    const loadGoogleMapsScript = () => {
        // First, remove any existing Google Maps script
        const existingScript = document.querySelector('script[src*="maps.googleapis.com"]');
        if (existingScript) {
            existingScript.remove();
            window.google = undefined; // Unset the google object
        }

        // Also uninitialize any existing autocomplete instance
        if (autocompleteRef.current) {
            autocompleteRef.current = null;
        }

        // Now load the Google Maps script again
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapApiKey}&libraries=places`;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        script.onload = () => {
            initializeAutocomplete(); // Initialize once the script is loaded
        };
    };

    useEffect(() => {
        if (contactResponse && contactResponse.status === "SUCCESS") {
            setContact(contactResponse.data)
        }
    }, [contactResponse]);

    const handleContactChange = (e) => {
        const { name, value } = e.target;
        setContact((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleContactSave = (e) => {
        e.preventDefault()

        if (!customerId) {
            Toast(false, 'Create a company first')
            return false
        }

        let formData = new FormData()
        formData.append('user_id', customerId)

        if (contact.location) formData.append('location', contact.location);
        if (contact.lat) formData.append('lat', contact.lat)
        if (contact.long) formData.append('long', contact.long)
        if (contact.mobile) formData.append('mobile', contact.mobile)
        if (contact.email) formData.append('email', contact.email)
        if (contact.website_url) formData.append('website_url', contact.website_url)
        if (contact.google_place_id) formData.append('google_place_id', contact.google_place_id)
        if (qrCodeImage) formData.append('qr_code_image', qrCodeImage)

        if (contact.id) {
            contactUpdateRequest('PUT', `/user-contact/${contact.id}`, formData)
        } else {
            contactUpdateRequest('POST', '/user-contact', formData)
        }
    }

    useEffect(() => {
        if (contactUpdateResponse && contactUpdateResponse.status === "SUCCESS") {
            qrCodeImageRef.current.value = null
            setContact(contactUpdateResponse.data)
            Toast(true, contactUpdateResponse?.message)
        } else {
            qrCodeImageRef.current.value = null
            Toast(false, contactUpdateResponse?.message)
        }
    }, [contactUpdateResponse]);

    // Customer social media
    const [socialMedia, setSocialMedia] = useState({
        user_id: customerId,
        social_platform: 'fa-instagram',
        social_url: null
    });
    const [socialMediaUrls, setSocialMediaUrls] = useState([])
    const { request: socialMediaRequest, response: socialMediaResponse } = useRequest();
    const { request: socialMediaAddRequest, response: socialMediaAddResponse } = useRequest();
    const { request: socialMediaDeleteRequest, response: socialMediaDeleteResponse } = useRequest();

    const fetchSocialMediaData = () => {
        socialMediaRequest('GET', `/user-social-media/${customerId}`)
    }

    const handleSocialMediaChange = (e) => {
        const { name, value } = e.target;
        setSocialMedia((prev) => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleSocialMediaAdd = (e) => {
        e.preventDefault()
        if (!customerId) {
            Toast(false, 'Create a company first')
            return false
        }
        if (!socialMedia.social_platform && !socialMedia.social_url) {
            return false
        }
        socialMedia.user_id = customerId
        socialMediaAddRequest('POST', '/user-social-media', socialMedia)
    }

    const handleSocialMediaDelete = (id) => {
        if (id) {
            socialMediaDeleteRequest('DELETE', `/user-social-media/${id}`)
        }
    }

    useEffect(() => {
        if (socialMediaResponse && socialMediaResponse.status === "SUCCESS") {
            setSocialMediaUrls(socialMediaResponse?.data)
        }
    }, [socialMediaResponse]);

    useEffect(() => {
        if (socialMediaAddResponse && socialMediaAddResponse.status === "SUCCESS") {
            fetchSocialMediaData()
            Toast(true, socialMediaAddResponse?.message)
        } else if (socialMediaAddResponse && socialMediaAddResponse.status === "FAIL") {
            Toast(false, socialMediaAddResponse?.message)
        }
    }, [socialMediaAddResponse]);

    useEffect(() => {
        if (socialMediaDeleteResponse && socialMediaDeleteResponse.status === "SUCCESS") {
            fetchSocialMediaData()
            Toast(true, socialMediaDeleteResponse?.message)
        } else if (socialMediaDeleteResponse && socialMediaDeleteResponse.status === "FAIL") {
            Toast(false, socialMediaDeleteResponse?.message)
        }
    }, [socialMediaDeleteResponse]);

    return (
        <>
            <div className="htab-module">
                <ul className="tab-menu">
                    <li data-tab="about-htab" className={activeHorizontalTab === 'about' ? 'active position-relative' : 'position-relative'} onClick={() => setActiveHorizontalTab('about')}>
                        <span><img src="/assets/images/icons/about.svg" alt="about"
                            ondragstart="return false" />About</span>
                        {
                            customerId &&
                            <button className="edit-text position-absolute" style={{ top: "-20px", right: "-10px" }} onClick={handleDescriptionInputEnable}>{descriptionInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button>
                        }
                    </li>
                    <li data-tab="gallery-htab" className={activeHorizontalTab === 'gallery' ? 'active' : ''} onClick={() => setActiveHorizontalTab('gallery')}><span><img src="/assets/images/icons/gallery.svg" alt="gallery"
                        ondragstart="return false" />Gallery</span><button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                    </li>
                    <li data-tab="service-htab" className={activeHorizontalTab === 'service' ? 'active' : ''} onClick={() => setActiveHorizontalTab('service')}><span><img src="/assets/images/icons/property.svg" alt="property"
                        ondragstart="return false" />Services</span><button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                    </li>
                    <li data-tab="clients-htab" className={activeHorizontalTab === 'client' ? 'active' : ''} onClick={() => setActiveHorizontalTab('client')}><span><img src="/assets/images/icons/store.svg" alt="store"
                        ondragstart="return false" />Clients</span><button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                    </li>
                    <li data-tab="offer-htab" className={activeHorizontalTab === 'offer' ? 'active' : ''} onClick={() => setActiveHorizontalTab('offer')}><span><img src="/assets/images/icons/offer.svg" alt="offer"
                        ondragstart="return false" />Offers</span> <button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                    </li>
                    <li data-tab="download-htab" className={activeHorizontalTab === 'download' ? 'active' : ''} onClick={() => setActiveHorizontalTab('download')}>
                        <span><i className="fa-solid fa-download"></i>Download</span><button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                    </li>
                    <li data-tab="contact-htab" className={activeHorizontalTab === 'contact' ? 'active' : ''} onClick={() => setActiveHorizontalTab('contact')}><span><img src="/assets/images/icons/contact.svg" alt="contact"
                        ondragstart="return false" />Contact Us</span><button className="edit-text"><i className="fa-solid fa-pencil"></i></button>
                    </li>
                </ul>
                <div id="about-htab" className={`tab-content ${activeHorizontalTab === 'about' ? 'active' : ''}`}>
                    <div className="field-title-db">
                        <textarea placeholder="Description" name='description' value={company.description} onChange={handleCompanyChange}></textarea>
                        {/* <button className="edit-text" onClick={handleDescriptionInputEnable}>{descriptionInputStates ? <i className="fa-solid fa-pencil"></i> : <i className="fa fa-check"></i>}</button> */}
                    </div>
                </div>
                <div id="service-htab" className={`tab-content ${activeHorizontalTab === 'service' ? 'active' : ''}`}>
                    <div className="add-data">
                        <form onSubmit={handleSaveService}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Add Images</label>
                                    <div className="attached-input">
                                        <input type="file" ref={serviceImageRef} onChange={(e) => setServiceImage(e.target.files[0])} />
                                        {serviceImage && <small>{serviceImage.name}<button className="edit-text" style={{ padding: '10px 5px', marginTop: '6px' }}><i className="fa-solid fa-pencil" style={{ height: '0px' }}></i></button></small>}
                                        {!serviceImage && (
                                            <small>
                                                Upload <img src="/assets/images/icons/upload.svg" alt="upload" />
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Add Title</label>
                                    <input type="text" name='service_title' value={serviceTitle} onChange={(e) => setServiceTitle(e.target.value)} placeholder="Type Title" />
                                </div>
                            </div>
                            <div className="submit-dbe">
                                <button>Add More</button>
                            </div>
                        </form>
                    </div>
                    <div className="preview-data">
                        <ul>
                            {services && services.map((data, index) => (
                                <li key={index}>
                                    <img src={`${cloudFrontUrl}${data?.image}`} alt={data.title} />
                                    <button className="delete-text" onClick={() => handleServiceDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div id="offer-htab" className={`tab-content ${activeHorizontalTab === 'offer' ? 'active' : ''}`}>
                    <div className="add-data">
                        <form onSubmit={handleClientOffer}>
                            <label>Add Photo / Image</label>
                            <div className="attached-input">
                                <input type="file" ref={offerImageRef} onChange={(e) => setOfferImage(e.target.files[0])} />
                                {offerImage && <small>{offerImage.name}<button className="edit-text" style={{ padding: '10px 5px', marginTop: '6px' }}><i className="fa-solid fa-pencil" style={{ height: '0px' }}></i></button></small>}
                                {!offerImage && (
                                    <small>
                                        Upload <img src="/assets/images/icons/upload.svg" alt="upload" />
                                    </small>
                                )}
                            </div>
                            <div className="submit-dbe">
                                <button type='submit'>Add More</button>
                            </div>
                        </form>
                    </div>
                    <div className="preview-data">
                        <ul>
                            {offers && offers.map((data, index) => (
                                <li key={index}>
                                    <img src={`${cloudFrontUrl}${data?.image}`} alt="alt" />
                                    <button className="delete-text" onClick={() => handleClientOfferDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div id="gallery-htab" className={`tab-content ${activeHorizontalTab === 'gallery' ? 'active' : ''}`}>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="add-data">
                                <form onSubmit={(e) => handleAddGallery(e, 'image')}>
                                    <label>Add Images</label>
                                    <div className="attached-input">
                                        <input type="file" name='gallery_image' ref={galleryImageRef} onChange={(e) => setImage(e.target.files[0])} />
                                        {image && <small>{image.name}<button className="edit-text" style={{ padding: '10px 5px', marginTop: '6px' }}><i className="fa-solid fa-pencil" style={{ height: '0px' }}></i></button></small>}
                                        {!image && (
                                            <small>
                                                Upload <img src="/assets/images/icons/upload.svg" alt="upload" />
                                            </small>
                                        )}
                                    </div>
                                    <div className="submit-dbe">
                                        <button type='submit'>Add More</button>
                                    </div>
                                </form>
                            </div>
                            <div className="preview-data">
                                <ul>
                                    {galleries && galleries.map((data, index) => (
                                        data?.media_type === "IMAGE" && (
                                            <li key={index}>
                                                <img src={`${cloudFrontUrl}${data?.image}`} alt="alt" />
                                                <button className="delete-text" onClick={() => handleGalleryDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="add-data">
                                <form onSubmit={(e) => handleAddGallery(e, 'video')}>
                                    <label>Add Videos</label>
                                    <input type="text" name='gallery_video' value={url} onChange={(e) => setUrl(e.target.value)} placeholder="https://www.youtube.com" />
                                    <div className="submit-dbe">
                                        <button type='submit'>Add More</button>
                                    </div>
                                </form>
                            </div>
                            <div className="preview-data">
                                <ul>
                                    {galleries && galleries.map((data, index) => (
                                        data?.media_type === "VIDEO" && (
                                            <li key={index}>
                                                <iframe
                                                    src={data?.video_url}
                                                    title="YouTube Shorts video player"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                    referrerPolicy="strict-origin-when-cross-origin"
                                                    allowFullScreen
                                                    style={{ width: '200px', height: '90px' }} // Adjust height as needed
                                                ></iframe>
                                                <button className="delete-text" onClick={() => handleGalleryDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                                            </li>
                                        )
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="clients-htab" className={`tab-content ${activeHorizontalTab === 'client' ? 'active' : ''}`}>
                    <div className="add-data">
                        <form onSubmit={handleClientOffer}>
                            <label>Add Client Logo / Image</label>
                            <div className="attached-input">
                                <input type="file" ref={clientImageRef} onChange={(e) => setClientImage(e.target.files[0])} />
                                {clientImage && <small>{clientImage.name}<button className="edit-text" style={{ padding: '10px 5px', marginTop: '6px' }}><i className="fa-solid fa-pencil" style={{ height: '0px' }}></i></button></small>}
                                {!clientImage && (
                                    <small>
                                        Upload <img src="/assets/images/icons/upload.svg" alt="upload" />
                                    </small>
                                )}
                            </div>
                            <div className="submit-dbe">
                                <button type='submit'>Add More</button>
                            </div>
                        </form>
                    </div>
                    <div className="preview-data">
                        <ul>
                            {clients && clients.map((data, index) => (
                                <li key={index}>
                                    <img src={`${cloudFrontUrl}${data?.image}`} alt="alt" />
                                    <button className="delete-text" onClick={() => handleClientOfferDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div id="download-htab" className={`tab-content ${activeHorizontalTab === 'download' ? 'active' : ''}`}>
                    <div className="add-data">
                        <form onSubmit={handleSaveDownload}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Add Images</label>
                                    <div className="attached-input">
                                        <input type="file" ref={downloadImageRef} onChange={(e) => setDownloadImage(e.target.files[0])} />
                                        {downloadImage && <small>{downloadImage.name}<button className="edit-text" style={{ padding: '10px 5px', marginTop: '6px' }}><i className="fa-solid fa-pencil" style={{ height: '0px' }}></i></button></small>}
                                        {!downloadImage && (
                                            <small>
                                                Upload <img src="/assets/images/icons/upload.svg" alt="upload" />
                                            </small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Add Title</label>
                                    <input type="text" name='download_title' value={downloadTitle} onChange={(e) => setDownloadTitle(e.target.value)} placeholder="Example: Brochure, Company Profile, Menu, Catalogue etc" />
                                </div>
                            </div>
                            <div className="submit-dbe">
                                <button type='submit'>Add More</button>
                            </div>
                        </form>
                    </div>
                    <div className="preview-data">
                        <ul>
                            {downloads && downloads.map((data, index) => (
                                <li key={index}>
                                    <img src={`${cloudFrontUrl}${data?.image}`} alt={data.title} />
                                    <button className="delete-text" onClick={() => handleServiceDelete(data.id)}><i className="fa-solid fa-times"></i></button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div id="contact-htab" className={`tab-content ${activeHorizontalTab === 'contact' ? 'active' : ''}`}>
                    <div className="add-data">
                        <form onSubmit={handleContactSave}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Add Your Location</label>
                                    <input
                                        type="text"
                                        name="location"
                                        value={contact.location}
                                        ref={locationInputRef}
                                        onChange={handleContactChange}
                                        placeholder="Type location"
                                    />
                                </div>
                                <div className="col-sm-6">
                                    <label>Add Your Mobile Number</label>
                                    <input type="text" name='mobile' value={contact.mobile} onChange={handleContactChange} placeholder="+971 55 1234567" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Add Your Email</label>
                                    <input type="text" name='email' value={contact.email} onChange={handleContactChange} placeholder="Type email" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Add Your Website URL</label>
                                    <input type="text" name='website_url' value={contact.website_url} onChange={handleContactChange} placeholder="www.domain.com" />
                                </div>
                                <div className="col-sm-6">
                                    <label>Google Review Link</label>
                                    <input type="text" name='google_place_id' value={contact.google_place_id} onChange={handleContactChange} placeholder="URL..." />
                                </div>
                                <div className="col-sm-6">
                                    <label>Upload QR Code</label>
                                    <div className="attached-input">
                                        <input type="file" ref={qrCodeImageRef} onChange={(e) => setQrCodeImage(e.target.files[0])} />
                                        <small>Upload <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-dbe">
                                <button type='submit'>Save</button>
                            </div>
                        </form>
                    </div>
                    <div className="add-data">
                        <form onSubmit={handleSocialMediaAdd}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Add Your Social Media URL</label>
                                    <input type="text" name='social_url' onChange={handleSocialMediaChange} placeholder="URL..." />
                                </div>
                                <div className="col-sm-6">
                                    <label>Select Social Media</label>
                                    <div className="field-add-form">
                                        <select name='social_platform' onChange={handleSocialMediaChange}>
                                            <option value="fa-instagram">Instagram</option>
                                            <option value="fa-facebook-f">Facebook</option>
                                            <option value="fa-x-twitter">X</option>
                                            <option value="fa-youtube">Youtube</option>
                                            <option value="fa-linkedin-in">Linkedin</option>
                                            <option value="fa-pinterest-p">Pinterest</option>
                                            <option value="fa-telegram">Telegram</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-dbe">
                                <button type='submit'>Add</button>
                            </div>
                        </form>
                    </div>

                    <div className="preview-data">
                        <div className="smoicons">
                            <ul>
                                {socialMediaUrls && socialMediaUrls.map((data, index) => (
                                    <li key={index}>
                                        <a href={data?.social_url} target="_blank"><i className={`fa-brands ${data?.social_platform}`}></i></a>
                                        <button className="delete-text" onClick={() => handleSocialMediaDelete(data?.id)}><i className="fa-solid fa-times"></i></button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Htabs;
