import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomerSideBar from "../components/Customer/CustomerSideBar";
import CustomerHeader from "../components/Customer/CustomerHeader";
import CustomerFooter from "../components/Customer/CustomerFooter";

const CustomerLayout = () => {
    console.log('Customer layout')
    const [isSidebarHidden, setIsSidebarHidden] = useState(false);

    const hideSidebar = () => {
        setIsSidebarHidden(true);
    };

    const showSidebar = () => {
        setIsSidebarHidden(false);
    };

    return (
        <>
            <CustomerSideBar hideSidebar={hideSidebar} showSidebar={showSidebar} isSidebarHidden={isSidebarHidden} />
            <ToastContainer />
            <CustomerHeader />
            <Outlet context={{ isSidebarHidden }} />
            <CustomerFooter />
        </>
    )
}
export default CustomerLayout;