import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../config';

const AdminAgent = () => {
    const { country, lang, category, customer_id, agent_id } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState(null);
    const [formData, setFormData] = useState({
        category_id: '',
        logo: null,
        name: '',
        description: '',
        mobile: '',
        email: '',
        location: '',
        languages: '',
        meta_title: '',
        meta_description: '',
        meta_tags: ''
    });
    const [errors, setErrors] = useState({});
    const [previewLogo, setPreviewLogo] = useState(null);
    const logoInputRef = useRef(null);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: fetchAgentRequest, response: agentResponse } = useRequest();
    const { request: addAgentRequest, response: agentAddResponse } = useRequest();
    const { request: updateAgentRequest, response: agentUpdateResponse } = useRequest();

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
        if (agent_id) {
            fetchAgentRequest('GET', `/agent/${agent_id}`)
        }
    }, [category, agent_id]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data);
            setFormData((prevData) => ({ ...prevData, category_id: categoryResponse.data.id }));
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (agentResponse && agentResponse.status === "SUCCESS") {
            setPreviewLogo(cloudFrontUrl + agentResponse?.data?.logo)
            setFormData({
                category_id: agentResponse?.data?.category_id,
                logo: null,
                name: agentResponse?.data?.name,
                description: agentResponse?.data?.description,
                mobile: agentResponse?.data?.mobile,
                email: agentResponse?.data?.email,
                location: agentResponse?.data?.location,
                languages: agentResponse?.data?.languages,
                meta_title: agentResponse?.data?.meta_title,
                meta_description: agentResponse?.data?.meta_description,
                meta_tags: agentResponse?.data?.meta_tags
            })
        }
    }, [agentResponse]);

    useEffect(() => {
        if (agentAddResponse && agentAddResponse.status === "SUCCESS") {
            Toast(true, agentAddResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/customer/${customer_id}`);
        } else if (agentAddResponse) {
            Toast(false, agentAddResponse.message);
        }
    }, [agentAddResponse]);

    useEffect(() => {
        if (agentUpdateResponse && agentUpdateResponse.status === "SUCCESS") {
            Toast(true, agentUpdateResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/agents`);
        } else if (agentUpdateResponse) {
            Toast(false, agentUpdateResponse.message);
        }
    }, [agentUpdateResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'logo') {
            setFormData({ ...formData, logo: files[0] });
            setPreviewLogo(URL.createObjectURL(files[0])); // Set preview of the logo
            logoInputRef.current.value = ''; // Clear file input after setting the logo
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.name) formErrors.name = 'Name is required';
        if (!formData.mobile) formErrors.mobile = 'Mobile number is required';
        if (!formData.email) formErrors.email = 'Email is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        if (formData.category_id) data.append('category_id', formData.category_id);
        if (formData.logo) data.append('logo', formData.logo);
        if (formData.name) data.append('name', formData.name);
        if (formData.description) data.append('description', formData.description);
        if (formData.mobile) data.append('mobile', formData.mobile);
        if (formData.email) data.append('email', formData.email);
        if (formData.location) data.append('location', formData.location);
        if (formData.languages) data.append('languages', formData.languages);
        if (formData.meta_title) data.append('meta_title', formData.meta_title);
        if (formData.meta_description) data.append('meta_description', formData.meta_description);
        if (formData.meta_tags) data.append('meta_tags', formData.meta_tags);

        if (agent_id) {
            updateAgentRequest('PUT', `/agent/${agent_id}`, data)
        } else {
            data.append('user_id', customer_id)
            addAgentRequest('POST', '/agent', data);
        }
    };

    return (
        <div className={`agent-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Directory</a></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Add Agent</li>
                    </ul>
                </div>
                <div className="container-user-contact box-dashboard">
                    <form onSubmit={handleSubmit}>
                        <div className="title-db mb-5">
                            <div className="row">
                                <div className="col-sm-2">
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="logo"
                                            onChange={handleInputChange}
                                            className="form-control"
                                            ref={logoInputRef}
                                        />
                                        {previewLogo && (
                                            <img
                                                src={previewLogo}
                                                alt="Logo Preview"
                                                className="img-circle"
                                            />
                                        )}
                                        <small>Upload Photo</small>
                                    </div>
                                </div>

                                <div className="col-sm-10">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            {errors.name && (
                                                <small className="text-danger">{errors.name}</small>
                                            )}
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/tick.svg" alt="tick" />
                                                <input
                                                    type="text"
                                                    name="name"
                                                    placeholder="Add Agent Name"
                                                    value={formData.name}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            {errors.mobile && (
                                                <small className="text-danger">{errors.mobile}</small>
                                            )}
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/mobile.svg" alt="mobile" />
                                                <input
                                                    type="text"
                                                    name="mobile"
                                                    placeholder="Add Mobile Number"
                                                    value={formData.mobile}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            {errors.email && (
                                                <small className="text-danger">{errors.email}</small>
                                            )}
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/email.svg" alt="email" />
                                                <input
                                                    type="email"
                                                    name="email"
                                                    placeholder="Add Email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/location.svg" alt="location" />
                                                <input
                                                    type="text"
                                                    name="location"
                                                    placeholder="Add Location"
                                                    value={formData.location}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-sm-4">
                                            <div className="field-title-db">
                                                <img src="/assets/images/icons/language.svg" alt="language" />
                                                <input
                                                    type="text"
                                                    name="languages"
                                                    placeholder="Add Language"
                                                    value={formData.languages}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-dashboard">
                            <div className="heading">
                                <h2>Description</h2>
                            </div>
                            <textarea
                                name="description"
                                placeholder="Add Description"
                                value={formData.description}
                                onChange={handleInputChange}
                                className="form-control"
                            ></textarea>
                        </div>
                        <div class="box-dashboard">
                            {/* <button class="edit-text"><i class="fa-solid fa-pencil"></i></button> */}
                            <div class="meta-dbe">
                                <div class="heading">
                                    <h2>SEO</h2>
                                </div>
                                <form>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Meta Title*</label>
                                            <div class="field-meta-dbe">
                                                <input type="text" name='meta_title' value={formData.meta_title} onChange={handleInputChange} placeholder="Meta Title" />
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Meta Tags*</label>
                                            <div class="field-meta-dbe">
                                                <input type="text" name='meta_tags' value={formData.meta_tags} onChange={handleInputChange} placeholder="Meta Keywords" />
                                            </div>
                                        </div>
                                    </div>
                                    <label>Meta Description*</label>
                                    <div class="field-meta-dbe">
                                        <textarea placeholder="Meta Description" name='meta_description' value={formData.meta_description} onChange={handleInputChange}></textarea>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="more-add-form">
                            <button type="submit" className="btn btn-secondary">{agent_id ? 'Update' : 'Save'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminAgent;
