import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Modal/Modal';
import useRequest from '../../../../hooks/useRequest';
import { cloudFrontUrl } from '../../../../config';

const Assets = ({ listingId }) => {
    const [listingAssets, setListingAssets] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [image, setImage] = useState(null);

    const { request: request, response: response } = useRequest();
    const { request: getRequest, response: getResponse } = useRequest();
    const { request: addRequest, response: addResponse } = useRequest();
    const { request: deleteRequest, response: deleteResponse } = useRequest();

    const handleAssetChange = () => {
        const formData = new FormData();
        formData.append('listing_id', listingId);
        formData.append('title', title);
        formData.append('image', image);

        addRequest('POST', `/listing-assets`, formData);
    };

    const handleRemoveAsset = (id) => {
        deleteRequest('DELETE', `/listing-assets/${id}`);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setTitle('');
        setImage(null);
    };

    const handleFetchListingAssets = () => {
        getRequest('GET', `/listing-assets/listing/${listingId}`);
    };

    useEffect(() => {
        if (listingId) {
            handleFetchListingAssets();
        }
    }, [listingId]);

    useEffect(() => {
        if (getResponse && getResponse.status === 'SUCCESS') {
            setListingAssets(getResponse.data);
        }
    }, [getResponse]);

    useEffect(() => {
        if (addResponse && addResponse.status === 'SUCCESS') {
            handleFetchListingAssets();
            handleModalClose();
        }
    }, [addResponse]);

    useEffect(() => {
        if (deleteResponse && deleteResponse.status === 'SUCCESS') {
            handleFetchListingAssets();
        }
    }, [deleteResponse]);

    return (
        <>
            <div className="heading">
                <h2>Downloads</h2>
            </div>
            <div className="add-amenity-dbe">
                <button className="btn-add-adbe" onClick={() => setIsModalOpen(true)}>
                    Add Download <i className="fa-solid fa-plus"></i>
                </button>

                <ul>
                    {listingAssets && listingAssets.map((data, index) => (
                        <li key={index}>
                            <img src={cloudFrontUrl + data?.image} alt={data?.title} /> {data?.title}
                            <button className="delete-text" onClick={() => handleRemoveAsset(data?.id)}>
                                <i className="fa-solid fa-minus"></i>
                            </button>
                        </li>
                    ))}
                </ul>
                <Modal isOpen={isModalOpen} closeModal={handleModalClose} title="Add Asset">
                    <div className="form-group">
                        <label>Title</label>
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <label>Image</label>
                        <input
                            type="file"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                    </div>
                    <button onClick={handleAssetChange}>Save</button>
                </Modal>
            </div>
        </>
    );
};

export default Assets;
