import React, { useEffect, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import Pagination from "../../../../components/Admin/Pagination";
import { Toast } from "../../../../utils/ToastNotification";

const Story = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [stories, setStories] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchStoriesRequest, response: storiesResponse } = useRequest();
    const { request: deleteStoryRequest, response: deleteStoryResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();

    const handleFetchStories = () => {
        if (categoryData) {
            fetchStoriesRequest(
                'GET',
                `/my-story?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchStories();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (storiesResponse && storiesResponse.status === "SUCCESS") {
            setStories(storiesResponse?.data || []);
            setTotalRecords(storiesResponse?.total || 0);
        }
    }, [storiesResponse]);

    useEffect(() => {
        if (deleteStoryResponse && deleteStoryResponse.status === "SUCCESS") {
            Toast(true, deleteStoryResponse?.message);
            handleFetchStories();
        } else if (deleteStoryResponse) {
            Toast(false, deleteStoryResponse?.message);
        }
    }, [deleteStoryResponse]);

    const handleDeleteStory = (id) => {
        deleteStoryRequest('DELETE', `/my-story/${id}`);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    return (
        <div
            className={`stories-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`}
            id="container-dashboard"
        >
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Stories</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>

                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Total<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>View<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {stories.map((story) => (
                                    <tr key={story.id}>
                                        <td>{story.customer?.name || 'N/A'}</td>
                                        <td>{story.listing?.title || 'N/A'}</td>
                                        <td>{story.listing?.google_rating || 'N/A'}</td>
                                        <td>
                                            <div className="btn-toggle">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-toggle"
                                                    checked={story.customer?.is_active}
                                                    onChange={() => {/* Toggle Active State */ }}
                                                />
                                                <div className="txt-toggle"> <span>{story.customer?.is_active ? 'YES' : 'NO'}</span> </div>
                                                <div className="layer-toggle"></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-table">
                                                <a href="#">Edit <i className="fa-solid fa-pencil"></i></a>
                                                <a href="#" onClick={() => handleDeleteStory(story.id)}>Delete <i className="fa-solid fa-trash"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Story;