import React from "react";
import { useParams, Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AuthLayout = () => {
    const { country, lang } = useParams();

    console.log('Auth layout')
    return (
        <>
            <ToastContainer />
            <Outlet />
        </>
    )
}
export default AuthLayout;