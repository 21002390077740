import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Directory = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    return (
        <>
            <div className={`directory-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Directory</a></li>
                            <li>{category}</li>
                        </ul>
                    </div>
                    <div className="box-dashboard">
                        <div className="content-directory">
                            <div className="search-wraper">
                                <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                                    <form className="">
                                        <input type="text" placeholder="Search" />
                                    </form>
                                </div>
                            </div>
                            <ul className="list-directory">
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/stats`}><img src="/assets/images/icons/stats.svg" alt="stats" /> Stats</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/customer-listing`}><img src="/assets/images/icons/user.svg" alt="user" /> Company Listing</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/listing`}><img src="/assets/images/icons/detail.svg" alt="detail" /> Store / Detail Listing</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/agents`}><img src="/assets/images/icons/agent.svg" alt="agent" /> Agent Listing</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/amenities`}><img src="/assets/images/icons/amenities.svg" alt="amenities" /> Amenities</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/landmark`}><img src="/assets/images/icons/location.svg" alt="location" /> Landmark</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/popular-area`}><img src="/assets/images/icons/location-2.svg" alt="location-2" /> Popular Areas</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/faqs`}><img src="/assets/images/icons/about.svg" alt="about" /> FAQs</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/news`}><img src="/assets/images/icons/news.svg" alt="news" /> News & Updates </Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/blog`}><img src="/assets/images/icons/lists.svg" alt="lists" /> Blogs</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/inquiries`}><img src="/assets/images/icons/enquiry-2.svg" alt="enquiry" /> Inquires</Link></li>
                                <li><a href="#"><img src="/assets/images/icons/live.svg" alt="live" /> My Live</a></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/my-story`}><img src="/assets/images/icons/story.svg" alt="story" /> Stories</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/my-reel`}><img src="/assets/images/icons/reels.svg" alt="reels" /> Reels</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/ai-tools`}><img src="/assets/images/icons/ai-tools.svg" alt="ai-tools" /> AI Tools</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel`}><img src="/assets/images/icons/review.svg" alt="review" /> Right-Side Panel</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/banners`}><img src="/assets/images/icons/banner.svg" alt="banner" /> Banner</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/sub-category`}><img src="/assets/images/icons/list.svg" alt="list" /> Sub Categories</Link></li>
                                <li><Link to={`/${country}/${lang}/admin/directory/${category}/transfer-user-profile`}><img src="/assets/images/icons/user.svg" alt="user" /> Transfer User Profile</Link></li>
                                <li></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Directory;
