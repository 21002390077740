import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Doughnut from './Doughnut/Doughnut';
import DashedGraph from './DashedGraph/DashedGraph';
import BarChart from './BarChart/BarChart';
import useRequest from '../../../../hooks/useRequest';

const AdminStats = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [adminStatsData, setAdminStatsData] = useState({});
    const [categoryData, setCategoryData] = useState(null);
    const [visitorStats, setVisitorStats] = useState({});

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: adminStatsRequest, response: adminStatsResponse } = useRequest();

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    const handleFetchAdminStats = () => {
        if (categoryData) {
            adminStatsRequest(
                'GET',
                `/directory/stats/${categoryData.id}`
            );
        }
    };

    useEffect(() => {
        if (categoryData) {
            handleFetchAdminStats();
        }
    }, [categoryData]);


    useEffect(() => {
        if (adminStatsResponse && adminStatsResponse.status === "SUCCESS") {
            setAdminStatsData(adminStatsResponse.data || {});
            setVisitorStats(adminStatsResponse.data?.visitor_stats || {});
        }
    }, [adminStatsResponse]);

    // Helper function to calculate the percentage change
    const calculatePercentageChange = (current = 0, previous = 0) => {
        if (!previous || previous === 0) return "+0%";
        const percentageChange = ((current - previous) / previous) * 100;
        return `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%`;
    };

    // Helper function to add class for positive/negative changes
    const getPercentageClass = (current = 0, previous = 0) => {
        return current >= previous ? "" : "minus-total-count";
    };

    return (
        <>
            <div className={`stats-dashboard container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Directory</a></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li>Stats</li>
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Visitors Stats</h3>
                                    <div className="btn-filter">
                                        <div className="select-btn-filter">
                                            <select>
                                                <option selected="selected">Export</option>
                                            </select>
                                        </div>
                                        <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 11 - Apr 20</a> <a href="#"
                                            className="ci-bf"><img src="/assets/images/icons/db-icon.svg" alt="alt" /></a>
                                    </div>
                                </div>
                                <DashedGraph visitorStats={visitorStats} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>New Listings</h3>
                                    <div className="btn-filter">
                                        <div className="select-btn-filter">
                                            <select>
                                                <option selected="selected">Export</option>
                                            </select>
                                        </div>
                                        <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 11 - Apr 20</a> <a href="#"
                                            className="ci-bf"><img src="/assets/images/icons/db-icon.svg" alt="alt" /></a>
                                    </div>
                                </div>
                                <Doughnut adminStatsData={adminStatsData} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Total Listings</h3>
                                    <div className="btn-filter">
                                        <div className="select-btn-filter">
                                            <select>
                                                <option selected="selected">Export</option>
                                            </select>
                                        </div>
                                        <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 11 - Apr 20</a> <a href="#"
                                            className="ci-bf"><img src="/assets/images/icons/db-icon.svg" alt="alt" /></a>
                                    </div>
                                </div>
                                <BarChart adminStatsData={adminStatsData} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="total-count">
                                <ul>
                                    <li>Total Reviews
                                        <h3>{adminStatsData?.total_reviews?.total_reviews} <small>+70.56%</small></h3>
                                    </li>
                                    <li>Today Inquiries
                                        <h3>{adminStatsData?.total_inquiries?.total_inquiries} <small>+70.56%</small></h3>
                                    </li>
                                    <li>Total Recommendation
                                        <h3>{adminStatsData?.total_recommendations?.total_recommendations} <small>+70.56%</small></h3>
                                    </li>
                                    <li>New Visitors
                                        <h3>{adminStatsData?.visitor_stats?.new_visitors} <small className="minus-total-count">-70.56%</small></h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminStats;
