import { Form, useOutletContext } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Toast } from "../../../utils/ToastNotification";

const ApplyJob = () => {
  const { isSidebarHidden } = useOutletContext();
  const [jobCategory, setJobCategory] = useState([]);
  const FILE_SIZE = 1 * 1024 * 1024;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  const validationSchema = yup.object().shape({
    sub_category_id: yup
      .string()
      .required("Sub category ID is required")
      .min(1, "Sub category ID must be greater than 0"),
    title: yup
      .string()
      .required("Title is required")
      .min(3, "Title must be at least 3 characters"),
    salary: yup.string().required("Salary is required"),
    experience: yup.string().required("Experience is required"),
    experience_required: yup
      .string()
      .required("Experience requirement is required"),
    description: yup
      .string()
      .required("Description is required")
      .min(10, "Description must be at least 10 characters"),
    responsibility: yup.string().required("Responsibility is required"),
    requirement: yup.string().required("Requirement is required"),
    image: yup
      .mixed()
      // .required("Image is required")
      .test("fileSize", "Image size is too large (max 1MB)", (value) => {
        if (value.length > 0) {
          return value && value[0].size <= FILE_SIZE;
        } else {
          return true;
        }
      })
      .test(
        "fileFormat",
        "Unsupported format. Supported formats are jpg, jpeg, and png",
        (value) => {
          if (value.length > 0) {
            return value && SUPPORTED_FORMATS.includes(value[0].type);
          } else {
            return true;
          }
        }
      ),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { request: fetchJobCategory, response: jobCategoryRes } = useRequest();
  const {request : postJob,response : postJobResponse} = useRequest()
  useEffect(() => {
    (async () => {
      await fetchJobCategory("GET", `/sub-categories/4`);
    })();
  }, []);

  useEffect(() => {
    if (jobCategoryRes) {
      setJobCategory(jobCategoryRes.data);
    }
  }, [jobCategoryRes]);


  const submitForm = (data) => {
    const formData = new FormData();
    for (const key in data) {
      const value = data[key];
      if (key === "image") {
        formData.append(key, value[0]);
      } else {
        formData.append(key, value);
      }
    }
    postJob("POST","/jobs",formData);
  };

  useEffect(()=>{
    if(postJobResponse){
      console.log(postJobResponse.data);
      if(postJobResponse.status === 'SUCCESS'){
        Toast(true, postJobResponse?.message);
        reset()
      }
      else{
        Toast(false, postJobResponse?.message)
      }
    }
  },[postJobResponse])

  return (
    <div
      className={`post-job-page container-dashboard  ${
        isSidebarHidden ? "sidebar-dashboard-hidden" : ""
      }`}
      id="container-dashboard"
    >
      <div className="container-fluid">
        <div className="box-dashboard">
          <div className="add-form">
            <form onSubmit={handleSubmit(submitForm)}>
              <div className="row">
                <div className="col-sm-3 mb-3">
                  <label>Select Job Category</label>
                  <div className="field-add-form mb-0">
                    <select {...register("sub_category_id")}>
                      <option value="">Seject Job Cateogry</option>
                      {jobCategory &&
                        jobCategory.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <p className="text-danger">
                    {errors.sub_category_id?.message}
                  </p>
                </div>
                <div className="col-sm-3 mb-3">
                  <label>Title *</label>
                  <div className="field-add-form mb-0">
                    <input type="text" {...register("title")} />
                  </div>
                  <p className="text-danger">{errors.title?.message}</p>
                </div>
                <div className="col-sm-3 mb-3">
                  <label>Upload Image</label>
                  <div className="attached-input mb-0">
                    <input type="file" {...register("image")} />
                    <small>
                      Upload Image / Photo
                      <img
                        src="/assets/images/icons/upload.svg"
                        alt="upload"
                      />
                    </small>
                  </div>
                  <p className="text-danger">{errors.image?.message}</p>
                </div>
                <div className="col-sm-3 mb-3">
                  <label>Salary Range</label>
                  <div className="field-add-form mb-0">
                    <select {...register("salary")}>
                      <option value="">Salary Range</option>
                      <option value="AED 1000 - 2000">
                        AED 1000 - 2000
                      </option>
                      <option value="AED 3000 - 4000">
                        AED 4000 - 4000
                      </option>
                    </select>
                  </div>
                  <p className="text-danger">{errors.salary?.message}</p>
                </div>
                <div className="col-sm-3 mb-3">
                  <label>Job Experience</label>
                  <div className="field-add-form mb-0">
                    <select {...register("experience")}>
                      <option value="">Select Job Experience</option>
                      <option value="1-2 years">1-2 years</option>
                      <option value="3-5 years">3-5 years</option>
                    </select>
                  </div>
                  <p className="text-danger">
                    {errors.experience?.message}
                  </p>
                </div>
                <div className="col-sm-3 mb-3">
                  <label>Experience Required</label>
                  <div className="field-add-form mb-0">
                    <input
                      type="text"
                      {...register("experience_required")}
                    />
                  </div>
                  <p className="text-danger">
                    {errors.experience_required?.message}
                  </p>
                </div>
              </div>

              <div className="col-sm-12 mb-3">
                <label>Responsibility</label>
                <div className="field-add-form mb-0">
                  <textarea
                    {...register("responsibility")}
                    placeholder="Type Responsibility"
                  ></textarea>
                </div>
                <p className="text-danger">
                  {errors.responsibility?.message}
                </p>
              </div>
              <div className="col-sm-12 mb-3">
                <label>Requirement</label>
                <div className="field-add-form mb-0">
                  <textarea
                    {...register("requirement")}
                    placeholder="Type Requirement"
                  ></textarea>
                </div>
                <p className="text-danger">{errors.requirement?.message}</p>
              </div>

              <div className="col-sm-12 mb-3">
                <label>Job Description</label>
                <div className="field-add-form mb-0">
                  <textarea
                    {...register("description")}
                    placeholder="Type Description"
                  ></textarea>
                </div>
                <p className="text-danger">{errors.requirement?.message}</p>
              </div>

              <div className="submit-add-form">
                <input type="submit" value="Save" />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyJob;
