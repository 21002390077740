import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';

const Blog = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [blogs, setBlogs] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: fetchBlogsRequest, response: blogsResponse } = useRequest();
    const { request: deleteBlogRequest, response: deleteBlogResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchBlogs = () => {
        if (categoryData) {
            fetchBlogsRequest(
                'GET',
                `/directory-blog?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchBlogs();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (blogsResponse && blogsResponse.status === "SUCCESS") {
            setBlogs(blogsResponse.data || []);
            setTotalRecords(blogsResponse.total || 0);
        }
    }, [blogsResponse]);

    useEffect(() => {
        if (deleteBlogResponse && deleteBlogResponse.status === "SUCCESS") {
            Toast(true, deleteBlogResponse.message);
            handleFetchBlogs(); // Refresh the list after deletion
        } else if (deleteBlogResponse) {
            Toast(false, deleteBlogResponse.message);
        }
    }, [deleteBlogResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchBlogs(); // Refresh the list after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when changing page size
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0); // Reset to first page when searching
    };

    const handleDelete = (id) => {
        deleteBlogRequest('DELETE', `/directory-blog/${id}`);
    };

    const handleToggleStatus = (id, isActive) => {
        updateStatusRequest('PATCH', `/directory-blog?/${id}/status`, { is_active: !isActive });
    };

    return (
        <>
            <div className={`amenity-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><Link to="#">Directory</Link></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li>Blogs</li>
                        </ul>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/blog/add`} className="btn btn-primary">
                                Add Blog <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db">
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <form>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </form>
                            </div>
                            <div className="btn-filter">
                                {/* <div className="select-btn-filter">
                                    <select>
                                        <option selected="selected">Category</option>
                                        <option value="Property">Property</option>
                                        <option value="Motors">Motors</option>
                                        <option value="Jobs">Jobs</option>
                                    </select>
                                </div>
                                <div className="select-btn-filter">
                                    <select>
                                        <option selected="selected">Subcategory</option>
                                        <option value="Entertainment">Entertainment</option>
                                        <option value="Restaurants">Restaurants</option>
                                        <option value="Rooftops">Rooftops</option>
                                    </select>
                                </div> */}
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>
                                    {blogs.map((blog) => (
                                        <tr key={blog.id}>
                                            <td>{blog.title}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={blog.is_active}
                                                        onChange={() => handleToggleStatus(blog.id, blog.is_active)}
                                                    />
                                                    <div className="txt-toggle"><span>{blog.is_active ? 'YES' : 'NO'}</span></div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/directory/${category}/blog/edit/${blog.id}`}>
                                                        Edit <i className="fa-solid fa-pencil"></i>
                                                    </Link>
                                                    <a href="#" onClick={() => handleDelete(blog.id)}>
                                                        Delete<i className="fa-solid fa-trash"></i>
                                                    </a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Blog;