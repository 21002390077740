import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../config';

const AmenityUpdate = () => {
    const { country, lang, category, amenity_id } = useParams(); // Add amenity_id from params
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categoryData, setCategoryData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        icon: null
    });
    const [previewIcon, setPreviewIcon] = useState(null);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: fetchAmenityRequest, response: amenityResponse } = useRequest();
    const { request: updateAmenityRequest, response: updateAmenityResponse } = useRequest();

    // Fetch category data
    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    // Set category data after fetching
    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data);
        }
    }, [categoryResponse]);

    // Fetch amenity data to populate form for editing
    useEffect(() => {
        if (amenity_id) {
            fetchAmenityRequest('GET', `/amenity/${amenity_id}`);
        }
    }, [amenity_id]);

    // Set form data with fetched amenity data
    useEffect(() => {
        if (amenityResponse && amenityResponse.status === "SUCCESS") {
            setFormData({
                name: amenityResponse.data.name || ''
            });
            setPreviewIcon(cloudFrontUrl + amenityResponse.data.icon); // Assuming API returns icon_url for preview
        }
    }, [amenityResponse]);

    // Handle update response
    useEffect(() => {
        if (updateAmenityResponse && updateAmenityResponse.status === "SUCCESS") {
            Toast(true, updateAmenityResponse.message);
            navigate(`/${country}/${lang}/admin/directory/${category}/amenities`);
        } else if (updateAmenityResponse) {
            Toast(false, updateAmenityResponse.message);
        }
    }, [updateAmenityResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'icon') {
            setFormData({ ...formData, icon: files[0] });
            setPreviewIcon(URL.createObjectURL(files[0])); // Set preview of the icon
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const data = new FormData();

        // Add only fields with values to the FormData object
        if (formData.name) data.append('name', formData.name);
        if (formData.icon) data.append('icon', formData.icon);
        if (categoryData?.id) data.append('category_id', categoryData.id);
        data.append('language', lang);

        updateAmenityRequest('PUT', `/amenity/${amenity_id}`, data);
    };

    return (
        <div className={`add-amenity container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Venues</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/amenities`}>Amenity</Link></li>
                        <li>Update Amenity</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Name</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Amenity Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Icon</label>
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="icon"
                                            onChange={handleInputChange}
                                        />
                                        <small>Upload Icon <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Preview</label>
                                    <div className="preview-add-form">
                                        {previewIcon ? (
                                            <img src={previewIcon} alt="Icon Preview" />
                                        ) : (
                                            <p>No icon selected</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AmenityUpdate;
