import React, { useEffect, useState } from 'react';
import { useParams, useOutletContext, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';

const TransferUserProfile = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [profile, setProfiles] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: fetchProfileRequest, response: profileResponse } = useRequest();
    const { request: deleteProfileRequest, response: deleteProfileResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchProfiles = () => {
        if (categoryData) {
            fetchProfileRequest(
                'GET',
                `/company-profile?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchProfiles();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (profileResponse && profileResponse.status === "SUCCESS") {
            setProfiles(profileResponse.data || []);
            setTotalRecords(profileResponse.total || 0);
        }
    }, [profileResponse]);

    useEffect(() => {
        if (deleteProfileResponse && deleteProfileResponse.status === "SUCCESS") {
            Toast(true, deleteProfileResponse.message);
            handleFetchProfiles();
        } else if (deleteProfileResponse) {
            Toast(false, deleteProfileResponse.message);
        }
    }, [deleteProfileResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchProfiles();
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (e) => {
        setPageSize(e.target.value);
        setCurrentPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0);
    };

    const handleDelete = (id) => {
        deleteProfileRequest('DELETE', `/company-profile/${id}`);
    };

    const handleToggleStatus = (id, isActive) => {
        updateStatusRequest('PATCH', `/company-profile/${id}`, { is_active: !isActive });
    };

    return (
        <>
            <div className={`transfer-profile-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><Link to="#">Directory</Link></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li>Transfer User Profile</li>
                        </ul>
                        <div className="btn-bc">
                            <a href="#" className="btn btn-primary">Transfer <i className="fa-solid fa-plus"></i></a>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </div>
                            <div className="btn-filter">
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Name <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Active <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Action <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>
                                    {/* {profile.map((p) => (
                                        <tr key={p.id}>
                                            <td>{p.name}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={p.is_active}
                                                        onChange={() => handleToggleStatus(p.id, p.is_active)}
                                                    />
                                                    <div className="txt-toggle">
                                                        <span>{p.is_active ? "YES" : "NO"}</span>
                                                    </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="btn-bc">
                                                    <a href="#" className="btn btn-primary">Transfer <i className="fa-solid fa-plus"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))} */}
                                    <tr>
                                        <td>
                                            john
                                        </td>
                                        <td><div class="btn-toggle">
                                            <input type="checkbox" class="checkbox-toggle" />
                                            <div class="txt-toggle"> <span>YES</span> </div>
                                            <div class="layer-toggle"></div>
                                        </div></td>
                                        <td><div className="btn-bc">
                                            <a href="#" className="btn btn-primary">Send Profile<i className="fa-solid fa-plus"></i></a>
                                        </div></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TransferUserProfile;