import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminSideBar from "../components/Admin/AdminSideBar";
import AdminHeader from "../components/Admin/AdminHeader";
import AdminFooter from "../components/Admin/AdminFooter";

const AdminLayout = () => {
    console.log('Admin layout')
    const [isSidebarHidden, setIsSidebarHidden] = useState(false);

    const hideSidebar = () => {
        setIsSidebarHidden(true);
    };

    const showSidebar = () => {
        setIsSidebarHidden(false);
    };

    return (
        <>
            <AdminSideBar hideSidebar={hideSidebar} showSidebar={showSidebar} isSidebarHidden={isSidebarHidden} />
            <ToastContainer />
            <AdminHeader />
            <Outlet context={{ isSidebarHidden }} />
            <AdminFooter />
        </>
    )
}
export default AdminLayout;