import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from "../../../../hooks/useRequest";
import { Toast } from '../../../../utils/ToastNotification';

const AddMasterVideo = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [masterVideo, setMasterVideo] = useState(null); // Holds the existing master video data
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        cover_video_image: null,
        video_url: ''
    });

    const { request: fetchMasterVideoRequest, response: masterVideoResponse } = useRequest();
    const { request: addOrUpdateMasterVideoRequest, response: addOrUpdateMasterVideoResponse } = useRequest();

    // Fetch existing master video on component load
    useEffect(() => {
        fetchMasterVideoRequest('GET', '/cms-master-video');
    }, []);

    // Set form data if master video exists
    useEffect(() => {
        if (masterVideoResponse && masterVideoResponse.status === "SUCCESS" && masterVideoResponse.data) {
            const videoData = masterVideoResponse.data; // Access the object directly
            setMasterVideo(videoData);
            setFormData({
                cover_video_image: videoData.cover_video_image || null,
                video_url: videoData.video_url || ''
            });
        }
    }, [masterVideoResponse]);

    // Handle submission response
    useEffect(() => {
        if (addOrUpdateMasterVideoResponse && addOrUpdateMasterVideoResponse.status === "SUCCESS") {
            Toast(true, addOrUpdateMasterVideoResponse.message);
        } else if (addOrUpdateMasterVideoResponse) {
            Toast(false, addOrUpdateMasterVideoResponse.message);
        }
    }, [addOrUpdateMasterVideoResponse]);

    const handleInputChange = (e) => {
        const { name, value, type } = e.target;
        setFormData({ ...formData, [name]: type === 'file' ? e.target.files[0] : value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.cover_video_image && !masterVideo?.cover_video_image) {
            formErrors.cover_video_image = 'Cover video image is required';
        }
        if (!formData.video_url) {
            formErrors.video_url = 'Video URL is required';
        }
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('cover_video_image', formData.cover_video_image);
        data.append('video_url', formData.video_url);

        // If masterVideo exists, perform an update; otherwise, create a new entry
        if (masterVideo) {
            await addOrUpdateMasterVideoRequest('PUT', `/cms-master-video/${masterVideo.id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } else {
            await addOrUpdateMasterVideoRequest('POST', '/cms-master-video', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
    };

    return (
        <div className={`add-video container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li><Link to={`/${country}/${lang}/admin/how-it-work`}>How It Work?</Link></li>
                        <li>Master Video</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <label>Upload Video Cover</label>
                            {errors.cover_video_image && <p className="error">{errors.cover_video_image}</p>}
                            {masterVideo?.cover_video_image && (
                                <div className="existing-cover">
                                    <img src={masterVideo.cover_video_image} alt="cover-image" style={{ width: '100px', marginBottom: '10px' }} />
                                    <p>Upload Cover Image</p>
                                </div>
                            )}
                            <div className="attached-input">
                                <input type="file" name="cover_video_image" onChange={handleInputChange} />
                                <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                            </div>
                            <label>Add Video URL</label>
                            {errors.video_url && <p className="error">{errors.video_url}</p>}
                            <div className="field-add-form">
                                <input type="text" name="video_url" value={formData.video_url} onChange={handleInputChange} placeholder="Add Video URL" />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddMasterVideo;