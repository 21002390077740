import React, { useEffect, useState, useRef } from 'react';
import { useParams, useOutletContext, useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import Pagination from '../../../components/Admin/Pagination';
import { cloudFrontUrl } from '../../../config';

const Listings = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [customers, setCustomers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('')
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: request, response: response } = useRequest()
    const { request: updateRequest, response: updateResponse } = useRequest()

    const handleEdit = (id) => {
        navigate(`/${country}/${lang}/customer/listings/update/${id}`);
    }

    const handleDelete = (id) => {
        updateRequest('DELETE', `/listing/${id}`)
    }

    const handleUpdate = (id, type, value) => {
        let formData = new FormData()

        if(type === "active"){
            formData.append('is_active', value)
        }

        if(type === "featured"){
            formData.append('is_featured', value)
        }

        updateRequest('PUT', `/listing/${id}`, formData)
    }

    // Search input change handler
    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        fetchCustomers(query); // Fetch customers whenever search input changes
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when page size changes
    };

    const fetchCustomers = (search = '') => {
        request('GET', `/listing?page_num=${currentPage}&page_size=${pageSize}&search=${search}`)
    }

    useEffect(() => {
        fetchCustomers()
    }, []);

    useEffect(() => {
        if (response && response.status === "SUCCESS") {
            setCustomers(response.data)
            setTotalRecords(response.total);
        }
    }, [response]);

    useEffect(() => {
        if (updateResponse && updateResponse.status === "SUCCESS") {
            fetchCustomers()
            Toast(true, updateResponse?.message)
        } else {
            Toast(false, updateResponse?.message)
        }
    }, [updateResponse]);

    return (
        <>
            <div className={`profile-listing-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/customer/listings/add`} className="btn btn-primary"> Add Listing <i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                                <form className="">
                                    <input type="text" name="search" value={searchQuery} onChange={handleSearchChange} placeholder="Search" />
                                </form>
                            </div>
                            
                            <div className="btn-filter"> <a href="#"><img src="/assets/images/icons/filter.svg" alt="filter" /> Filters</a> <a
                                href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a> <a href="#">
                                    <img src="/assets/images/icons/download.svg" alt="Download" /> Download</a> </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Image<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        {/* <th>Store<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Agent<img src="/assets/images/icons/sort.svg" alt="sort" /></th> */}
                                        <th>Featured<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>

                                    {customers && customers.map((data, index) => (
                                        <>
                                            <tr key={index}>
                                                <td><img src={data?.logo ? `${cloudFrontUrl}${data?.logo}` : "/assets/images/logo-1.png"} alt="logo" /></td>
                                                <td>{data?.title.charAt(0).toUpperCase() + data?.title.slice(1)}</td>
                                                {/* <td>{data.stores}</td>
                                                <td>{data.agents}</td> */}
                                                <td>
                                                    <div className="btn-toggle">
                                                        <input type="checkbox" className="checkbox-toggle" checked={!data.is_featured} onChange={(e)=>handleUpdate(data.id, 'featured', !data.is_featured)} />
                                                        <div className="txt-toggle"> <span>YES</span> </div>
                                                        <div className="layer-toggle"></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="btn-toggle">
                                                        <input type="checkbox" className="checkbox-toggle" checked={!data.is_active} onChange={(e)=>handleUpdate(data.id, 'active', !data.is_active)} />
                                                        <div className="txt-toggle"> <span>YES</span> </div>
                                                        <div className="layer-toggle"></div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="action-table">
                                                        <a href='javascript:void(0);' onClick={() => handleEdit(data.id)}>Edit <i className="fa-solid fa-pencil"></i></a>
                                                        <a href='javascript:void(0);' onClick={() => handleDelete(data.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        {/* Rest of your component HTML */}
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Listings;
