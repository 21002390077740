import React, { useState, useEffect } from 'react';
import { useParams, useOutletContext, Link } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';

const AddListing = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();

    // State for each section
    const [videosFormData, setVideosFormData] = useState({ video_url: '' });
    const [registerFormData, setRegisterFormData] = useState({ headline: '', description: '', button_url: '', image: null });
    const [advertisementBannerFormData, setAdvertisementBannerFormData] = useState({ image: null, button_url: '' });
    const [horizontalBannerFormData, setHorizontalBannerFormData] = useState({ headline: '', description: '', button_url: '', image: null });
    const [newsFormData, setNewsFormData] = useState({ headline: '', description: '', image: null });

    // State to store data from API
    const [attractionVideos, setAttractionVideos] = useState([]);
    const [attractionRegister, setAttractionRegister] = useState([]);
    const [advertisementBanners, setAdvertisementBanners] = useState([]);
    const [horizontalBanners, setHorizontalBanners] = useState([]);
    const [attractionNews, setAttractionNews] = useState([]);

    // Request hooks
    const { request: fetchAttractionVideos, response: attractionVideosResponse } = useRequest();
    const { request: postAttractionVideo, response: postAttractionVideoResponse } = useRequest();
    const { request: deleteAttractionVideo, response: deleteAttractionVideoResponse } = useRequest();

    const { request: fetchAttractionRegister, response: attractionRegisterResponse } = useRequest();
    const { request: postAttractionRegister, response: postAttractionRegisterResponse } = useRequest();
    const { request: deleteAttractionRegister, response: deleteAttractionRegisterResponse } = useRequest();

    const { request: fetchAdvertisementBanners, response: advertisementBannersResponse } = useRequest();
    const { request: postAdvertisementBanner, response: postAdvertisementBannerResponse } = useRequest();
    const { request: deleteAdvertisementBanner, response: deleteAdvertisementBannerResponse } = useRequest();

    const { request: fetchHorizontalBanners, response: horizontalBannersResponse } = useRequest();
    const { request: postHorizontalBanner, response: postHorizontalBannerResponse } = useRequest();
    const { request: deleteHorizontalBanner, response: deleteHorizontalBannerResponse } = useRequest();

    const { request: fetchAttractionNews, response: attractionNewsResponse } = useRequest();
    const { request: postAttractionNews, response: postAttractionNewsResponse } = useRequest();
    const { request: deleteAttractionNews, response: deleteAttractionNewsResponse } = useRequest();

    // Fetch data on mount
    useEffect(() => {
        fetchAttractionVideos('GET', '/attraction-videos');
        fetchAttractionRegister('GET', '/attraction-register');
        fetchAdvertisementBanners('GET', '/attraction-advertisement-banner');
        fetchHorizontalBanners('GET', '/attraction-horizontal-banner');
        fetchAttractionNews('GET', '/attraction-news');
    }, []);

    // Update state with fetched data
    useEffect(() => {
        if (attractionVideosResponse?.status === 'SUCCESS') setAttractionVideos(attractionVideosResponse.data);
        if (attractionRegisterResponse?.status === 'SUCCESS') setAttractionRegister(attractionRegisterResponse.data);
        if (advertisementBannersResponse?.status === 'SUCCESS') setAdvertisementBanners(advertisementBannersResponse.data);
        if (horizontalBannersResponse?.status === 'SUCCESS') setHorizontalBanners(horizontalBannersResponse.data);
        if (attractionNewsResponse?.status === 'SUCCESS') setAttractionNews(attractionNewsResponse.data);
    }, [
        attractionVideosResponse,
        attractionRegisterResponse,
        advertisementBannersResponse,
        horizontalBannersResponse,
        attractionNewsResponse
    ]);

    // Handle input change
    const handleInputChange = (e, setFormData) => {
        const { name, value, files } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: name === 'image' ? files[0] : value
        }));
    };

    // Handle form submission
    const handleSubmit = async (e, endpoint, formData, postRequest) => {
        e.preventDefault();
        const data = new FormData();
        Object.keys(formData).forEach(key => {
            if (formData[key]) data.append(key, formData[key]);
        });

        try {
            await postRequest('POST', endpoint, data, { headers: { 'Content-Type': 'multipart/form-data' } });
            Toast(true, 'Item created successfully');
            // Fetch updated data after submission
            switch (endpoint) {
                case '/attraction-videos':
                    fetchAttractionVideos('GET', endpoint);
                    break;
                case '/attraction-register':
                    fetchAttractionRegister('GET', endpoint);
                    break;
                case '/attraction-advertisement-banner':
                    fetchAdvertisementBanners('GET', endpoint);
                    break;
                case '/attraction-horizontal-banner':
                    fetchHorizontalBanners('GET', endpoint);
                    break;
                case '/attraction-news':
                    fetchAttractionNews('GET', endpoint);
                    break;
                default:
                    break;
            }
        } catch (error) {
            Toast(false, 'Failed to create item. Please try again.');
        }
    };

    // Handle deletion
    const handleDelete = async (id, endpoint, deleteRequest) => {
        if (window.confirm('Are you sure you want to delete this item?')) {
            try {
                await deleteRequest('DELETE', `${endpoint}/${id}`);
                Toast(true, 'Item deleted successfully');
                // Fetch updated data after deletion
                switch (endpoint) {
                    case '/attraction-videos':
                        fetchAttractionVideos('GET', endpoint);
                        break;
                    case '/attraction-register':
                        fetchAttractionRegister('GET', endpoint);
                        break;
                    case '/attraction-advertisement-banner':
                        fetchAdvertisementBanners('GET', endpoint);
                        break;
                    case '/attraction-horizontal-banner':
                        fetchHorizontalBanners('GET', endpoint);
                        break;
                    case '/attraction-news':
                        fetchAttractionNews('GET', endpoint);
                        break;
                    default:
                        break;
                }
            } catch (error) {
                Toast(false, 'Failed to delete item. Please try again.');
            }
        }
    };

    return (
        <div className={`add-listing-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>Dashboard</li>
                        <li><Link to={`/${country}/${lang}/admin/attraction`}>Attraction</Link></li>
                        <li>Add Listing Page</li>
                    </ul>
                </div>

                {/* Attraction Videos Section */}
                <h3>Right Side Panel</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-videos', videosFormData, postAttractionVideo)}>
                            <label>Video URL</label>
                            <div className="field-add-form">
                                <input type="text" name="video_url" value={videosFormData.video_url} onChange={(e) => handleInputChange(e, setVideosFormData)} placeholder="Video URL" />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {attractionVideos.map(video => (
                            <li key={video.id}>
                                <h5>{video.video_url}</h5>
                                <button onClick={() => handleDelete(video.id, '/attraction-videos', deleteAttractionVideo)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Register Now Section */}
                <h3>Register Now</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-register', registerFormData, postAttractionRegister)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Icon / Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setRegisterFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" value={registerFormData.headline} onChange={(e) => handleInputChange(e, setRegisterFormData)} placeholder="Type Headline
                                        " />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Description</label>
                                    <div className="field-add-form">
                                        <input type="text" name="description" value={registerFormData.description} onChange={(e) => handleInputChange(e, setRegisterFormData)} placeholder="Type Description" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Button URL</label>
                                    <div className="field-add-form">
                                        <input type="text" name="button_url" value={registerFormData.button_url} onChange={(e) => handleInputChange(e, setRegisterFormData)} placeholder="Add URL" />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {attractionRegister.map(register => (
                            <li key={register.id}>
                                <h5>{register.headline}</h5>
                                <p>{register.description}</p>
                                <img src={cloudFrontUrl + register.image} alt={register.headline} style={{ width: '100px', height: 'auto' }} />
                                <a href={register.button_url} target="_blank" rel="noopener noreferrer">Button URL</a>
                                <button onClick={() => handleDelete(register.id, '/attraction-register', deleteAttractionRegister)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Advertisement Banner Section */}
                <h3>Advertisement Banner</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-advertisement-banner', advertisementBannerFormData, postAdvertisementBanner)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Icon / Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setAdvertisementBannerFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Button URL</label>
                                    <div className="field-add-form">
                                        <input type="text" name="button_url" value={advertisementBannerFormData.button_url} onChange={(e) => handleInputChange(e, setAdvertisementBannerFormData)} placeholder="Add URL" />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {advertisementBanners.map(banner => (
                            <li key={banner.id}>
                                <img src={cloudFrontUrl + banner.image} alt="Advertisement Banner" style={{ width: '100px', height: 'auto' }} />
                                <a href={banner.button_url} target="_blank" rel="noopener noreferrer">Button URL</a>
                                <button onClick={() => handleDelete(banner.id, '/attraction-advertisement-banner', deleteAdvertisementBanner)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Horizontal Banner Section */}
                <h3>Horizontal Banner</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-horizontal-banner', horizontalBannerFormData, postHorizontalBanner)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Icon / Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setHorizontalBannerFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" value={horizontalBannerFormData.headline} onChange={(e) => handleInputChange(e, setHorizontalBannerFormData)} placeholder="Type Headline" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Description</label>
                                    <div className="field-add-form">
                                        <input type="text" name="description" value={horizontalBannerFormData.description} onChange={(e) => handleInputChange(e, setHorizontalBannerFormData)} placeholder="Type Description" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Link</label>
                                    <div className="field-add-form">
                                        <input type="text" name="button_url" value={horizontalBannerFormData.button_url} onChange={(e) => handleInputChange(e, setHorizontalBannerFormData)} placeholder="Add URL" />
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {horizontalBanners.map(banner => (
                            <li key={banner.id}>
                                <h5>{banner.headline}</h5>
                                <p>{banner.description}</p>
                                <img src={cloudFrontUrl + banner.image} alt="Horizontal Banner" style={{ width: '100px', height: 'auto' }} />
                                <a href={banner.button_url} target="_blank" rel="noopener noreferrer">Link</a>
                                <button onClick={() => handleDelete(banner.id, '/attraction-horizontal-banner', deleteHorizontalBanner)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* News & Updates Section */}
                <h3>News & Updates</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={(e) => handleSubmit(e, '/attraction-news', newsFormData, postAttractionNews)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Icon / Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="image" onChange={(e) => handleInputChange(e, setNewsFormData)} />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="headline" value={newsFormData.headline} onChange={(e) => handleInputChange(e, setNewsFormData)} placeholder="Type Headline" />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea name="description" value={newsFormData.description} onChange={(e) => handleInputChange(e, setNewsFormData)} placeholder="Type Description"></textarea>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {attractionNews.map(news => (
                            <li key={news.id}>
                                <h5>{news.headline}</h5>
                                <p>{news.description}</p>
                                <img src={cloudFrontUrl + news.image} alt="News & Updates" style={{ width: '100px', height: 'auto' }} />
                                <button onClick={() => handleDelete(news.id, '/attraction-news', deleteAttractionNews)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AddListing;
