import React from 'react';

const CustomerFooter = () => {

    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <p>&copy; 2024 Copyright myfinder.ai / All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default CustomerFooter;
