import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const Advertisement = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        category_id: '',
        advertisement_headline: '',
        advertisement_description: '',
        advertisement_image: null
    });
    const [advertisement, setAdvertisement] = useState(null); // Holds the existing master video data
    const [categoryData, setCategoryData] = useState(null);

    const { request: fetchAdvertisementRequest, response: advertisementResponse } = useRequest();
    const { request: addOrUpdateAdvertisementRequest, response: advertisementAddOrUpdateResponse } = useRequest();
    const { request: categoryRequest, response: categoryResponse } = useRequest();

    useEffect(() => {
        if (category) {
            categoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data?.id);
        }
    }, [categoryResponse]);

    useEffect(() => {
        fetchAdvertisementRequest('GET', '/right_side_advertisement');
    }, []);

    useEffect(() => {
        if (advertisementResponse && advertisementResponse.status === "SUCCESS") {
            const { category_id, advertisement_headline, advertisement_description, advertisement_image } = advertisementResponse.data;
            setAdvertisement(advertisementResponse.data);
            setFormData({
                category_id,
                advertisement_headline,
                advertisement_description,
                advertisement_image
            });
        }
    }, [advertisementResponse]);

    useEffect(() => {
        if (advertisementAddOrUpdateResponse) {
            if (advertisementAddOrUpdateResponse.status === "SUCCESS") {
                Toast(true, advertisementAddOrUpdateResponse.message);
                navigate(`/${country}/${lang}/admin/directory/${category}/right-side-panel`);
            } else {
                Toast(false, advertisementAddOrUpdateResponse.message);
            }
        }
    }, [advertisementAddOrUpdateResponse]);

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'file' ? files[0] : value
        });
    };

    const validateInputs = () => {
        const errors = {};
        if (!formData.advertisement_headline) errors.advertisement_headline = 'Headline is required';
        if (!formData.advertisement_description) errors.advertisement_description = 'Description is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateInputs();
        if (Object.keys(errors).length > 0) {
            return;
        }

        const data = new FormData();
        data.append('advertisement_headline', formData.advertisement_headline);
        data.append('advertisement_description', formData.advertisement_description);
        if (formData.advertisement_image) {
            data.append('advertisement_image', formData.advertisement_image);
        }
        if (categoryData) {
            data.append('category_id', categoryData);
        }

        if (advertisement) {
            await addOrUpdateAdvertisementRequest('PUT', `/right_side_advertisement/${advertisement.id}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } else {
            await addOrUpdateAdvertisementRequest('POST', '/right_side_advertisement', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
    };

    return (
        <div className={`add-side-panel container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}/right-side-panel`}>Right Side Panel</Link></li>
                        <li>Advertisement</li>
                    </ul>
                </div>
                <h3>Advertisement</h3>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input type="file" name="advertisement_image" onChange={handleInputChange} />
                                        <small>Upload <img src="images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input type="text" name="advertisement_headline" value={formData.advertisement_headline} onChange={handleInputChange} placeholder="Type Headline" />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Call to Action Button</label>
                                    <div className="field-add-form">
                                        <input type="text" placeholder="Click Here" />
                                    </div>
                                </div>
                            </div>
                            <label>Description</label>
                            <div className="field-add-form">
                                <textarea name="advertisement_description" value={formData.advertisement_description} onChange={handleInputChange} placeholder="Type Description"></textarea>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Advertisement;