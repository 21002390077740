import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../config';

const Announcement = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        url: '',
        image: null,
    });
    const [announcements, setAnnouncements] = useState([]);
    const [errors, setErrors] = useState({});

    const { request: fetchAnnouncementRequest, response: announcementResponse } = useRequest();
    const { request: addAnnouncementRequest, response: addAnnouncementResponse } = useRequest();
    const { request: deleteAnnouncementRequest, response: announcementDeleteResponse } = useRequest();

    useEffect(() => {
        fetchAnnouncementRequest('GET', '/announcements/latest');
    }, []);

    useEffect(() => {
        if (announcementResponse && announcementResponse.status === "SUCCESS") {
            setAnnouncements([announcementResponse.data]);
        }
    }, [announcementResponse]);

    useEffect(() => {
        if (addAnnouncementResponse) {
            if (addAnnouncementResponse.status === "SUCCESS") {
                Toast(true, addAnnouncementResponse.message);
                navigate(`/${country}/${lang}/admin/announcement`);
            } else {
                Toast(false, addAnnouncementResponse.message);
            }
        }
    }, [addAnnouncementResponse, country, lang, navigate]);

    useEffect(() => {
        if (announcementDeleteResponse) {
            if (announcementDeleteResponse.status === "SUCCESS") {
                Toast(true, announcementDeleteResponse.message);
                fetchAnnouncementRequest('GET', '/announcements/latest');
            } else {
                Toast(false, announcementDeleteResponse.message);
            }
        }
    }, [announcementDeleteResponse, fetchAnnouncementRequest]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'image' ? files[0] : value
        });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.url) formErrors.url = 'URL is required';
        if (!formData.image) formErrors.image = 'Image is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('url', formData.url);
        if (formData.image) {
            data.append('image', formData.image);
        }

        try {
            await addAnnouncementRequest('POST', '/announcements', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        } catch (error) {
            Toast(false, 'Failed to add announcement. Please try again.');
        }
    };

    // const handleDelete = async (announcementId) => {
    //     if (window.confirm('Are you sure you want to delete this announcement?')) {
    //         try {
    //             await deleteAnnouncementRequest('DELETE', `/announcements/${announcementId}`);
    //         } catch (error) {
    //             Toast(false, 'Failed to delete announcement. Please try again.');
    //         }
    //     }
    // };

    return (
        <div className={`add-announcement container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Dashboard</Link></li>
                        <li>Announcement</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Image / Photo</label>
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={handleInputChange}
                                        />
                                        <small>Upload <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                        {errors.image && <p className="error">{errors.image}</p>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Click Here Button</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="url"
                                            placeholder="URL"
                                            value={formData.url}
                                            onChange={handleInputChange}
                                        />
                                        {errors.url && <p className="error">{errors.url}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <h4>Preview</h4>
                {announcements.map(announcement => (
                    <div class="box-dashboard announcement-preview">
                        <div key={announcement.id} className="announcement-add-form">
                            <img src={cloudFrontUrl + announcement.image} className="announcement-image-preview" alt="announcement" />
                        </div>
                        {/* <p><strong>URL:</strong> <a href={announcement.url}>{announcement.url}</a></p>
                        <button
                            className="btn btn-danger"
                            onClick={() => handleDelete(announcement.id)}
                        >
                            Delete Announcement
                        </button> */}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Announcement;