import React, { useEffect, useState, useRef } from 'react';
import { useParams, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../hooks/useRequest';
import { Toast } from '../../utils/ToastNotification';
import { cloudFrontUrl } from '../../config';

const Dashboard = () => {
    const { country, language } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    return (
        <>
            <div className={`stats-dashboard container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Visitors Stats</h3>
                                </div>
                                <img src="/assets/images/graph/1.1.jpg" alt="alt" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Country Stats</h3>
                                </div>
                                <img src="/assets/images/graph/2.2.jpg" alt="alt" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Traffic by Device</h3>
                                </div>
                                <img src="/assets/images/graph/3.3.jpg" alt="alt" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="total-count">
                                <ul>
                                    <li>Total Inquiries
                                        <h3>5.2K <small>+70.56%</small></h3>
                                    </li>
                                    <li>Today Inquiries
                                        <h3>5.2K <small>+70.56%</small></h3>
                                    </li>
                                    <li>New Visitors
                                        <h3>5.2K <small>+70.56%</small></h3>
                                    </li>
                                    <li>Repeat Visitors
                                        <h3>5.2K <small className="minus-total-count">-70.56%</small></h3>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="heading-graph-db">
                            <h3>Recent Enquiries</h3>
                            {/*<div className="btn-filter"> <a href="#">Today</a> <a href="#">Last Week </a> <a href="#">Last Month</a> <a
                                href="#">Custom</a> </div>*/}
                        </div>
                        <img src="/assets/images/graph/4.4.jpg" alt="alt" className="img-fluid" />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
