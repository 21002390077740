import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import Pagination from "../../../components/Admin/Pagination";

const Recommendation = () => {
    const { isSidebarHidden } = useOutletContext();

    const [recommendations, setRecommendations] = useState([]);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);

    const { request: fetchRecommendationsRequest, response: recommendationsResponse } = useRequest();
    const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
    const { request: fetchSubCategoriesRequest, response: subCategoriesResponse } = useRequest();

    const handleFetchRecommendations = () => {
        fetchRecommendationsRequest(
            'GET',
            `/recommendations?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
        );
    };

    useEffect(() => {
        handleFetchRecommendations();
    }, [currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (recommendationsResponse && recommendationsResponse.status === "SUCCESS") {
            setRecommendations(recommendationsResponse?.data || []);
            setTotalRecords(recommendationsResponse?.total || 0);
        }
    }, [recommendationsResponse]);

    // Fetch categories on component mount
    useEffect(() => {
        fetchCategoriesRequest('GET', '/category');
    }, []);

    // Set categories data after fetching
    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategories(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    // Fetch subcategories when selectedCategoryId changes
    useEffect(() => {
        if (selectedCategoryId) {
            fetchSubCategoriesRequest('GET', `/sub-categories/${selectedCategoryId}`);
        }
    }, [selectedCategoryId]);

    // Set subcategories data after fetching
    useEffect(() => {
        if (subCategoriesResponse && subCategoriesResponse.status === "SUCCESS") {
            setSubCategories(subCategoriesResponse.data || []);
        }
    }, [subCategoriesResponse]);

    const getContributor = (recommendation) => {
        return recommendation.user_id ? `User ${recommendation.user_id}` : "Anonymous";
    };

    const getRank = () => {
        return "N/A";  // Customize this based on your logic
    };

    const getActiveStatus = (recommendation) => {
        return recommendation.is_active ? "YES" : "NO";
    };

    const handleCategoryChange = (e) => {
        setSelectedCategoryId(e.target.value);
    };

    return (
        <div className={`recommendation-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>Review</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <div className="select-btn-filter">
                                <select onChange={handleCategoryChange}>
                                    <option value="">Category</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="select-btn-filter">
                                <select>
                                    <option value="">Subcategory</option>
                                    {subCategories.map((subCategory) => (
                                        <option key={subCategory.id} value={subCategory.id}>
                                            {subCategory.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Profile Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Contributor<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Email<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Phone<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Rank<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {recommendations.map((recommendation) => (
                                    <tr key={recommendation.id}>
                                        <td>{recommendation.name}</td>
                                        <td>{getContributor(recommendation)}</td>
                                        <td>{recommendation.email}</td>
                                        <td>{recommendation.phone}</td>
                                        <td><div className="rank-table">{getRank()}</div></td>
                                        <td>
                                            <div className="btn-toggle">
                                                <input type="checkbox" className="checkbox-toggle" checked={recommendation.is_active} />
                                                <div className="txt-toggle">
                                                    <span>{getActiveStatus(recommendation)}</span>
                                                </div>
                                                <div className="layer-toggle"></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-table">
                                                <a href="#">Edit <i className="fa-solid fa-pencil"></i></a>
                                                <a href="#">Delete<i className="fa-solid fa-trash"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <Pagination
                            currentPage={currentPage}
                            pageSize={pageSize}
                            totalRecords={totalRecords}
                            onPageChange={setCurrentPage}
                            onPageSizeChange={setPageSize}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recommendation;