import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';

const AdminPopularArea = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [popularAreas, setPopularAreas] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: getPopularAreasRequest, response: getPopularAreasResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: deletePopularAreaRequest, response: deletePopularAreaResponse } = useRequest();

    const handleFetchPopularArea = () => {
        if (categoryData) {
            getPopularAreasRequest(
                'GET',
                `/popular-areas?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchPopularArea();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {

        if (getPopularAreasResponse?.status === 'SUCCESS') {
            console.log("popular area", popularAreas)
            setPopularAreas(getPopularAreasResponse.data || []);
            setTotalRecords(getPopularAreasResponse.total || 0);
        } else if (getPopularAreasResponse?.status === 'ERROR') {
            Toast(false, getPopularAreasResponse.message);
        }
    }, [getPopularAreasResponse]);

    useEffect(() => {
        if (deletePopularAreaResponse && deletePopularAreaResponse.status === "SUCCESS") {
            Toast(true, deletePopularAreaResponse?.message);
            handleFetchPopularArea();
        } else if (deletePopularAreaResponse) {
            Toast(false, deletePopularAreaResponse?.message);
        }
    }, [deletePopularAreaResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0);
    };

    const handleDeletePopularArea = (id) => {
        deletePopularAreaRequest('DELETE', `/popular-areas/${id}`);
    };

    return (
        <div className={`popular-area-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Popular Area</li>
                    </ul>
                    <div className="btn-bc">
                        <Link to={`/${country}/${lang}/admin/directory/${category}/popular-area/add`} className="btn btn-primary">
                            Add Popular Area <i className="fa-solid fa-plus"></i>
                        </Link>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                            <form className="">
                                <input type="text" name="search" value={searchQuery} onChange={handleSearchChange} placeholder="Search" />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>State<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>City<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Country<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {popularAreas?.length > 0 ? (
                                    popularAreas.map((area) => (
                                        <tr key={area.id}>
                                            <td>{area.location_name}</td>
                                            <td>{area.state}</td>
                                            <td>{area.city}</td>
                                            <td>{area.country}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input type="checkbox" className="checkbox-toggle" checked={area.isActive} readOnly />
                                                    <div className="txt-toggle"><span>{area.isActive ? 'YES' : 'NO'}</span></div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/directory/${category}/popular-area/edit/${area.id}`}>
                                                        Edit <i className="fa-solid fa-pencil"></i>
                                                    </Link>
                                                    <a href="#" onClick={() => handleDeletePopularArea(area.id)}>
                                                        Delete <i className="fa-solid fa-trash"></i>
                                                    </a>                                                         </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="6">No popular areas found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default AdminPopularArea;
