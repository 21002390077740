import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../config';

const AdminAmenities = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [amenities, setAmenities] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: fetchAmenitiesRequest, response: amenitiesResponse } = useRequest();
    const { request: deleteAmenityRequest, response: deleteAmenityResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchAmenities = () => {
        if (categoryData) {
            fetchAmenitiesRequest(
                'GET',
                `/amenities?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        // Fetch category data using the category slug from the URL parameters
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        // Set category data after fetching and trigger fetching amenities
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        // Fetch amenities after category data is set
        if (categoryData) {
            handleFetchAmenities();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (amenitiesResponse && amenitiesResponse.status === "SUCCESS") {
            setAmenities(amenitiesResponse.data || []);
            setTotalRecords(amenitiesResponse.total || 0);
        }
    }, [amenitiesResponse]);

    useEffect(() => {
        if (deleteAmenityResponse && deleteAmenityResponse.status === "SUCCESS") {
            Toast(true, deleteAmenityResponse.message);
            handleFetchAmenities(); // Refresh the list after deletion
        } else if (deleteAmenityResponse) {
            Toast(false, deleteAmenityResponse.message);
        }
    }, [deleteAmenityResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchAmenities(); // Refresh the list after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when changing page size
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0); // Reset to first page when searching
    };

    const handleDelete = (id) => {
        deleteAmenityRequest('DELETE', `/amenity/${id}`);
    };

    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        const formData = new FormData();
        formData.append('is_active', updatedStatus);
        updateStatusRequest('PUT', `/amenity/${id}`, formData);
    };

    return (
        <>
            <div className={`amenity-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><Link to="#">Directory</Link></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li>Amenity</li>
                        </ul>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/amenities/add`} className="btn btn-primary">
                                Add Amenity <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db"><i className="fa-solid fa-magnifying-glass"></i>
                                <form className="">
                                    <input type="text" name="search" value={searchQuery} onChange={handleSearchChange} placeholder="Search" />
                                </form>
                            </div>
                            <div className="btn-filter">
                                <a href="#"><img src="/assets/images/icons/filter.svg" alt="filter" /> Filters</a>
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Icon<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>
                                    {amenities.map((data, index) => (
                                        <tr key={index}>
                                            <td><img src={cloudFrontUrl + data.icon || '/assets/images/default-icon.png'} alt="logo" /></td>
                                            <td>{data.name}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={!data.is_active}
                                                        onChange={() => handleToggleStatus(data.id, data.is_active)}
                                                    />
                                                    <div class="txt-toggle"> <span>YES</span> </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/directory/${category}/amenities/update/${data?.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                                                    <a href="javascript:void(0);" onClick={() => handleDelete(data.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminAmenities;
