import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import Pagination from '../../../components/Admin/Pagination';
import { Toast } from '../../../utils/ToastNotification';

const Blog = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [blogs, setBlogs] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: subCategoriesRequest, response: subCategoriesResponse } = useRequest();
    const { request: fetchBlogsRequest, response: blogsResponse } = useRequest();
    const { request: deleteBlogRequest, response: deleteBlogResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchBlogs = () => {
        fetchBlogsRequest(
            'GET',
            `/directory-blog?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
        );
    };

    useEffect(() => {
        handleFetchBlogs();
    }, [currentPage, pageSize, searchQuery]);

    useEffect(() => {
        fetchCategoryRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (selectedCategory) {
            subCategoriesRequest(
                'GET',
                `/public/sub-category/${selectedCategory}`
            );
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (subCategoriesResponse && subCategoriesResponse.status === "SUCCESS") {
            setSubCategories(subCategoriesResponse.data || []);
        }
    }, [subCategoriesResponse]);

    useEffect(() => {
        if (blogsResponse && blogsResponse.status === "SUCCESS") {
            setBlogs(blogsResponse.data || []);
            setTotalRecords(blogsResponse.total || 0);
        }
    }, [blogsResponse]);

    useEffect(() => {
        if (deleteBlogResponse && deleteBlogResponse.status === "SUCCESS") {
            Toast(true, deleteBlogResponse.message);
            handleFetchBlogs();
        } else if (deleteBlogResponse) {
            Toast(false, deleteBlogResponse.message);
        }
    }, [deleteBlogResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchBlogs();
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handleCategoryChange = (event) => {
        setSelectedCategory(event.target.value);
    };

    const filteredSubCategories = subCategories;

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0);
    };

    const handleDelete = (id) => {
        deleteBlogRequest('DELETE', `/directory-blog/${id}`);
    };

    const handleToggleStatus = (id, isActive) => {
        updateStatusRequest('PUT', `/directory-blog/${id}`, { is_active: !isActive });
    };

    return (
        <div className={`blog-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to={`/${country}/${lang}/admin`}>Dashboard</Link></li>
                        <li>Blogs</li>
                    </ul>
                    <div className="btn-bc">
                        <Link to={`/${country}/${lang}/admin/blog/add`} className="btn btn-primary">
                            Add Blog <i className="fa-solid fa-plus"></i>
                        </Link>
                    </div>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <div class="select-btn-filter">
                                <select onChange={handleCategoryChange} value={selectedCategory}>
                                    <option value="">Category</option>
                                    {categoryData && categoryData.map(category => (
                                        <option key={category.id} value={category.id}>{category.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div class="select-btn-filter">
                                <select>
                                    <option value="">Subcategory</option>
                                    {filteredSubCategories && filteredSubCategories.map(subCat => (
                                        <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                                    ))}
                                </select>
                            </div>
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {blogs.map((blog) => (
                                    <tr key={blog.id}>
                                        <td>{blog.title}</td>
                                        <td>
                                            <div className="btn-toggle">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-toggle"
                                                    checked={blog.is_active}
                                                    onChange={() => handleToggleStatus(blog.id, blog.is_active)}
                                                />
                                                <div className="txt-toggle"><span>{blog.is_active ? 'YES' : 'NO'}</span></div>
                                                <div className="layer-toggle"></div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="action-table">
                                                <Link to={`/${country}/${lang}/admin/blog/edit/${blog.id}`}>
                                                    Edit <i className="fa-solid fa-pencil"></i>
                                                </Link>
                                                <a href="#" onClick={() => handleDelete(blog.id)}>
                                                    Delete<i className="fa-solid fa-trash"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Blog;