import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { Logout } from '../../utils/Logout';
import UserLogo from '../UserLogo';

const AdminHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { country, lang } = useParams();
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
    const submenuRef = useRef(null);

    const toggleSubmenu = () => {
        setIsSubmenuOpen(prevState => !prevState);
    };

    const handleLogout = async () => {
        await Logout(navigate);
    };

    const handleClickOutside = (event) => {
        if (submenuRef.current && !submenuRef.current.contains(event.target)) {
            setIsSubmenuOpen(prevState => !prevState);
        }
    };

    useEffect(() => {
        if (isSubmenuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isSubmenuOpen]);

    // Close submenu when route changes
    useEffect(() => {
        setIsSubmenuOpen(false);
    }, [location]);

    return (
        <>
            <header>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <ul className="menubar">
                                <li><a href="#"><img src="/assets/images/icons/language-2.svg" alt="language" /> Global</a></li>
                                <li className="notification-header"><a onClick={() => false}><img src="/assets/images/icons/bell.svg"
                                    alt="bell" /> <small>1</small></a></li>
                                <Link to={`/${country}/${lang}/admin/profile`}>
                                    <UserLogo />
                                </Link>
                                <li ref={submenuRef}>
                                    <a className="btn-submenu" onClick={toggleSubmenu}>
                                        <img src="/assets/images/icons/burger.svg" alt="burger" className={`icon-submenu ${isSubmenuOpen ? 'icon-submenu-hide' : ''}`} />
                                        <i className={`fa-solid fa-xmark close-submenu ${isSubmenuOpen ? 'close-submenu-show' : ''}`}></i>
                                    </a>
                                    <div className="submenu" style={{ display: isSubmenuOpen ? 'block' : 'none' }}>
                                        <ul>
                                            <li><Link to={`/${country}/${lang}/admin/profile`}>Profile</Link></li>
                                            {/* <li><a href="javascript:void(0);">Change Password</a></li> */}
                                            <li><a href="javascript:void(0);" onClick={handleLogout}>Sign Out</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default AdminHeader;
