import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddFaqS = () => {
    const { country, lang, faqId } = useParams(); // Get faqId from the URL for edit mode
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [formData, setFormData] = useState({
        category_id: '',
        sub_category_id: '',
        question: '',
        answer: '',
    });
    const [errors, setErrors] = useState({});
    const isEditMode = Boolean(faqId);

    const { request: fetchCategoriesRequest, response: categoriesResponse } = useRequest();
    const { request: fetchSubCategoriesRequest, response: subCategoriesResponse } = useRequest();
    const { request: addFaqRequest, response: addFaqResponse } = useRequest();
    const { request: fetchFaqRequest, response: faqResponse } = useRequest();
    const { request: updateFaqRequest, response: updateFaqResponse } = useRequest();

    useEffect(() => {
        fetchCategoriesRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoriesResponse && categoriesResponse.status === "SUCCESS") {
            setCategories(categoriesResponse.data || []);
        }
    }, [categoriesResponse]);

    // If in edit mode, fetch the FAQ details
    useEffect(() => {
        if (isEditMode) {
            fetchFaqRequest('GET', `/cms-faq/${faqId}`); // Fetch existing FAQ data
        }
    }, [isEditMode, faqId]);

    useEffect(() => {
        if (faqResponse && faqResponse.status === "SUCCESS" && isEditMode) {

            setFormData({
                category_id: faqResponse.data.category_id || '',
                sub_category_id: faqResponse.data.sub_category_id || '',
                question: faqResponse.data.question || '',
                answer: faqResponse.data.answer || '',
            });
        }
    }, [faqResponse, isEditMode]);

    useEffect(() => {
        if (formData.category_id) {
            fetchSubCategoriesRequest('GET', `/sub-categories/${formData.category_id}`);
        }
    }, [formData.category_id]);

    useEffect(() => {
        if (subCategoriesResponse && subCategoriesResponse.status === "SUCCESS") {
            setSubCategories(subCategoriesResponse.data || []);
        }
    }, [subCategoriesResponse]);

    useEffect(() => {
        if (addFaqResponse && addFaqResponse.status === "SUCCESS") {
            Toast(true, addFaqResponse.message);
            navigate(`/${country}/${lang}/admin/cms/faqs`);
        } else if (addFaqResponse) {
            Toast(false, addFaqResponse.message);
        }
    }, [addFaqResponse]);

    useEffect(() => {
        if (updateFaqResponse && updateFaqResponse.status === "SUCCESS") {
            Toast(true, updateFaqResponse.message);
            navigate(`/${country}/${lang}/admin/cms/faqs`);
        } else if (updateFaqResponse) {
            Toast(false, updateFaqResponse.message);
        }
    }, [updateFaqResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.question) formErrors.question = 'Question is required';
        if (!formData.answer) formErrors.answer = 'Answer is required';
        if (!formData.category_id) formErrors.category_id = 'Category is required';
        if (!formData.sub_category_id) formErrors.sub_category_id = 'Subcategory is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = {
            category_id: formData.category_id,
            sub_category_id: formData.sub_category_id,
            question: formData.question,
            answer: formData.answer,
        };

        if (isEditMode) {
            await updateFaqRequest('PUT', `/cms-faq/${faqId}`, data);
        } else {
            await addFaqRequest('POST', '/cms-faq', data);
        }
    };

    return (
        <div className={`add-faq container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li><Link to={`/${country}/${lang}/admin/cms/faqs`}>FAQs</Link></li>
                        <li>{isEditMode ? 'Edit FAQ' : 'Add FAQ'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Category</label>
                                    {errors.category_id && <p className="error">{errors.category_id}</p>}
                                    <div className="field-add-form">
                                        <select
                                            name="category_id"
                                            value={formData.category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Category</option>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>
                                                    {category.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Subcategory</label>
                                    {errors.sub_category_id && <p className="error">{errors.sub_category_id}</p>}
                                    <div className="field-add-form">
                                        <select
                                            name="sub_category_id"
                                            value={formData.sub_category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select SubCategory</option>
                                            {subCategories.map(subCategory => (
                                                <option key={subCategory.id} value={subCategory.id}>
                                                    {subCategory.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <label>Question</label>
                            {errors.question && <p className="error">{errors.question}</p>}
                            <div className="field-add-form">
                                <input
                                    type="text"
                                    placeholder="Enter question"
                                    name="question"
                                    value={formData.question}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <label>Answer</label>
                            {errors.answer && <p className="error">{errors.answer}</p>}
                            <div className="field-add-form">
                                <input
                                    type="text"
                                    placeholder="Enter answer"
                                    name="answer"
                                    value={formData.answer}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={isEditMode ? "Update" : "Save"} />
                            </div>
                        </form>
                    </div>
                </div>
                {isEditMode ? null : (
                    <div className="more-add-form">
                        <a href="#" className="btn btn-primary">Add More <i className="fa-solid fa-plus"></i></a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AddFaqS;