import React, { useEffect, useState } from "react";
import { useParams, Link, useOutletContext } from "react-router-dom";
import useRequest from "../../../../hooks/useRequest";
import Pagination from "../../../../components/Admin/Pagination";
import { Toast } from "../../../../utils/ToastNotification";

const AiTools = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();

    const [reels, setReels] = useState([]);
    const [categoryData, setCategoryData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchReelsRequest, response: reelsResponse } = useRequest();
    const { request: deleteReelRequest, response: deleteReelResponse } = useRequest();
    const { request: fetchCategoryRequest, response: categoryResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchReels = () => {
        if (categoryData) {
            fetchReelsRequest(
                'GET',
                `/ai-tools?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            fetchCategoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data || {});
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchReels();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (reelsResponse && reelsResponse.status === "SUCCESS") {
            setReels(reelsResponse?.data || []);
            setTotalRecords(reelsResponse?.total || 0);
        }
    }, [reelsResponse]);

    useEffect(() => {
        if (deleteReelResponse && deleteReelResponse.status === "SUCCESS") {
            Toast(true, deleteReelResponse?.message);
            handleFetchReels();
        } else if (deleteReelResponse) {
            Toast(false, deleteReelResponse?.message);
        }
    }, [deleteReelResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchReels();
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handleDeleteReel = (id) => {
        deleteReelRequest('DELETE', `/ai-tools/${id}`);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        const formData = new FormData();
        formData.append('is_active', updatedStatus);
        updateStatusRequest('PUT', `/ai-tools/${id}`, formData);
    };

    return (
        <div className={`aitool-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Directory</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>AI Tools</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/filter.svg" alt="filter" /> Filters</a>
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>AI Tools<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {reels.length > 0 ? (
                                    reels.map((reel) => (
                                        <tr key={reel.id}>
                                            <td>{reel.name}</td>
                                            <td>
                                                <div className="aitool-table">
                                                    {reel.icon && reel.icon.split(',').map((icon, index) => (
                                                        <img key={index} src={`images/icons/${icon}.svg`} alt={icon} />
                                                    ))}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={reel.is_active}
                                                        onChange={() => handleToggleStatus(reel.id, reel.is_active)}
                                                    />
                                                    <div className="txt-toggle">
                                                        <span>{reel.is_active ? 'YES' : 'NO'}</span>
                                                    </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/edit-ai-tool/${reel.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                                                    <a href="#" onClick={() => handleDeleteReel(reel.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="4">No data available</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        pageSize={pageSize}
                        totalRecords={totalRecords}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default AiTools;