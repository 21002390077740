import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DoughnutChart from './DougnutChart/DoughnutChart';
import DashedLine from './DashedGraph.jsx/DashedLine';
import BarChart from './BarChart/BarChart';
import useRequest from '../../../hooks/useRequest';
import { cloudFrontUrl } from '../../../config';


const AdminDashboard = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const [adminStatsData, setAdminStatsData] = useState({});
    const [visitorStats, setVisitorStats] = useState({});
    const [categoriesStats, setCategoriesStats] = useState([]);
    const [recentListingStats, setRecentListingStats] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;

    const { request: adminStatsRequest, response: adminStatsResponse } = useRequest();

    useEffect(() => {
        adminStatsRequest("Get", "/admin/stats");
    }, []);

    useEffect(() => {
        if (adminStatsResponse && adminStatsResponse.status === "SUCCESS") {
            setAdminStatsData(adminStatsResponse.data || {});
            setVisitorStats(adminStatsResponse.data?.visitor_stats || {});
            setCategoriesStats(adminStatsResponse.data?.categories || {});

            const allRecentListings = adminStatsResponse.data?.categories?.flatMap(category => category.recent_listings) || [];
            setRecentListingStats(allRecentListings);
        }
    }, [adminStatsResponse]);

    const indexOfLastListing = currentPage * itemsPerPage;
    const indexOfFirstListing = indexOfLastListing - itemsPerPage;
    const currentListings = recentListingStats.slice(indexOfFirstListing, indexOfLastListing);
    const totalPages = Math.ceil(recentListingStats.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        console.log("Changing to page:", pageNumber);
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        console.log("Current Page:", currentPage);
        console.log("Total Pages:", totalPages);
        console.log("Current Listings:", currentListings);
    }, [currentPage, totalPages, currentListings]);

    // Helper function to calculate the percentage change
    const calculatePercentageChange = (current = 0, previous = 0) => {
        if (!previous || previous === 0) return "+0%";
        const percentageChange = ((current - previous) / previous) * 100;
        return `${percentageChange > 0 ? '+' : ''}${percentageChange.toFixed(2)}%`;
    };

    // Helper function to add class for positive/negative changes
    const getPercentageClass = (current = 0, previous = 0) => {
        return current >= previous ? "" : "minus-total-count";
    };

    return (
        <>
            <div className={`stats-dashboard container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Visitors Stats</h3>
                                    <div className="btn-filter">
                                        <div className="select-btn-filter">
                                            <select>
                                                <option selected="selected">Export</option>
                                            </select>
                                        </div>
                                        <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 11 - Apr 20</a> <a href="#"
                                            className="ci-bf"><img src="/assets/images/icons/db-icon.svg" alt="alt" /></a>
                                    </div>
                                </div>
                                <DashedLine visitorStats={visitorStats} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>New Listings</h3>
                                    <div className="btn-filter">
                                        <div className="select-btn-filter">
                                            <select>
                                                <option selected="selected">Export</option>
                                            </select>
                                        </div>
                                        <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 11 - Apr 20</a> <a href="#"
                                            className="ci-bf"><img src="/assets/images/icons/db-icon.svg" alt="alt" /></a>
                                    </div>
                                </div>
                                <div>
                                    <DoughnutChart categoriesStats={categoriesStats} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="box-dashboard">
                                <div className="heading-graph-db">
                                    <h3>Total Listings</h3>
                                    <div className="btn-filter">
                                        <div className="select-btn-filter">
                                            <select>
                                                <option selected="selected">Export</option>
                                            </select>
                                        </div>
                                        <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 11 - Apr 20</a> <a href="#"
                                            className="ci-bf"><img src="/assets/images/icons/db-icon.svg" alt="alt" /></a>
                                    </div>
                                </div>
                                <BarChart categoriesStats={categoriesStats} />
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="total-count">
                                <ul>
                                    <li>Company Profile Count
                                        <h3>{adminStatsData.categories?.[0]?.company_profile_count?.company_profile_count || 0} <small>+70.56%</small></h3>
                                    </li>
                                    <li>Today Inquiries
                                        <h3>{adminStatsData.categories?.[0]?.inquiries || 0} <small>+70.56%</small></h3>
                                    </li>
                                    <li>New Visitors
                                        <h3>
                                            {adminStatsData.visitor_stats?.[0]?.new_visitors?.current_week || 0}
                                            <small>
                                                {calculatePercentageChange(
                                                    adminStatsData.visitor_stats?.[0]?.new_visitors?.current_week,
                                                    adminStatsData.visitor_stats?.[0]?.new_visitors?.previous_week
                                                )}
                                            </small>
                                        </h3>
                                    </li>
                                    <li>Repeat Visitors
                                        <h3>
                                            {adminStatsData.visitor_stats?.[0]?.repeat_visitors?.current_week || 0}
                                            <small className={getPercentageClass(
                                                adminStatsData.visitor_stats?.[0]?.repeat_visitors?.current_week,
                                                adminStatsData.visitor_stats?.[0]?.repeat_visitors?.previous_week
                                            )}>
                                                {calculatePercentageChange(
                                                    adminStatsData.visitor_stats?.[0]?.repeat_visitors?.current_week,
                                                    adminStatsData.visitor_stats?.[0]?.repeat_visitors?.previous_week
                                                )}
                                            </small>
                                        </h3>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="heading-graph-db">
                            <h3>Recent Listings</h3>
                            <h3>Views</h3>
                        </div>
                        <div className="all-recent-listing">
                            {currentListings && currentListings.map((listing, index) => {
                                return (
                                    <div className="recent-listing" key={index}>
                                        <div className="flex-recent-listing">
                                            <img src={cloudFrontUrl + listing.logo} alt="alt" />
                                            <div className="info-recent-listing">
                                                <p>Event Added by <a href="#">Selfies chicken</a></p>
                                                <h3>{listing.title}</h3>
                                                <small>{listing.created_at}</small>
                                            </div>
                                        </div>
                                        <h4>{listing.total_visitors}</h4>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="pagination-container right-pagination">
                            <div className="info-pagination">
                                <div className="control-pagination">
                                    {[...Array(totalPages)].map((_, index) => (
                                        <button
                                            key={index + 1}
                                            className={currentPage === index + 1 ? 'active' : ''}
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminDashboard;
