import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = ({ adminStatsData }) => {

    const labels = ['Venues'];
    const dataValues = [adminStatsData?.new_listings?.venues || 0];

    const data = {
        labels: labels, // Labels for the chart (in this case, just 'Venues')
        datasets: [
            {
                label: '# of New Listings',
                data: dataValues,
                backgroundColor: [
                    '#0000CD',
                ],
                borderColor: [
                    '#FFFFFF',
                ],
                borderWidth: 5,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'right',
                labels: {
                    usePointStyle: true,
                    boxWidth: 10,
                },
            },
            tooltip: {
                callbacks: {
                    label: (tooltipItem) => {
                        const label = tooltipItem.label || ''; // Get the label (category name)
                        const value = tooltipItem.raw || 0; // Get the data value (number of listings)
                        return `${label}: ${value}`; // Format the tooltip text
                    },
                },
            },
        },
        cutout: '50%', // Controls the size of the center hole in the doughnut chart
    };

    return (
        <div style={{ width: '400px', height: '400px' }}>
            <Doughnut data={data} options={options} />
        </div>
    );
};

export default DoughnutChart;