import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';

const AddAboutUs = () => {
    const { country, lang, aboutUsId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        headline: '',
        image: null,
        content: '',
    });
    const [errors, setErrors] = useState({});
    const isEditMode = Boolean(aboutUsId);

    const { request: fetchAboutUsRequest, response: aboutUsResponse } = useRequest();
    const { request: addAboutUsRequest, response: addAboutUsResponse } = useRequest();
    const { request: updateAboutUsRequest, response: updateAboutUsResponse } = useRequest();

    useEffect(() => {
        if (isEditMode) {
            fetchAboutUsRequest('GET', `/cms-about-us/${aboutUsId}`);
        }
    }, [isEditMode, aboutUsId]);

    useEffect(() => {
        if (aboutUsResponse && aboutUsResponse.status === "SUCCESS" && isEditMode) {

            setFormData({
                headline: aboutUsResponse.data.headline || '',
                image: aboutUsResponse.image,
                content: aboutUsResponse.data.content || '',
            });
        }
    }, [aboutUsResponse, isEditMode]);

    useEffect(() => {
        if (addAboutUsResponse && addAboutUsResponse.status === "SUCCESS") {
            Toast(true, addAboutUsResponse.message);
            navigate(`/${country}/${lang}/admin/cms/about-us`);
        } else if (addAboutUsResponse) {
            Toast(false, addAboutUsResponse.message);
        }
    }, [addAboutUsResponse]);

    useEffect(() => {
        if (updateAboutUsResponse && updateAboutUsResponse.status === "SUCCESS") {
            Toast(true, updateAboutUsResponse.message);
            navigate(`/${country}/${lang}/admin/cms/about-us`);
        } else if (updateAboutUsResponse) {
            Toast(false, updateAboutUsResponse.message);
        }
    }, [updateAboutUsResponse]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const validateInputs = () => {
        let formErrors = {};
        if (!formData.headline) formErrors.headline = 'Headline is required';
        if (!formData.content) formErrors.content = 'content is required';
        if (!isEditMode && !formData.image) formErrors.image = 'Image is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('headline', formData.headline);
        data.append('content', formData.content);
        data.append('image', formData.image);

        if (isEditMode) {
            await updateAboutUsRequest('PUT', `/cms-about-us/${aboutUsId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        } else {
            await addAboutUsRequest('POST', '/cms-about-us', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }
    };

    return (
        <div className={`add-about-us container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li>CMS Pages</li>
                        <li><Link to={`/${country}/${lang}/admin/cms/about-us`}>About Us</Link></li>
                        <li>{isEditMode ? 'Update About Us' : 'Add About Us'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    {errors.headline && <p className="error">{errors.headline}</p>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            placeholder="Type Headline"
                                            name="headline"
                                            value={formData.headline}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    {errors.image && <p className="error">{errors.image}</p>}
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                        />
                                        <small>Upload Image / Photo <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                    </div>
                                </div>
                            </div>
                            <label>Body Copy</label>
                            {errors.content && <p className="error">{errors.content}</p>}
                            <div className="field-add-form">
                                <textarea
                                    placeholder="Type here..."
                                    name="content"
                                    value={formData.content}
                                    onChange={handleInputChange}
                                ></textarea>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value={isEditMode ? "Update" : "Save"} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAboutUs;