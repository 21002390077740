import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import { Toast } from '../../../../utils/ToastNotification';
import { cloudFrontUrl } from '../../../../config';

const Banner = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        headline: '',
        category_id: '',
        upload_image: null,
    });
    const [categoryData, setCategoryData] = useState(null);
    const [banners, setBanners] = useState([]);
    const [errors, setErrors] = useState({});

    const { request: fetchBannersRequest, response: bannersResponse } = useRequest();
    const { request: addBannerRequest, response: bannerAddResponse } = useRequest();
    const { request: deleteBannerRequest, response: bannerDeleteResponse } = useRequest();
    const { request: categoryRequest, response: categoryResponse } = useRequest();

    // Fetch categories on mount or category change
    useEffect(() => {
        if (category) {
            categoryRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse.data);
        }
    }, [categoryResponse]);

    // Fetch banners on mount
    useEffect(() => {
        fetchBannersRequest('GET', '/banners');
    }, []);

    useEffect(() => {
        if (bannersResponse && bannersResponse.status === "SUCCESS") {
            setBanners(bannersResponse.data);
        }
    }, [bannersResponse]);

    useEffect(() => {
        if (bannerAddResponse) {
            if (bannerAddResponse.status === "SUCCESS") {
                Toast(true, bannerAddResponse.message);
                fetchBannersRequest('GET', '/banners'); // Refresh banner list
                navigate(`/${country}/${lang}/admin/directory/${category}/banners`);
            } else {
                Toast(false, bannerAddResponse.message);
            }
        }
    }, [bannerAddResponse]);

    useEffect(() => {
        if (bannerDeleteResponse) {
            if (bannerDeleteResponse.status === "SUCCESS") {
                Toast(true, bannerDeleteResponse.message);
                fetchBannersRequest('GET', '/banners'); // Refresh banner list
            } else {
                Toast(false, bannerDeleteResponse.message);
            }
        }
    }, [bannerDeleteResponse]);

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            [name]: name === 'upload_image' ? files[0] : value
        });
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        const formErrors = {};
        if (!formData.headline) formErrors.headline = 'Headline is required';
        return formErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('headline', formData.headline);
        if (formData.upload_image) {
            data.append('upload_image', formData.upload_image);
        }
        if (categoryData) {
            data.append('category_id', categoryData.id);
        }

        try {
            await addBannerRequest('POST', '/banners', data, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
        } catch (error) {
            Toast(false, 'Failed to add banner. Please try again.');
        }
    };

    const handleDelete = async (bannerId) => {
        if (window.confirm('Are you sure you want to delete this banner?')) {
            try {
                await deleteBannerRequest('DELETE', `/banners/${bannerId}`);
            } catch (error) {
                Toast(false, 'Failed to delete banner. Please try again.');
            }
        }
    };

    return (
        <div className={`add-banner container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to="#">Dashboard</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                        <li>Add Banner</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Upload Image</label>
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="upload_image"
                                            onChange={handleInputChange}
                                        />
                                        <small>Upload <img src="/assets/images/icons/upload.svg" alt="upload" /></small>
                                        {errors.upload_image && <p className="error">{errors.upload_image}</p>}
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Headline</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="headline"
                                            placeholder="Type Headline"
                                            value={formData.headline}
                                            onChange={handleInputChange}
                                        />
                                        {errors.headline && <p className="error">{errors.headline}</p>}
                                    </div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
                <div className="preview-data">
                    <ul>
                        {banners.map(banner => (
                            <li key={banner.id}>
                                <img src={cloudFrontUrl + banner?.upload_image} alt="Preview" />
                                <button
                                    className="delete-text"
                                    onClick={() => handleDelete(banner.id)}
                                >
                                    <i className="fa-solid fa-times"></i>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Banner;
