import React, { useState, useEffect } from 'react';
import { useParams, Link, useOutletContext, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';

const AddBlog = () => {
    const { country, lang, blogId } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        content: '',
        category_id: '',
        sub_category_id: '',
        thumbnail_image: null,
        feature_image: null,
        is_active: true,
        seo_url: '',
        is_popular: false,
        is_featured: false,
        meta_title: '',
        meta_tags: "",
        tags: '',
        meta_keywords: '',
        meta_description: ''
    });
    const [categoryData, setCategoryData] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [errors, setErrors] = useState({});
    const [selectedCategory, setSelectedCategory] = useState('');

    const { request: fetchBlogRequest, response: blogResponse } = useRequest();
    const { request: addOrUpdateBlogRequest, response: blogAddOrUpdateResponse } = useRequest();
    const { request: categoryRequest, response: categoryResponse } = useRequest();
    const { request: subCategoryRequest, response: subCategoryResponse } = useRequest();

    useEffect(() => {
        categoryRequest('GET', '/category');
    }, []);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse?.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (selectedCategory) {
            subCategoryRequest('GET', `/public/sub-category/${selectedCategory}`);
        }
    }, [selectedCategory]);

    useEffect(() => {
        if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
            setSubCategories(subCategoryResponse?.data || []);
        }
    }, [subCategoryResponse]);


    useEffect(() => {
        if (blogId) {
            fetchBlogRequest('GET', `/directory-blog/${blogId}`);
        }
    }, [blogId]);

    useEffect(() => {
        if (blogResponse && blogResponse.status === "SUCCESS") {
            const {
                title,
                category_id,
                sub_category_id,
                content,
                feature_image,
                thumbnail_image,
                seo_url,
                is_popular,
                is_featured,
                meta_title,
                tags,
                meta_keywords,
                meta_description,
                is_active
            } = blogResponse.data;

            setFormData({
                title,
                content,
                category_id,
                sub_category_id,
                feature_image,
                seo_url,
                is_popular,
                is_featured,
                meta_title,
                thumbnail_image,
                tags,
                meta_keywords,
                meta_description,
                is_active
            });
            setSelectedCategory(category_id);
        }
    }, [blogResponse]);

    useEffect(() => {
        if (blogAddOrUpdateResponse && blogAddOrUpdateResponse.status === "SUCCESS") {
            Toast(true, blogAddOrUpdateResponse.message);
            navigate(`/${country}/${lang}/admin/blog`);
        } else if (blogAddOrUpdateResponse) {
            Toast(false, blogAddOrUpdateResponse.message);
        }
    }, [blogAddOrUpdateResponse]);

    // Handle category change
    const handleCategoryChange = (event) => {
        const categoryId = event.target.value;
        setFormData({ ...formData, category_id: categoryId });
        setSelectedCategory(categoryId);
    };

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'feature_image') {
            setFormData({ ...formData, feature_image: files.length > 0 ? files[0] : null });
        } else if (name === 'thumbnail_image') {
            setFormData({ ...formData, thumbnail_image: files.length > 0 ? files[0] : null });
        } else {
            setFormData({ ...formData, [name]: value });
        }
        setErrors({ ...errors, [name]: '' });
    };

    const validateInputs = () => {
        const formErrors = {};
        // if (!formData.category_id) formErrors.category_id = 'Category is required';
        // if (!formData.sub_category_id) formErrors.sub_category_id = 'Sub-category is required';
        if (!formData.title) formErrors.title = 'Title is required';
        if (!formData.content) formErrors.content = 'Content is required';
        if (!formData.feature_image && !blogId) formErrors.feature_image = 'Feature image is required';
        return formErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formErrors = validateInputs();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        const data = new FormData();
        data.append('title', formData.title);
        data.append('content', formData.content);
        data.append('feature_image', formData.feature_image);
        data.append('seo_url', formData.seo_url);
        data.append('is_popular', formData.is_popular);
        data.append('is_featured', formData.is_featured);
        data.append('is_active', formData.is_active);
        data.append('meta_title', formData.meta_title);
        data.append('meta_tags', formData.meta_tags);
        data.append('tags', formData.tags);
        data.append('meta_keywords', formData.meta_keywords);
        data.append('meta_description', formData.meta_description);
        data.append('category_id', formData.category_id);
        data.append('sub_category_id', formData.sub_category_id);

        if (blogId) {
            addOrUpdateBlogRequest('PUT', `/directory-blog/${blogId}`, data, 'multipart/form-data');
        } else {
            addOrUpdateBlogRequest('POST', '/directory-blog', data, 'multipart/form-data');
        }
    };


    return (
        <div className={`add-blog container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><Link to={`/${country}/${lang}/admin/dashboard`}>Dashboard</Link></li>
                        <li><Link to={`/${country}/${lang}/admin/blog`}>Blogs</Link></li>
                        <li>{blogId ? 'Edit Blog' : 'Add Blog'}</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="add-form">
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Select Category</label>
                                    {errors.category_id && <span className="error">{errors.category_id}</span>}
                                    <div className="field-add-form">
                                        <select
                                            name="category_id"
                                            value={formData.category_id}
                                            onChange={handleCategoryChange}
                                        >
                                            <option value="">Select Category</option>
                                            {categoryData && categoryData.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Title</label>
                                    {errors.title && <span className="error">{errors.title}</span>}
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="title"
                                            placeholder="Blog Title"
                                            value={formData.title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Select Sub Category</label>
                                    {errors.sub_category_id && <span className="error">{errors.sub_category_id}</span>}
                                    <div className="field-add-form">
                                        <select
                                            name="sub_category_id"
                                            value={formData.sub_category_id}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select Sub-Category</option>
                                            {subCategories && subCategories.map(subCat => (
                                                <option key={subCat.id} value={subCat.id}>{subCat.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>SEO URL</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="seo_url"
                                            placeholder="Enter SEO URL"
                                            value={formData.seo_url}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Is Popular?</label>
                                    <div className="field-add-form">
                                        <select
                                            name="is_popular"
                                            value={formData.is_popular}
                                            onChange={handleInputChange}
                                        >
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Is Featured?</label>
                                    <div className="field-add-form">
                                        <select
                                            name="is_featured"
                                            value={formData.is_featured}
                                            onChange={handleInputChange}
                                        >
                                            <option value={false}>No</option>
                                            <option value={true}>Yes</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <label>Meta Title</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="meta_title"
                                            placeholder="Meta Title"
                                            value={formData.meta_title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Tags</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="tags"
                                            placeholder="Tags"
                                            value={formData.tags}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <label>Meta Keywords</label>
                                    <div className="field-add-form">
                                        <input
                                            type="text"
                                            name="meta_keywords"
                                            placeholder="Meta Keywords"
                                            value={formData.meta_keywords}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Meta Description</label>
                                    <div className="field-add-form">
                                        <textarea
                                            name="meta_description"
                                            placeholder="Meta Description"
                                            value={formData.meta_description}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Content</label>
                                    <div className="field-add-form">
                                        <textarea
                                            name="content"
                                            placeholder="Content..."
                                            value={formData.content}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-6">
                                    <label>Thumbnail/Profile Image</label>
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="thumbnail_image"
                                            accept="image/*"
                                            onChange={handleInputChange}
                                        />
                                        <small>Please choose a file to upload <img src="/assets/images/icons/upload.svg" alt="upload" /></small></div>
                                </div>
                                <div className="col-sm-6">
                                    <label>Featured Image</label>
                                    {errors.feature_image && <span className="error">{errors.feature_image}</span>}
                                    <div className="attached-input">
                                        <input
                                            type="file"
                                            name="feature_image"
                                            accept="image/*"
                                            onChange={handleInputChange}
                                        />
                                        <small>Please choose a file to upload <img src="/assets/images/icons/upload.svg" alt="upload" /></small></div>
                                </div>
                            </div>
                            <div className="submit-add-form">
                                <input type="submit" value="Save Changes" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddBlog;