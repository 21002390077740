import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ categoriesStats }) => {

    const categories = categoriesStats.map(category => category.name);
    const dataValues = categoriesStats.map(category => category.total_listings);

    // Function to get color based on the index
    const getColor = (index) => {
        const colors = ['#0000CD', '#F400A1', '#FF7F50', '#6699CC', '#FF69B4', '#FA8072', '#FA8098', '#FA3268', '#FA8085', '#FA8788', '#FA2020', '#F412A1', '#FF8550', '#6632CC'];
        return colors[index % colors.length];
    };

    const series = [
        {
            name: 'Series 1',
            data: dataValues.map((value, index) => ({
                x: categories[index],
                y: value,
                fillColor: getColor(index)
            })),
        }
    ];

    const options = {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                endingShape: 'rounded',
                columnWidth: '55%'
            },
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: categories.length > 0 ? categories : ['Property', 'Venus', 'Motors', 'Jobs', 'Influencer', 'Talents'],
        },
    };

    return (
        <>
            <Chart
                options={options}
                series={series}
                type="bar"
                height={350}
            />
        </>
    )
}

export default BarChart;
