import * as React from 'react';
import {
    LineChart,
    lineElementClasses,
    markElementClasses,
} from '@mui/x-charts/LineChart';

const xLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const DashedLine = ({ visitorStats }) => {
    console.log("MyFinder - DashedLine - visitorStats:", visitorStats);

    // Dynamically setting the data for uData (new_visitors) and pData (growth_rate)
    const uData = visitorStats ? Array(7).fill(visitorStats.new_visitors) : [4000, 3000, 2000, 2780, 1890, 2390, 3490];
    const pData = visitorStats ? Array(7).fill(visitorStats.growth_rate) : [2400, 1398, 9800, 3908, 4800, 3800, 4300];

    return (
        <LineChart
            width={500}
            height={400}
            series={[
                { data: pData, label: 'This Week', id: 'growthRateId' },
                { data: uData, label: 'Last Week', id: 'newVisitorsId' },
            ]}
            xAxis={[{ scaleType: 'point', data: xLabels }]}
            sx={{
                [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
                    strokeWidth: 1,
                },
                '.MuiLineElement-series-growthRateId': {
                    strokeDasharray: '5 5',
                },
                '.MuiLineElement-series-newVisitorsId': {
                    strokeDasharray: '3 4 5 2',
                },
                [`.${markElementClasses.root}:not(.${markElementClasses.highlighted})`]: {
                    fill: '#fff',
                },
                [`& .${markElementClasses.highlighted}`]: {
                    stroke: 'none',
                },
            }}
        />
    );
}

export default DashedLine;
