import React, { useEffect, useState } from 'react';
import useRequest from '../../../hooks/useRequest';
import { useOutletContext } from "react-router-dom";


const CustomerCommunity = () => {
    const { isSidebarHidden } = useOutletContext();

    const [communityData, setCommunityData] = useState({
        total_groups: { count: 0, change: "0%" },
        total_friends: { count: 0, change: "0%" },
        total_events: { count: 0, change: "0%" },
        total_polls: { count: 0, change: "0%" },
        total_blogs: { count: 0, change: "0%" },
        total_posts: { count: 0, change: "0%" }
    });

    const [tabeleData, setTableData] = useState([])
    // Community Count and Change
    const { request: communityRequest, response: communityResponse } = useRequest();

    // Community Table 
    const { request: tableRequest, response: tableResponse } = useRequest();

    useEffect(() => {
        communityRequest("Get", "/community/stats/guest-stats-count");
        tableRequest("Get", "/community/stats/guest-community")
    }, []);

    useEffect(() => {
        if (communityResponse && communityResponse.status === 'SUCCESS') {
            setCommunityData(communityResponse.data || {});
        }
    }, [communityResponse]);

    // Table Response Set
    useEffect(() => {
        if (tableResponse && tableResponse.status === 'SUCCESS') {
            setTableData(tableResponse.data || {});
        }
    }, [tableResponse]);
    return (
        <>
            <div className={`admin35-page container-dashboard ${isSidebarHidden ? "sidebar-dashboard-hidden" : ""}`} id="container-dashboard">
                <div className="container-fluid">
                    <h1>MyCommunity</h1>
                    {/*<div className="total-count col-3-tc">
                        <ul>
                            <li>
                                Total Group
                                <h3>
                                    {communityData.total_groups?.count}
                                    <small>{communityData.total_groups?.change}</small>
                                </h3>
                            </li>
                            <li>
                                Total Friends
                                <h3>
                                    {communityData.total_friends?.count}
                                    <small>{communityData.total_friends?.change}</small>
                                </h3>
                            </li>
                            <li>
                                Total Events
                                <h3>
                                    {communityData.total_events?.count}
                                    <small>{communityData.total_events?.change}</small>
                                </h3>
                            </li>
                            <li>
                                Total Polls
                                <h3>
                                    {communityData.total_polls?.count}
                                    <small className="minus-total-count">{communityData.total_polls?.change}</small>
                                </h3>
                            </li>
                            <li>
                                Blogs
                                <h3>
                                    {communityData.total_blogs?.count}
                                    <small>{communityData.total_blogs?.change}</small>
                                </h3>
                            </li>
                            <li>
                                Total Articles
                                <h3>
                                    {communityData.total_posts?.count}
                                    <small>{communityData.total_posts?.change}</small>
                                </h3>
                            </li>
                        </ul>
                    </div>*/}
                    <div className="box-dashboard">
                        {/* Search and Table Section */}
                        <div className="search-wraper">
                            <div className="search-db">
                                <i className="fa-solid fa-magnifying-glass" />
                                <form>
                                    <input type="text" placeholder="Search" />
                                </form>
                            </div>
                            {/* <div className="btn-search-db">
                                <a href="#" className="btn btn-outline-primary">Company</a>
                                <a href="#" className="btn btn-primary">Store / Listing</a>
                            </div> */}
                            <div className="btn-filter">
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Name <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Blogs <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Friends <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Groups <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Events <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Polls <img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        {/*<th>Action <img src="/assets/images/icons/sort.svg" alt="sort" /></th>*/}
                                    </tr>
                                    {tabeleData && tabeleData.length > 0 ? (tabeleData.map((tabledata, index) => {
                                        return <tr key={index}>
                                            <td>{tabledata.name}</td>
                                            <td>{tabledata.blogs}</td>
                                            <td>{tabledata.friends}</td>
                                            <td>{tabledata.groups}</td>
                                            <td>{tabledata.events}</td>
                                            <td>{tabledata.polls}</td>
                                            {/*<td>
                                                <div className="action-table">
                                                    <a href="#">Edit <i className="fa-solid fa-pencil" /></a>
                                                    <a href="#">Delete <i className="fa-solid fa-trash" /></a>
                                                </div>
                                            </td>*/}
                                        </tr>
                                    })) : <td colSpan={7}>No data available</td>}

                                    {/* Repeat rows as needed */}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-container">
                            <div className="show-per-page">
                                <label>Show per page:</label>
                                <select>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                </select>
                            </div>
                            <div className="info-pagination">
                                <span>1 - 10 of 11 items</span>
                                <div className="control-pagination">
                                    <button className="active">1</button>
                                    <button>2</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CustomerCommunity