import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../hooks/useRequest';
import Pagination from '../../../components/Admin/Pagination';
import { Toast } from '../../../utils/ToastNotification';

const UserManagement = () => {
    const { country, lang } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [users, setUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchUsersRequest, response: usersResponse } = useRequest();
    const { request: deleteUserRequest, response: deleteUserResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchUsers = () => {
        fetchUsersRequest(
            'GET',
            `/sub-admin?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`
        );
    };

    useEffect(() => {
        handleFetchUsers();
    }, [currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (usersResponse && usersResponse.status === "SUCCESS") {
            setUsers(usersResponse?.data || []);
            setTotalRecords(usersResponse?.total || 0); // Assuming your API returns total records
        }
    }, [usersResponse]);

    useEffect(() => {
        if (deleteUserResponse && deleteUserResponse.status === "SUCCESS") {
            Toast(true, deleteUserResponse?.message);
            handleFetchUsers(); // Refresh the list after deletion
        } else if (deleteUserResponse) {
            Toast(false, deleteUserResponse?.message);
        }
    }, [deleteUserResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchUsers(); // Refresh the list after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when changing page size
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0); // Reset to first page when searching
    };

    const handleDelete = (id) => {
        deleteUserRequest('DELETE', `/sub-admin/${id}`);
    };

    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        updateStatusRequest('PUT', `/sub-admin/${id}`, { is_active: updatedStatus });
    };

    return (
        <>
            <div className={`subadmin-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><Link to={`/${country}/${lang}/admin`}>Dashboard</Link></li>
                            <li>Sub Admin</li>
                        </ul>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/sub-admin/add`} className="btn btn-primary">
                                Add Sub Admin <i className="fa-solid fa-plus"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db">
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <form>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </form>
                            </div>
                            <div className="btn-filter">
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Email<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Roles<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>
                                    {users && users.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.name}</td>
                                            <td>{data.email}</td>
                                            <td>{data.user_role_type}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input type="checkbox" className="checkbox-toggle" checked={!data.is_active} onChange={() => handleToggleStatus(data.id, data.is_active)}/>
                                                    <div class="txt-toggle"> <span>YES</span> </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/sub-admin/update/${data.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                                                    <a href='javascript:void(0);' onClick={() => handleDelete(data.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserManagement;
