import React, { useEffect, useState } from 'react';
import useRequest from '../../../hooks/useRequest';
import { Toast } from '../../../utils/ToastNotification';
import Pagination from '../../../components/Admin/Pagination';

const Newsletter = () => {
    const [newsletterData, setNewsletterData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: fetchNewsletterRequest, response: newsletterResponse } = useRequest();

    const handleFetchNewsletterData = () => {
        fetchNewsletterRequest('GET', `/newsletter?page_num=${currentPage}&page_size=${pageSize}&search=${searchQuery}`);
    };

    useEffect(() => {
        handleFetchNewsletterData();
    }, [currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (newsletterResponse && newsletterResponse.status === 'SUCCESS') {
            setNewsletterData(newsletterResponse.data);
            setTotalRecords(newsletterResponse.total);
        } else if (newsletterResponse) {
            Toast(false, newsletterResponse.message);
        }
    }, [newsletterResponse]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <div className="newsletter-page container-dashboard" id="container-dashboard">
            <div className="container-fluid">
                <div className="bc-dashboard">
                    <ul>
                        <li><a href="#">Dashboard</a></li>
                        <li>Newsletter</li>
                    </ul>
                </div>
                <div className="box-dashboard">
                    <div className="search-wraper">
                        <div className="search-db">
                            <i className="fa-solid fa-magnifying-glass"></i>
                            <form>
                                <input
                                    type="text"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                />
                            </form>
                        </div>
                        <div className="btn-filter">
                            <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" />Apr 10 - Apr 30</a>
                            <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                        </div>
                    </div>
                    <div className="table-dashboard">
                        <table>
                            <thead>
                                <tr>
                                    <th>Title<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    <th>Content<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                {newsletterData && newsletterData.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.title}</td>
                                        <td>{data.content}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        totalRecords={totalRecords}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default Newsletter;