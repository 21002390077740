import React, { useEffect, useState } from 'react';
import { useParams, Link, useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useRequest from '../../../../hooks/useRequest';
import Pagination from '../../../../components/Admin/Pagination';
import { Toast } from '../../../../utils/ToastNotification';

const AdminSubCategories = () => {
    const { country, lang, category } = useParams();
    const { isSidebarHidden } = useOutletContext();
    const { t } = useTranslation();

    const [categoryData, setCategoryData] = useState(null);
    const [subCategories, setSubCategories] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);

    const { request: categroyRequest, response: categoryResponse } = useRequest();
    const { request: subCategroyRequest, response: subCategoryResponse } = useRequest();
    const { request: subCategroyDeleteRequest, response: subCategoryDeleteResponse } = useRequest();
    const { request: updateStatusRequest, response: updateStatusResponse } = useRequest();

    const handleFetchSubCategories = () => {
        if (categoryData) {
            subCategroyRequest(
                'GET',
                `/sub-categories?page_num=${currentPage}&page_size=${pageSize}&category_id=${categoryData.id}&search=${searchQuery}`
            );
        }
    };

    useEffect(() => {
        if (category) {
            categroyRequest('GET', `/category/${category}`);
        }
    }, [category]);

    useEffect(() => {
        if (categoryResponse && categoryResponse.status === "SUCCESS") {
            setCategoryData(categoryResponse?.data);
        }
    }, [categoryResponse]);

    useEffect(() => {
        if (categoryData) {
            handleFetchSubCategories();
        }
    }, [categoryData, currentPage, pageSize, searchQuery]);

    useEffect(() => {
        if (subCategoryResponse && subCategoryResponse.status === "SUCCESS") {
            setSubCategories(subCategoryResponse?.data || []);
            setTotalRecords(subCategoryResponse?.total || 0); // Assuming your API returns total records
        }
    }, [subCategoryResponse]);

    useEffect(() => {
        if (subCategoryDeleteResponse && subCategoryDeleteResponse.status === "SUCCESS") {
            Toast(true, subCategoryDeleteResponse?.message);
            handleFetchSubCategories(); // Refresh the list after deletion
        } else {
            Toast(false, subCategoryDeleteResponse?.message);
        }
    }, [subCategoryDeleteResponse]);

    useEffect(() => {
        if (updateStatusResponse && updateStatusResponse.status === "SUCCESS") {
            Toast(true, updateStatusResponse.message);
            handleFetchSubCategories(); // Refresh the list after status update
        } else if (updateStatusResponse) {
            Toast(false, updateStatusResponse.message);
        }
    }, [updateStatusResponse]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(0); // Reset to first page when changing page size
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(0); // Reset to first page when searching
    };

    const handleDelete = (id) => {
        subCategroyDeleteRequest('DELETE', `/sub-category/${id}`);
    };

    const handleToggleStatus = (id, currentStatus) => {
        const updatedStatus = !currentStatus;
        updateStatusRequest('PUT', `/sub-category/${id}`, { is_active: updatedStatus });
    };

    return (
        <>
            <div className={`subcategory-page container-dashboard ${isSidebarHidden ? 'sidebar-dashboard-hidden' : ''}`} id="container-dashboard">
                <div className="container-fluid">
                    <div className="bc-dashboard">
                        <ul>
                            <li><a href="#">Directory</a></li>
                            <li><Link to={`/${country}/${lang}/admin/directory/${category}`}>{category}</Link></li>
                            <li>Subcategory</li>
                        </ul>
                        <div className="btn-bc">
                            <Link to={`/${country}/${lang}/admin/directory/${category}/sub-category/add`} className="btn btn-primary">Add Sub Category <i className="fa-solid fa-plus"></i></Link>
                        </div>
                    </div>
                    <div className="box-dashboard">
                        <div className="search-wraper">
                            <div className="search-db">
                                <i className="fa-solid fa-magnifying-glass"></i>
                                <form>
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </form>
                            </div>
                            <div className="btn-filter">
                                <a href="#"><img src="/assets/images/icons/calendar.svg" alt="calendar" /> Apr 10 - Apr 30</a>
                                <a href="#"><img src="/assets/images/icons/download.svg" alt="Download" /> Download</a>
                            </div>
                        </div>
                        <div className="table-dashboard">
                            <table>
                                <tbody>
                                    <tr>
                                        <th>Name<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Active<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                        <th>Action<img src="/assets/images/icons/sort.svg" alt="sort" /></th>
                                    </tr>
                                    {subCategories && subCategories.map((data, index) => (
                                        <tr key={index}>
                                            <td>{data.name}</td>
                                            <td>
                                                <div className="btn-toggle">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-toggle"
                                                        checked={data.is_active}
                                                        onChange={() => handleToggleStatus(data.id, data.is_active)}
                                                    />
                                                    <div class="txt-toggle"> <span>YES</span> </div>
                                                    <div className="layer-toggle"></div>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="action-table">
                                                    <Link to={`/${country}/${lang}/admin/directory/${category}/sub-category/update/${data?.id}`}>Edit <i className="fa-solid fa-pencil"></i></Link>
                                                    <a href='javascript:void(0);' onClick={() => handleDelete(data?.id)}>Delete<i className="fa-solid fa-trash"></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <Pagination
                            totalRecords={totalRecords}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={handlePageChange}
                            onPageSizeChange={handlePageSizeChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default AdminSubCategories;
